import React from 'react';
import { Row, Col, Button, Card, Typography } from 'antd';
import PaywallLayout from '../Layouts/PaywallLayout';
import _ from 'underscore';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

const { Paragraph, Title } = Typography;

// Users
function Invite(props) {
  try {
    initPostHog();
    posthog.capture('paywall_page_viewed');
    console.log('paywall_page_viewed');
  } catch (error) {
    console.error('Error capturing posthog event: ', error);
  }
  return (
    <PaywallLayout title="" commonData={props.commonData}>
      <Card className="mt-12 px-12 py-0">
        <Row>
          <Col span={24}>
            <img className="logo-dark" />
            <Title style={{ textAlign: 'center' }} level={2} className="mt-0 mb-8">
              <div>Finish adding a payment method to activate your 10-day free trial.</div>
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={16} className="p-8">
            <Paragraph>
              Don’t miss out on this opportunity to 10X your LinkedIn engagement activity with our
              commenting tool, custom influencer alerts, and Favorites list building.
            </Paragraph>

            <Paragraph>
              Plus, get all of the LinkedIn profile and activity metrics you need: no need for a
              separate analytics dashboard, spreadsheets, or bookmarks.
            </Paragraph>

            <Paragraph>
              Ready to fire up your LinkedIn outbound engagement and your personal brand presence?
            </Paragraph>

            <Paragraph>Get started today, in just 2 minutes.</Paragraph>

            <Paragraph>
              Once you’ve activated your account, we’ll email you to set up time to walk you through
              the onboarding process and answer any questions you may have.
            </Paragraph>

            <Paragraph>
              P.S. Don’t worry, you won’t be charged for 10 days - and if you don’t absolutely LOVE
              Aware, you can cancel it at any time. No questions asked.
            </Paragraph>

            <form action="subscription/new">
              <button
                type="submit"
                class="mt-8 inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Subscribe now
              </button>
            </form>
          </Col>

          <Col xs={24} lg={8} className="p-8">
            <Paragraph>
              <blockquote class="mt-6 md:flex md:flex-grow md:flex-col">
                <div class="relative text-lg font-medium md:flex-grow">
                  <svg
                    class="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 32 32">
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p class="relative">
                    This is excellent. I'm just hyped at being able to bucket all of these people
                    into competitor, prospect, influencer etc. And then it gave me an insight into a
                    prospect I've been trying to go after - got a lead within the first hour of
                    using Aware!
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex items-start">
                    <div class="inline-flex flex-shrink-0 rounded-full"></div>
                    <div class="ml-4">
                      <img src="images/eric.jpeg" class="w-16 rounded-full" />
                      <div class="text-base font-medium">Eric Nowoslawski</div>
                      <div class="text-base font-medium text-indigo-500">
                        Founder & Growth Consultant
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </Paragraph>
          </Col>
        </Row>
      </Card>
    </PaywallLayout>
  );
}

export default Invite;
