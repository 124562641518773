import React from 'react';
import axios from 'axios';
import { message, Dropdown, Menu } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import * as ApiHelper from '../Helpers/ApiHelpers';

class PersonListDropdown extends React.Component {

  constructor(props) {
    super(props);
    // console.log('activity props', props.activity);

    this.state = {
      customLists: this.filteredCustomLists(props.customLists),
      menuVisible: false,
      isRepliesPage: props.isRepliesPage || false
    };
    this.onMenuClick = this.onMenuClick.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
  }

  filteredCustomLists(lists) {
    // console.log('filteredCustomLists', lists);
    return lists.map((e) => ({
      key: e.id.toString(),
      label: e.name
    }));
  }

	async saveDraft(event) {

		console.log('save draft', event)

    let content = this.props.activity.blob['post_content'];
    content = content.replace(/\n/g, '<br>');

    const url = `/api/v1/posts/create`;
    const payload = {
      post_text : content,
      post_text_html : content,
      post_status : 'draft',
      linked_in_urn : this.props.activity.blob['permalink'],
    };

    await axios.post(url, payload, ApiHelper.headers())
      .then((res) => {
        console.log('Post saved successfully', res);
        message.info('Post saved successfully');
      })
      .catch((err) => {
        console.error('Error saving draft', err);
        message.error('Error saving draft');
      })
  };

  onMenuClick(evt) {

    if(evt.key === 'save-draft') {
      console.log('Save draft');
      this.saveDraft(evt);
      return;
    }
    const { personId, peopleToLists, onUpdateLists } = this.props;
    const listId = evt.key;

    const isMember = peopleToLists[personId].includes(parseInt(listId));

    if (isMember) {
      // Make an API call to remove the person from the list
      axios.delete(`/api/v1/lists/${listId}/people/${personId}`)
        .then(response => {
          console.log(`Removed person ${personId} from list ${listId}`);
          // Update the state
          onUpdateLists(personId, listId, 'remove');
        })
        .catch(error => {
          console.error(`Error removing person ${personId} from list ${listId}`, error);
        });
    } else {
      // Make an API call to add the person to the list
      axios.post(`/api/v1/lists/${listId}/people`, { person_id: personId })
        .then(response => {
          console.log(`Added person ${personId} to list ${listId}`);
          // Update the state
          onUpdateLists(personId, listId, 'add');
        })
        .catch(error => {
          console.error(`Error adding person ${personId} to list ${listId}`, error);
        });
    }
  }

  handleVisibleChange(flag) {
    this.setState({ menuVisible: flag });
  }

	listOptions() {
		const { personId, peopleToLists } = this.props;

		return this.state.customLists.map((e) => {
			const peopleLists = peopleToLists?.[personId] ?? [];
			const isIncluded = peopleLists.includes(parseInt(e.key));
			
			return {
				key: e.key,
				label: (
					<div className='min-w-24 flex items-center'>
						{isIncluded ? (
							<CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
						) : (
							<CloseCircleOutlined style={{ color: '#ccc', marginRight: '8px' }} />
						)} 
						{e.label}
					</div>
				)
			};
		});
	}

  menuItems() {
    const { personId, personName } = this.props;

    if( this.state.isRepliesPage ) {

      return [
        {
          key: '1',
          label: 'Add/remove profile from lists',
          children: this.listOptions()
        }
      ];

    } else {

      return [
        {
          key: '1',
          label: 'Add/remove profile from lists',
          children: this.listOptions()
        },
        {
          key: 'save-draft',
          label: 'Save post as draft',
        }
      ];

    }
  }

  render() {
    return (
      <Dropdown
        overlay={<Menu items={this.menuItems()} onClick={this.onMenuClick} />}
        trigger={['click']}
        visible={this.state.menuVisible}
        onVisibleChange={this.handleVisibleChange}
      >
        <a className='text-3xl'>
          ...
        </a>
      </Dropdown>
    );
  }
}

export default PersonListDropdown;
