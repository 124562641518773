import React from 'react';
import axios from 'axios';
import { Input, Form, Button, Tooltip } from 'antd';
import * as ApiHelper from './Helpers/ApiHelpers';
import * as Helper from './Helpers/Helpers';

class CustomSearchList extends React.Component {
  constructor(props) {
    super(props);
    // console.log('CustomSL props', props)
    this.onFinish = this.onFinish.bind(this);
    ApiHelper.prepHttpCall();
  }

  onFinish(values) {
    // console.log('val', values)
    let params = {
      list: {
        li_user_id: this.props.linkedInUserId,
        authorKeywords: values.authorKeywords,
        contentKeywords: values.contentKeywords
      }
    };

    let self = this;
    let url = Helper.profileSettingsUrl(this.props.commonData) + 'lists/' + this.props.listId;

    axios
      .put(url, params, ApiHelper.headers())

      .then(function (response) {
        // console.log('response', response);
        let url =
          Helper.profileSettingsUrl(self.props.commonData) + 'lists/';
        window.location.href = url;
      })

      .catch((res) => {
        console.log('Error');
        console.log(res);

        this.setState({
          hasError: true
        });
      });
  }

  render() {
    let tt = <>Just like in LinkedIn, you can use boolean searches (AND, OR, NOT), and enclose phrases in quotes for exact matches. <a href='https://www.linkedin.com/help/linkedin/answer/a524335' target='_blank'>Learn more</a></>

    return (
      <Form
        name="list"
        onFinish={this.onFinish}
        style={{ width: '350px', margin: 'auto' }}
        layout="vertical"
        initialValues={{
          authorKeywords: this.props.authorKeywords,
          contentKeywords: this.props.contentKeywords,
        }}
      >
        <Form.Item
          name="authorKeywords"
          label="Author title/headline keywords:"
          tooltip={tt}
          rules={[
            {
              pattern: /^.{0,79}$/,
              message: "Due to a LinkedIn limitation, please enter fewer than 80 characters here",
            },
            {
              pattern: /^(?!(?:.*(?:\bAND\b|\bNOT\b|\bOR\b)){6,}).*$/,
              message: "LinkedIn has a limit of 5 boolean operators (e.g. 'AND', 'OR', 'NOT') in a given search.",
            }
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"i.e. CTO OR \"chief technology officer\""} />
        </Form.Item>

        <Form.Item
          name="contentKeywords"
          label="Content keywords:"
          tooltip={tt}
          rules={[
            {
              pattern: /^.{0,79}$/,
              message: "Due to a LinkedIn limitation, please enter fewer than 80 characters here",
            },
            {
              pattern: /^(?!(?:.*(?:\bAND\b|\bNOT\b|\bOR\b)){6,}).*$/,
              message: "LinkedIn has a limit of 5 boolean operators (e.g. 'AND', 'OR', 'NOT') in a given search.",
            }
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder="i.e. technology OR tech" />
        </Form.Item>

        <Form.Item>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit">
            Save search
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default CustomSearchList;

