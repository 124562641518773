import React from 'react';
import SessionLayout from '../Layouts/SessionLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { UserIcon } from '@heroicons/react/24/outline';

const { Paragraph, Text } = Typography;

import { Select, Typography, Form, Input, Button } from 'antd';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.onFinish = this.onFinish.bind(this);
    console.log('props', props)
    locale.locale = props.session.locale

    this.state = {
      hasError: false,
      successfulResponse: false
    };
    ApiHelper.prepHttpCall();
  }

  onFinish(values) {
    let params = {
      user: {
        email: values.email,
        password: values.password
      }
    };

    let self = this;
    let url = '/users/password';

    axios
      .post(url, params)

      .then(function (response) {
        self.setState({
          successfulResponse: true,
          hasError: false
        });
      })

      .catch((res) => {
        self.setState({
          hasError: true,
          successfulResponse: false
        });
      });
  }

  render() {
    return (
      <SessionLayout>
        <Paragraph>
          { locale.t("forgot_password.forgot_password_enter_email_msg") }
        </Paragraph>
        <Form
          name="email"
          initialValues={{
            remember: true
          }}
          onFinish={this.onFinish}>
          <Text type="danger" className={`${this.state.hasError ? '' : 'hidden'}`}>
            { locale.t("forgot_password.email_not_found_msg") }
          </Text>
          <Text type="success" className={`${this.state.successfulResponse ? '' : 'hidden'}`}>
            { locale.t("forgot_password.instructions_email_sent_msg") }
          </Text>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: locale.t("forgot_password.please_input_email_msg")
              }
            ]}>
            <Input prefix={<UserIcon width={20} />} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              { locale.t("forgot_password.send_link_btn") }
            </Button>
            <br />
            <br />
            { locale.t("forgot_password.or") } <a href="/users/sign_up">{ locale.t("forgot_password.sign_up_link_text") }</a>
          </Form.Item>

          <div style={{ width: '100%', textAlign: 'center' }}>
            <Select style={{ width: 120 }}
                defaultValue={{ value: this.props.session.locale }}
                onChange={this.onLocaleToggle}
                options={[{ value: 'en', label: <span>English</span> }, { value: 'fr', label: <span>French</span> }]} />
          </div>
        </Form>
      </SessionLayout>
    );
  }
}

export default ForgotPassword;
