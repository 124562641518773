import React from 'react';
import { Select } from 'antd';
const axios = require('axios');
import * as ApiHelper from './Helpers/ApiHelpers';

class ProfileToggle extends React.Component {

  constructor(props) {
    super(props);
    // console.log('isMasterAdmin', props.commonData.isMasterAdmin);
    this.onProfileChange = this.onProfileChange.bind(this);
  }

  onChooseProfile = (value, option) => {

    let url = '/api/v1/accounts/' + this.props.commonData.currentAccountUid + '/users/' + this.props.commonData.currentUserId + '/set_current_li_user';

    let self = this

    axios.put(url, { linked_in_user_id: value }, ApiHelper.headers())
      .then(function (response) {
        window.location.reload();
        console.log('response', response);
      })
      .catch(function (error) {
        console.log('error', error);
      })
  }

  onProfileChange(value, option) { 
    // Handle change, using value or any other logic you need
    this.onChooseProfile(value, option);
  }

  render() {
    const { profiles, selectedProfile } = this.props;  // Ensure profiles are passed as props correctly
    // console.log('profiles', selectedProfile, profiles);

    return (
      <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4">
        <span className="text-gray-500 mr-2 mb-2 sm:mb-0">Engage on behalf of:</span>

        <div className="flex items-center gap-2 rounded-full bg-gray-100 px-1 py-0">

          <Select
            defaultValue={selectedProfile}
            style={{ width: 240 }}
            onChange={this.onProfileChange}
            className="bg-gray-100 border-none focus:ring-0 text-sm text-gray-700 appearance-none rounded-full"
            dropdownRender={(menu) => (
              <div className="bg-white rounded-lg shadow-lg p-2">
                {menu}
              </div>
            )}
          >
            {profiles.map((profile) => (
              <Select.Option key={profile.id} value={profile.id}>
                <div className="border-none flex items-center">
                  <img
                    src={profile.avatar}
                    className="rounded-full"
                    alt={profile.name}
                    style={{ width: 24, height: 22, marginRight: 8 }}
                  />
                  <span className="text-gray-700">{profile.name}</span>
                </div>
              </Select.Option>
            ))}
          </Select>

        </div>

      </div>
    );
  }
}

export default ProfileToggle;

