import React, { Component } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { Drawer } from "antd";
import NewPostContainer from "../../Posts/NewPostContainer";

class DrawerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: props.postData.filter((post) => post.post_status?.toLowerCase() === "draft") || [],
      postText: props.text,
    };
  }

  handleChange = (selectedTab) => {
    console.log('handleChange', selectedTab);
    const selectedTabData = this.props.postData.filter((post) => post.post_status?.toLowerCase() === selectedTab);
    this.setState({ postData: selectedTabData });
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Drawer
        width={640}
        onClose={handleClose}
        closable={false}
        open={open}
        closeIcon={<ChevronDoubleRightIcon width="20px" color="black" />}
      >
        <div className="flex justify-between">
          <h2>Write a new post</h2>
          <CloseOutlined
            style={{ marginRight: "30px" }}
            onClick={handleClose}
          />
        </div>
        <NewPostContainer
          mode="queue"
          commonData={this.props.commonData}
          postData={this.props.postData}
          prePostContent={this.state.postText}
        />
      </Drawer>
    );
  }
}

export default DrawerComponent;

