import React from 'react';
import Engage from '../Engage/Index';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    locale.locale = props.commonData.locale
    // console.log('Influencers props', props.customLists);
    super(props);
  }

  render() {
    return (
      <Engage
        commonData={this.props.commonData}
        posts={this.props.posts}
        prompts={this.props.prompts}
        pages={this.props.pages}
        lists={this.props.lists}
        customLists={this.props.customLists}
        listType={this.props.listType}
        selectedListValue={this.props.selectedListValue}
        pageName={this.props.pageName}
        title={ locale.t("influencers.title") }
        subtitle={ locale.t("influencers.subtitle") }>
      </Engage>
    );
  }
}

export default Index;
