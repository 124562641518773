import React from 'react';

class LinkButton extends React.Component {
  constructor(props) {
    super(props);
  }

  message() {
    if (this.props.message == undefined) {
      return "You're all caught up here!";
    } else {
      return this.props.message;
    }
  }

  render() {
    return (

      <button
        type="link"
        class={"sm:mr-0 lg:mr-4 inline-flex tracking-wide hover:cursor-pointer items-center text-gray-800 py-1.5 text-sm font-medium rounded-lg !bg-none !bg-transparent hover:bg-gray-200 border-none " + this.props.class}
        onClick={this.props.onClick}>
        {this.props.icon}
        <span className="pl-1.5">{this.props.text}</span>
      </button>

    );
  }
}

export default LinkButton;
