import React, { useState } from 'react';
import { Card, Alert } from 'antd';
import PeopleSearchList from '../PeopleSearchList';
import CustomSearchList from '../CustomSearchList';
import DashboardLayout from '../Layouts/DashboardLayout';
import * as Helper from '../Helpers/Helpers';
import BulkModal from './BulkModal'

const Show = (props) => {

  let billing_url = Helper.accountSettingsUrl(props.commonData) + 'billing';

  // console.log('----->', props.listType)
  // console.log('----->', props.commonData.grandfatheredCustomSearchLimits )
  return (
    <DashboardLayout title={props.list.name} commonData={props.commonData}>

      { !props.commonData.grandfatheredCustomSearchLimits && props.listType != 'CustomSearchList' && !props.commonData.availableFeatures.includes('max_profile_monitoring') &&
        <Alert message={ <div>On your current plan, you can monitor up to 200 profiles. <a href={billing_url}>Upgrade to the Power User</a> plan to unlock more.</div>} type="warning" className='mb-4' />
      }
      { props.commonData.grandfatheredCustomSearchLimits && props.listType != 'CustomSearchList' &&
        <Alert message={ <div>The more profiles you add across all your custom lists, the slower they are to update.<br/>We recommend monitoring a total of 200 or fewer. <br/>NOTE: Anyone added to your lists that hasn't posted within 90 days will be automatically removed.</div> } type="warning" className='mb-4' />
      }

      <Card>
        { props.listType != 'CustomSearchList' &&
          <>
          <BulkModal list={props.list.id}/>
          <div>Enter the name (not URL) of someone you'd like to monitor:</div>
          <PeopleSearchList
            records={props.people}
            listId={props.list.id}
            commonData={props.commonData}
            placeholder="Justin Welsh"
            linkedInUserId={props.commonData.linkedInUserId}
          />
          </>
        }
        { props.listType == 'CustomSearchList' &&
          <CustomSearchList
            records={props.people}
            listId={props.list.id}
            authorKeywords={props.authorKeywords}
            contentKeywords={props.contentKeywords}
            commonData={props.commonData}
            placeholder="Enter the name of someone you'd like to monitor"
            linkedInUserId={props.commonData.linkedInUserId}
          />
        }
      </Card>

    </DashboardLayout>
  );
};

export default Show;
