import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Input, message } from 'antd';
import PeopleSearchList from '../PeopleSearchList';
import DashboardLayout from '../Layouts/DashboardLayout';
import { PlusIcon } from '@heroicons/react/24/outline';
import * as ApiHelper from '../Helpers/ApiHelpers';
const { TextArea } = Input;
const axios = require('axios');

const BulkModal = (props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: "We are currently importing the profiles, which usually take less than a minute. You will need to reload the page to see the imported users as they're available. Note: Some profiles may show incomplete data temporarily until we hit the profile.",
      duration: 8,
      style: {
        maxWidth: '500px',
        margin: '0 auto'
      },
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTextareaValue('');
  };


  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };


  const upload = async (listId) => {
    const urls = document.querySelector('textarea').value.trim().split(/[\n\s]+/);
    const slugs = urls.map((url) => {
      const regex = /\/in\/([\w-]+)\/?/;
      const match = url.match(regex);
      if (match) {
        const slug = match[1];
        if (/^[a-z\d-]{3,}$/i.test(slug)) {
          return slug;
        }
      }
      return null;
    }).filter((slug) => slug !== null);

    console.log(slugs); // or do something else with the slugs
    console.log('->', props.list)
    ApiHelper.prepHttpCall();
    try {
      const response = await axios.post('/api/v1/bulk_import', { slugs, listId: props.list }, ApiHelper.headers());
      console.log(response.data);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
    }
    setTextareaValue('');
    success();
  };

  return (
    <>
      {contextHolder}

      <Button className='my-2' style={{ float: 'right' }} onClick={e=>showModal(props.list)}>
        <PlusIcon width="16px" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
        Bulk upload
      </Button>

      <Modal title="Bulk upload" visible={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel}>
        <h3>Upload your url's in bulk</h3>
        <TextArea
          placeholder="https://www.linkedin.com/in/macmartine"
          rows={4}
          value={textareaValue}
          onChange={handleTextareaChange}
        />        <Button onClick={() => upload(props.list.id)} className='mt-4' type='primary'>Upload</Button>
      </Modal>
    </>
  )

}

export default BulkModal;
