import posthog from 'posthog-js';

const initPostHog = () => {

  // if(process.env.NODE_ENV === 'development') {
  //   return
  // }

  // console.log('Initializing PostHog');

  posthog.init('phc_PrWembHzb8gFwtR82gB8xUTwwygOi02JNj0yESD1x7s', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      capture_pageview: false,
  });

};

export default initPostHog;

