import React from 'react';
import { Row, Col, Tooltip, Typography } from 'antd';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
const { Text, Title } = Typography;

class StatisticCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  render() {
    return (
      <div className={`${this.props.className} text-center rounded-lg bg-card border-solid border-secondary px-4 py-4 statistic`}>
        <Row>
          <Col span={24}>
            <Title level={5}>
              {this.props.title}
              { this.props.toolTip && (
                <Tooltip className="ml-2" title={ this.props.toolTip }>
                  <QuestionMarkCircleIcon className="ml-1 w-4 h-4" />
                </Tooltip>
              )}
            </Title>
            <Text className='text-center stat text-primary'>
              {this.state.data}
            </Text>
            <div style={{ textTransform: 'uppercase', fontSize: 12 }}>{this.props.tooltip}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StatisticCard;

