import React from 'react';
import { Menu } from '@headlessui/react';
import { SparklesIcon } from '@heroicons/react/24/outline';

const FlyoutButton = (props) => {

  const generateComment = (promptId, closeMenu) => {
    props.commentCallback(promptId);
    closeMenu();
  };

  return (
    <div className="z-100!important relative inline-block text-left">
      <Menu>
        {({ open, close }) => (
          <>
            <div className="">

              <Menu.Button className="relative inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-orange-600 bg-gradient-to-r from-gray-50 to-gray-100 rounded-md border border-gray-200 hover:bg-gradient-to-l hover:from-orange-50 hover:to-orange-100 hover:text-orange-700 transition duration-300 ease-in-out shadow-sm">
                <SparklesIcon className="w-4 h-4 mr-2 text-orange-500 hover:text-orange-600" />
                <span className="relative z-10">Generate comment with AI</span>
                <svg
                  className="w-3 h-3 mt-1 ml-2 text-orange-500 hover:text-orange-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Menu.Button>

            </div>

          {open && (
            <Menu.Items className="z-10 ml-4 absolute right-0 w-80 mt-2 p-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-100">
              <div className="py-1">
                {props.prompts.map((prompt, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          generateComment(prompt.id, close);
                        }}
                        className={`text-gray-700 hover:bg-white rounded-md flex justify-between px-4 py-2 text-sm`}
                      >
                        {prompt.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
                {/* Add the "Random comment" option at the end */}
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        generateComment('random', close);
                      }}
                      className={`text-gray-700 hover:bg-white flex justify-between px-4 py-2 text-sm`}
                    >
                      Short & Sweet
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          )}

          </>
        )}
      </Menu>
    </div>
  );
};

export default FlyoutButton;

