import React, { useEffect, useState, useContext } from "react";
import he from 'he';
import { Button, Modal, TimePicker, DatePicker, Dropdown, notification } from 'antd';
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FileImageOutlined, SaveOutlined, SendOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Editor from '../../Editor';
import { EditorThemeContext } from "../EditorContext";

function cleanHtml(content) {
  // Decode HTML entities first
  content = he.decode(content);
  // Remove any style attributes
  content = content.replace(/style="[^"]*"/ig, '');
  // Remove <img> and <style> tags entirely
  content = content.replace(/<(img|style)\b[^>]*>/ig, '');
  // Remove <span> tags
  content = content.replace(/<\/?(span|a)[^>]*>/ig, '');

  // Add a single newline for each tag that should result in a newline
  content = content.replace(/<\/(p|h[1-6]|blockquote|em)[^>]*>/ig, '\n');

  // Remove all specified tags without adding newlines
  content = content.replace(/<(p|h[1-6]|blockquote|em)[^>]*>/ig, '');
  // Remove <br> tags entirely
  content = content.replace(/<br[^>]*>/ig, '');

  // Collapse multiple newlines into a single newline
  // content = content.replace(/\n+/g, '\n');
  return content.trim();
}

export default function PostEditor(props) {
  const {
    onChange,
    handleImageChange,
    attachmentInput,
    prePostContent,
  } = props;

  const {
    dayjs,
    userTimeZone, locale,
    commonData,
    selectedPost,
    queueBtnSubtitle, selectedDate, setSelectedDate,
    selectedTimeSlot, setSelectedTimeSlot,
    setQueueBtnSubtitle, isSchedulingPostNow, setIsSchedulingPostNow,
    scheduleBtnText, setScheduleBtnText,
    deletePostHandler,
    addToFirstAvailableSlot,
    isLoadingPostEditor, editorHtml,
    createOrUpdatePostHandler,
    hasAvailableSlots
  } = useContext(EditorThemeContext);

  console.log("hasAvail", hasAvailableSlots);
  const selectedPostId = selectedPost?.id;

	const disabledDate = (current) => {
		const now = dayjs().utc();
		const customDate = dayjs(current).utc();
		const disable = customDate.isBefore(now.startOf('day')) || 
										customDate.isAfter(now.add(1, 'month').endOf('day'));
		console.log('before now', customDate.isBefore(now.startOf('day')));
		console.log("customDate", customDate.format());
		console.log("now", now.format());
		console.log("disable", disable);
		return disable;
	};

  // This isn't working...
  const disabledTime = (current) => {
    const now = dayjs().utc();
    const customTime = dayjs(current).utc();
    const disable = customTime.isBefore(now) || customTime.isSame(now);
    
    console.log('customTime before now', customTime.isBefore(now));
    console.log('customTime same as now', customTime.isSame(now));
    console.log("customTime", customTime.format());
    console.log("now", now.format());
    console.log("disable", disable);

    return disable;
  };


  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [defaultScheduledTime, setDefaultScheduledTime] = useState(null);
  const [defaultScheduledDate, setDefaultScheduledDate] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  const updateScheduleTime = () => {
    console.log("scheduledTime", scheduledTime);
    console.log("scheduledDate", scheduledDate);
    const [hours, minutes] = scheduledTime.split(':').map(Number);

    const sDate = dayjs().tz(userTimeZone)
      .year(scheduledDate.year()).month(scheduledDate.month()).date(scheduledDate.date())
      .hour(hours).minute(minutes).second(0).millisecond(0);
    setSelectedDate(sDate);

    console.log("sDate", sDate);
    const formattedDate = sDate.format('MMMM DD YYYY - hh:mm a');
    const formattedTimeIn12h = sDate.format('hh:mm a');

    setQueueBtnSubtitle(formattedDate);
    setSelectedTimeSlot(formattedTimeIn12h);
    setIsSchedulingPostNow(false);
    setScheduleBtnText("Schedule At");
    setDefaultScheduledDate(null);
    setDefaultScheduledTime(null);
  };

  const scheduleWithQueue = async (isEditingDraftPost = false) => {
    console.log("scheduleWithQueue");
    if (isEditingDraftPost) {
      await createOrUpdatePostHandler('DRAFT', null, selectedPostId, false);
      setSelectedDate(null);
      setSelectedTimeSlot(null);
      return;
    }
    let scheduledDateTime = selectedDate;
    if (selectedPost?.post_status === "scheduled" && !selectedDate) {
      scheduledDateTime = selectedPost?.scheduled_at;
    }
    const inputFormat = "DD MMMM YYYY | hh:mm a";
    const inputString = `${dayjs(scheduledDateTime).format('DD MMMM YYYY')} | ${selectedTimeSlot}`;
    const outputFormat = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
    console.log("inputString", inputString);
    console.log("inputFormat", inputFormat);
    console.log("userTimeZone", userTimeZone);
    console.log("outputFormat", outputFormat);

    const [datePart, timePart] = inputString.split('|').map(s => s.trim());
    let convertedDate;

    if (timePart && timePart !== 'null') {
        // If time is provided
        convertedDate = dayjs.tz(`${datePart} ${timePart}`, 'DD MMMM YYYY hh:mm a', userTimeZone);
    } else {
        // If only date is provided
        convertedDate = dayjs.tz(datePart, 'DD MMMM YYYY', userTimeZone);
    }

    // const result = convertedDate.utc().format(outputFormat);
    // const convertedDate = dayjs.tz(inputString, inputFormat, userTimeZone).utc().format(outputFormat);

    if (selectedPostId) {
      await createOrUpdatePostHandler('SCHEDULED', convertedDate, selectedPostId, false);
    } else {
      await createOrUpdatePostHandler('SCHEDULED', convertedDate);
    }
    setSelectedDate(null);
    setSelectedTimeSlot(null);
  };

  const onEditorChange = (htmlContent) => {
    
    const textContent = cleanHtml(htmlContent)
    if (textContent.length > 3000) {

      api.info({
        message: locale.t("post_scheduling.character_limit_exceeded_msg"),
        description: locale.t("post_scheduling.character_limit_exceeded_desc"),
        duration: 1, // Duration in seconds
        placement: "topRight",
        style: { 
          transition: "opacity 1s ease-out" 
        }
      });

      return;
    }
    onChange(htmlContent, textContent);
  };

  useEffect(() => {
    if (prePostContent) {
      setScheduleBtnText("Update Post!");
    } else {
      setScheduleBtnText("Add to Queue");
    }
  }, [selectedPost, prePostContent]);

  useEffect(() => {
    if (commonData?.locale) locale.locale = commonData?.locale;
  }, [commonData]);

  return (
    <>
      <div className="post-editor outline-none">
        {contextHolder}
        <div>
          <Editor
            content={editorHtml}
            placeholder="Write your post"
            commonData={props.commonData}
            onChangeCallback={onEditorChange}
          />
        </div>
        <div className="text-white p-2">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div>
                <label className="cursor-pointer block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
                  <div
                    style={{
                      border: "1px solid #ccc",
                      marginRight: "10px",
                      padding: "5px",
                      minWidth: "100px",
                      borderRadius: "4px",
                      color: "#222",
                      marginTop: "8px",
                    }}
                  >
                    <FileImageOutlined /> {locale.t("post_scheduling.add_image_btn")}
                  </div>
                </label>
                <input
                  ref={attachmentInput}
                  accept="image/*"
                  id="file_input"
                  type="file"
                  className="absolute block w-full text-sm text-gray-900 border border-gray-800 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400"
                  onChange={handleImageChange}
                  style={{ top: '-1000px' }}
                />
              </div>

              <Modal
                title={locale.t("post_scheduling.schedule_post_modal_title")}
                open={isScheduleModalOpen}
                onOk={() => setIsScheduleModalOpen(false)}
                onCancel={() => setIsScheduleModalOpen(false)}
                footer={[
                  <Button key="back" onClick={() => setIsScheduleModalOpen(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    disabled={!(scheduledDate && scheduledTime)}
                    onClick={() => {
                      updateScheduleTime();
                      setIsScheduleModalOpen(false);
                    }}
                  >
                    <ClockCircleOutlined />
                    Schedule
                  </Button>
                ]}
              >
                <h6 className="m-0 mt-4 mb-2">Date & Time</h6>
                <div className="w-full space-y-2">
                  <div className="my-2">
                    <DatePicker
                      className="w-full my-1"
                      value={defaultScheduledDate ?? null}
                      disabledDate={disabledDate}
                      onChange={(e) => {
                        setDefaultScheduledDate(e);
                        setScheduledDate(dayjs(e));
                      }}
                    />
                  </div>
                  <TimePicker
                    use12Hours
                    value={defaultScheduledTime ?? null}
                    minuteStep={5}
                    disabledTime={disabledTime}
                    format={'HH:mm'}
                    onChange={(e) => {
                      setDefaultScheduledTime(e);
                      setScheduledTime(dayjs(e).format('HH:mm'));
                    }}
                    className="w-full"
                  />
                </div>
              </Modal>
            </div>

            <div className="flex items-center">
              {props.mode === 'publish' ?
                <>
                  <Button disabled={isLoadingPostEditor} className='ml-3' type='primary' onClick={() => createOrUpdatePostHandler('DRAFT')}>
                    {locale.t("post_scheduling.save_draft_btn")} <SaveOutlined />
                  </Button>
                  <Button disabled={isLoadingPostEditor} className='ml-3' type='primary' onClick={() => createOrUpdatePostHandler('PUBLISHED')} style={{ marginLeft: "5px" }}>
                    {locale.t("post_scheduling.publish_btn")} <SendOutlined />
                  </Button>
                </> :
                <div className="flex items-center space-x-1">
                  {isSchedulingPostNow ?
                    <Button size="large" type="primary" disabled={isLoadingPostEditor} onClick={() => createOrUpdatePostHandler('PUBLISHED')}
                      className="py-0 text-sm" style={{ paddingTop: "0", paddingBottom: "0", borderTopRightRadius: "0", borderBottomRightRadius: "0" }}>
                      <div>Publish Now</div>
                    </Button> :
                    <Button
                      size="large"
                      type="primary"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                        paddingTop: "0",
                        paddingBottom: "0",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0"
                      }}
                      onClick={() => {
                        if (isLoadingPostEditor) return;
                        if (selectedPost?.post_status === "draft" && !selectedDate) {
                          scheduleWithQueue(true);
                          return;
                        } else {
                          scheduleWithQueue();
                        }
                      }}
                    >
                      <div className="text-sm">{isLoadingPostEditor ? "Please wait..." : scheduleBtnText}</div>
                      <div style={{ fontSize: "10px" }}>{queueBtnSubtitle}</div>
                    </Button>
                  }
                  {!prePostContent ? (
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: isSchedulingPostNow ? [
                          { label: <div onClick={() => setIsScheduleModalOpen(true)}>Custom Schedule</div>, key: "1" },
                          ...(hasAvailableSlots ? [{ label: <div onClick={() => addToFirstAvailableSlot()}>Add to Queue</div>, key: "2" }] : []),
                          { label: <div onClick={() => createOrUpdatePostHandler('DRAFT')}>Save as Draft</div>, key: "3" },
                        ] : [
                          { label: <div onClick={() => setIsScheduleModalOpen(true)}>Custom Schedule</div>, key: "1" },
                          { label: <div onClick={() => createOrUpdatePostHandler('PUBLISHED')}>Publish Now</div>, key: "2" },
                          { label: <div onClick={() => createOrUpdatePostHandler('DRAFT')}>Save as Draft</div>, key: "3" },
                        ]
                      }}
                    >
                      <Button type="primary" size="large" style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0", marginLeft: "1px" }}>
                        <ChevronDownIcon width="15px" color="white" />
                      </Button>
                    </Dropdown>

                  ) : (
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: selectedPost?.post_status === "draft" ? [
                          { label: <div onClick={() => setIsScheduleModalOpen(true)}>Schedule</div>, key: "1" },
                          { label: <div onClick={() => createOrUpdatePostHandler('PUBLISHED')}>Post Now</div>, key: "2" },
                          ...(hasAvailableSlots ? [{ label: <div onClick={() => addToFirstAvailableSlot()}>Add to Queue</div>, key: "3" }] : []),
                          { label: <div onClick={() => createOrUpdatePostHandler('DRAFT')}>Save as Draft</div>, key: "3" },
                          { label: <div onClick={() => setShowDeletePopover(true)}>Delete Draft</div>, key: "4" },
                        ] : [
                          { label: <div onClick={() => setIsScheduleModalOpen(true)}>Edit Schedule Time</div>, key: "1" },
                          { label: <div onClick={() => setShowDeletePopover(true)}>Delete Post</div>, key: "4" },
                        ]
                      }}
                    >
                      <Button type="primary" size="large" style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0", marginLeft: "1px" }}>
                        <ChevronDownIcon width="15px" color="white" />
                      </Button>
                    </Dropdown>
                  )}

                  <Modal
                    title={`Delete ${selectedPost?.post_status === "draft" ? "draft" : "post"}`}
                    open={showDeletePopover}
                    onOk={async () => await deletePostHandler(selectedPostId).then(() => {
                      setShowDeletePopover(false);
                      console.log("Post Deleted!");
                    })}
                    onCancel={() => setShowDeletePopover(false)}
                  >
                    <p>Are you sure you want to delete this {selectedPost?.post_status === "draft" ? "draft" : "post"}?</p>
                  </Modal>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

