import React from "react";

export default function IconSend() {
  return (
    <svg
      style={{ width: "20px", height: "20px" }}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      focusable="false"
    >
      <path d="M21 3L0 10l7.66 4.26L16 8l-6.26 8.34L14 24l7-21z"></path>
    </svg>
  );
}
