import React, { useState } from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Accounts from '../Admin/Accounts';
import Profiles from '../Admin/Profiles';
import AiPrompts from '../Admin/AiPrompts';
import { Card, Tabs } from 'antd';

const { TabPane } = Tabs;

// Admin Index
function Index(props) {
  const [activeTab, setActiveTab] = useState(props.activeTab || 'accounts');

  const onTabClick = (e) => {
    setActiveTab(e);
    let a = '/admin/' + e;
    window.history.pushState({ activeTab: e }, '', a);
  };

  return (
    <DashboardLayout
      title="Administration panel"
      subtitle="View accounts & profiles, and login as users"
      commonData={props.commonData}
      >
      <Card className="inline-block w-full">
        <Tabs
          onTabClick={onTabClick}
          activeKey={activeTab.toString()}
          centered>

          <TabPane tab="Accounts" key="accounts">
            <Accounts activeAccounts={props.activeAccounts} />
          </TabPane>

          <TabPane tab="Profiles" key="profiles">
            <Profiles activeProfiles={props.activeProfiles} />
          </TabPane>

          <TabPane tab="AI prompts" key="ai-prompts">
            <AiPrompts prompts={props.prompts}/>
          </TabPane>

        </Tabs>
      </Card>
    </DashboardLayout>
  );
}

export default Index;
