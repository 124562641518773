import React from 'react';
import { Row, Col, Typography } from 'antd';
import IconEarth from "../Posts/icons/IconEarth";
import moment from 'moment-timezone';
import PersonListDropdown from './PersonListDropdown';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

const { Paragraph } = Typography;

class PostBlock extends React.Component {
  constructor(props) {
    super(props);

    initPostHog();

    // console.log('PostBlock props', props);
    // console.log('PostBlock people', props.people_to_lists);

    locale.locale = props.commonData.locale;

    this.state = {
      showAll: false,
      showMoreLess: locale.t("common.see_more"),
      peopleToLists: props.people_to_lists,
      lists: this.filteredLists(props.lists),
      customLists: props.customLists,
      relativeTime: moment.tz(this.props.activity.time_ago, this.props.commonData.timezone).fromNow()
    };
    this.updatePeopleToLists = this.updatePeopleToLists.bind(this);
  }

  filteredLists(lists) {
    // console.log('filteredLists', lists);
    return lists
      .filter((e) => e.type === 'CustomList')
      .map((e) => ({
        key: e.value.toString(),
        label: e.label
      }));
  }

  phrase(activity) {
    switch (activity.event_type) {
      case 'new_post':
        return this.new_post_summary(activity);
      default:
        return activity.key;
    }
  }

  split(str, index) {
    if (str == undefined) {
      return '';
    }
    const result = [str.slice(0, index), str.slice(index)];
    return result;
  }

  toggleShowMore() {
    this.setState({
      showAll: !this.state.showAll,
      showMoreLess: this.state.showAll ? locale.t("common.see_more") : ''
    });
  }

  new_post_summary(activity) {
    const complete = activity.blob.post_content;

    return (
      <>
        <div>{complete}</div>

        {activity.blob.post_image && <img className="w-full max-w-xl my-8" src={activity.blob.post_image} />}
        {activity.blob.post_poll && (
          <>
            <Paragraph>{activity.blob.post_poll}</Paragraph>
            <div className="rounded-md bg-yellow-50 p-4">
              This is a poll: Click <a href={activity.blob.permalink} target="_blank">here</a> to view it on LinkedIn
            </div>
          </>
        )}
        {activity.blob.post_video && (
          <video controls width="90%">
            <source src={activity.blob.post_video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {activity.blob.post_article && (
          <div className='inline-block bg-gray-100 p-4 mt-4'>
            <a href={activity.blob.post_article['link']} target='_blank'>
              <div className='text-center'>
                <img className="w-full max-w-xl my-2" src={activity.blob.post_article['image']} />
              </div>
              <p className="font-semibold my-2">{activity.blob.post_article['title']}</p>
              <p className="text-sm my-2">{activity.blob.post_article['subtitle']}</p>
            </a>
          </div>
        )}
        {activity.blob.post_document && (
          <iframe width="90%" style={{ margin: '12px 0', minHeight: '800px' }} src={activity.blob.post_document}></iframe>
        )}
      </>
    );
  }

  snipped_content(content) {
    return content;
  }

  updatePeopleToLists(personId, listId, action) {
    this.setState(prevState => {
      const updatedPeopleToLists = { ...prevState.peopleToLists };
      if (action === 'remove') {
        updatedPeopleToLists[personId] = updatedPeopleToLists[personId].filter(id => id !== parseInt(listId));
      } else if (action === 'add') {
        updatedPeopleToLists[personId] = [...updatedPeopleToLists[personId], parseInt(listId)];
      }
      return { peopleToLists: updatedPeopleToLists };
    });
  }

  render() {
    let activity = this.props.activity;
    let str = this.phrase(this.props.activity);

    return (
      <>
        <div>
          <Row>
            <Col span={20}>
              <div className="flex items-center mb-4">
                <div className="h-12 w-12 flex-shrink-0">
                  <img 
                    className="max-w-xl h-12 w-12 rounded-full border-2 border-solid border-brand shadow-sm" 
                    src={activity.owner.headshot_url} 
                    alt="" 
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-bold">
                    <a href={activity.owner.profile_url} target='_blank'>
                      {activity.owner.full_name}
                    </a>
                  </div>

                  <div className="text-stone-500 text-xs">{activity.owner.title_or_headline}</div>

                  <div className="text-stone-500 text-xs flex">
                    {this.state.relativeTime} • <IconEarth />
                  </div>
                </div>
              </div>
            </Col>
            <Col span={4} className="text-right">
              <PersonListDropdown
                personId={activity.owner.id}
                personName={activity.owner.full_name}
                activity={activity}
                peopleToLists={this.state.peopleToLists}
                customLists={this.state.customLists}
                onUpdateLists={this.updatePeopleToLists}
              />
            </Col>
          </Row>
        </div>

        <div
          className={`post-display ${this.state.showAll ? '' : 'post-preview'}`}
          style={{
            whiteSpace: 'pre-wrap',
            padding: ''
          }}>
          {str}
        </div>
        {activity.blob.post_content !== undefined && (
          <span className="text-primary-400 text-sm cursor-pointer float-right text-gray-500" onClick={() => this.toggleShowMore()}>
            {this.state.showMoreLess}
          </span>
        )}
      </>
    );
  }
}

export default PostBlock;

