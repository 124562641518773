import * as ApiHelper from './ApiHelpers';
const axios = require('axios');
import _ from 'underscore';

export function pollStoredJobs(commonData, callback) {
  let headers = ApiHelper.headers();
  let params = {
    linkedInUserId: commonData.linkedInUserId
  };
  headers['params'] = params;

  let self = this;

  let url = '/0/' + commonData.currentAccountUniqueToken + '/job_poller';

  axios
    .get(url, headers)
    .then(function (res) {
      let msg = res.data;
      console.log('msg', msg);

      if (!_.isEmpty(msg.data)) {
        console.log('CRED POLLING: not complete');
        console.log(msg);
        console.log('data', msg.data);
        callback(msg.data, 'working');
        setTimeout(function () {
          self.pollStoredJobs(commonData, callback);
        }, 5000);
      } else {
        console.log('redirect');
        callback(msg.data, 'done');
        return 'complete';
      }
      return false;
    })
    .catch((error) => {
      console.log('Error', error);
    });
}

export function startReportPolling(commonData, jobId, callback) {
  let headers = ApiHelper.headers();
  let params = {
    job_id: jobId
  };
  headers['params'] = params;

  if (jobId == undefined) {
    console.log('!!!!!!!!! jobId is undefined');
    return;
  }
  let self = this;

  let url = '/0/' + commonData.currentAccountUniqueToken + '/job_poller';

  axios
    .get(url, headers)
    .then(function (res) {
      let msg = res.data;

      if (msg['status'] == 470) {
        console.log('POLLING: in 470');
      } else if (msg['status'] == 400) {
        console.log('400 redirect');
      } else {
        if (msg.job_status != 'complete') {
          console.log('POLLING: not complete', msg);
          setTimeout(function () {
            self.startReportPolling(commonData, jobId, callback);
          }, 3001);
        } else {
          // console.log('Job complete', res.data);
          callback(jobId, res.data);
          return 'complete';
        }
      }
      return false;
    })
    .catch((error) => {
      console.log('Error', error);
    });
}

export function startPolling(commonData, jobId, entityUrnId, callback) {
  let headers = ApiHelper.headers();
  let params = {
    job_id: jobId
  };
  headers['params'] = params;

  let self = this;

  let url = '/0/' + commonData.currentAccountUniqueToken + '/job_poller';

  axios
    .get(url, headers)
    .then(function (res) {
      let msg = res.data;

      if (msg['status'] == 470) {
        console.log('POLLING: in 470');
      } else if (msg['status'] == 400) {
        console.log('400 redirect');
      } else {
        if (msg.job_status != 'complete') {
          console.log('POLLING: not complete');
          console.log(msg);
          setTimeout(function () {
            self.startPolling(commonData, jobId, entityUrnId, callback);
          }, 3001);
        } else {
          console.log('redirect');
          callback(entityUrnId);
          return 'complete';
        }
      }
      return false;
    })
    .catch((error) => {
      console.log('Error', error);
    });
}

export function accountSettingsUrl(commonData) {
  return urlHelper(commonData.currentAccountUniqueToken, null, '');
}

export function profileSettingsUrl(commonData) {
  return urlHelper(commonData.currentAccountUniqueToken, commonData.linkedInUserId, '');
}

export function urlHelper(accountToken, liUserId, path) {
  if (liUserId == undefined) {
    return '/0/' + accountToken + '/' + path;
  } else {
    return '/0/' + accountToken + '/li/' + liUserId + '/' + path;
  }
}
