import React from 'react';
import Posts from './Posts';
import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class MyPosts extends React.Component {
  constructor(props) {
    locale.locale = props.commonData.locale
    super(props);
    // console.log('commun', this.props);
  }

  render() {
    return (
      <Posts
        commonData={this.props.commonData}
        title={this.props.pageName}
        subtitle = { locale.t("post_scheduling.title") }
        postData={this.props.postData || []}
      ></Posts>
    );
  }
}

export default MyPosts;
