
import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import { Typography, Button, Row, Col, Modal } from 'antd';
const { Paragraph, Title } = Typography;
import ProfileToggle from '../ProfileToggle';
import EmptyContent from '../EmptyContent';
import EngageBlock from '../EngageBlock';
import moment from 'moment-timezone';
import InlineSpinner from '../InlineSpinner';
import PersonListDropdown from '../Engage/PersonListDropdown';  // Add this import
import Card from '../Card';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

import * as Helper from '../Helpers/Helpers';
import * as ApiHelper from '../Helpers/ApiHelpers';
const axios = require('axios');

class Index extends React.Component {

  constructor(props) {
    super(props);
    initPostHog();
    locale.locale = props.commonData.locale
    console.log('Index custom props', props.peopleToLists);
    
    this.state = {
      activity: props.activity,
      isModalOpen: false,
      loading: props.commonData.dueToCheckForIncomingEngagements,
      customLists: props.customLists,
      peopleToLists: props.peopleToLists,
      newCommentsFound: false
    };
    this.callback = this.callback.bind(this);

    if(props.commonData.dueToCheckForIncomingEngagements) {
      this.fetchReplies()
    }
  }

  fetchReplies(post) {
    let self = this;

    let url = "/api/v1/accounts/"  + this.props.commonData.currentAccountUniqueToken + "/li/" + this.props.commonData.linkedInUserId + "/fetch_incoming_engagements";

    axios
      .get(
        url,
        ApiHelper.headers()
      )
      .then(function (response) {
        console.log('---> response', response);
        Helper.startPolling(self.props.commonData, response.data.jobId, null, self.callback);
      });
  }

  callback() {
    console.log('+++callback');
    this.setState({ loading: false, newCommentsFound: true })
  }

  handleOk() {
    this.setState({
      isModalOpen: false
    });
  }

  handleCancel() {
    this.setState({
      isModalOpen: false
    });
  }

  blobContent(activity) {
    if(activity.blob.post_content) {
      return activity.blob.post_content
    } else {
      return ''
    }
  }

  postBlock(activity) {
    let str = activity.blob.comment;
    let relativeTime = moment.tz(activity.time_ago, this.props.commonData.timezone).fromNow()

    return (
      <>
        <div class="card-profile" style={{ display: 'flex' }}>
          <div class="flex items-center mb-4">
            <div class="h-12 w-12 flex-shrink-0">
              <img class="max-w-xl h-12 w-12 rounded-full" src={activity.recipient.headshot_url} alt="" />
            </div>
            <div class="ml-4">
              <div class="text-sm font-bold text-cyan-900 ">
                <a href={ activity.recipient.profile_url } target='_blank'>
                  {activity.recipient.full_name}
                </a>
              </div>

              <div class="text-stone-500 text-sm">{activity.recipient.title_or_headline}</div>

              <div class="text-stone-500 text-sm">{relativeTime}</div>
            </div>
          </div>
        </div>

        <div
          class={`post-preview mt-x text-sm`}
          style={{
            whiteSpace: 'pre-wrap',
            borderLeft: '3px solid #efefef',
            padding: '8px 8px 24px 64px',
            marginLeft: '42px'
          }}>
          {this.blobContent(activity)}
        </div>

        <div class="mt-4 w-full card-profile" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div class="flex items-center mb-4">
            <div class="h-12 w-12 flex-shrink-0">
              <img class="max-w-xl h-12 w-12 rounded-full" src={activity.owner.headshot_url} alt="" />
            </div>
            <div class="ml-4">
              <div class="text-sm font-bold text-cyan-900 ">
                <a href={activity.owner.profile_url} target='_blank'>
                  {activity.owner.full_name}
                </a>
              </div>

              <div class="text-stone-500 text-sm">{activity.owner.title_or_headline}</div>

              <div class="text-stone-500 text-sm">{relativeTime}</div>
            </div>
          </div>
          <div class="ml-auto flex">

            <PersonListDropdown  // Add the dropdown here
              isRepliesPage={true}
              personId={activity.owner.id}
              personName={activity.owner.full_name}
              activity={activity}
              peopleToLists={this.state.peopleToLists}
              customLists={this.state.customLists}
              onUpdateLists={(personId, listId, action) => this.setState(prevState => {
                const updatedPeopleToLists = { ...prevState.peopleToLists };
                if (action === 'remove') {
                  updatedPeopleToLists[personId] = updatedPeopleToLists[personId].filter(id => id !== parseInt(listId));
                } else if (action === 'add') {
                  updatedPeopleToLists[personId] = [...updatedPeopleToLists[personId], parseInt(listId)];
                }
                return { peopleToLists: updatedPeopleToLists };
              })}
            />

          </div>
        </div>

        <div
          class={`mt-x text-sm`}
          style={{
            whiteSpace: 'pre-wrap',
            padding: '16px 8px 24px 94px'
          }}>
          {str}
        </div>

      </>
    );
  }

  scrollTo(ref) {

   ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }

  render() {
    return (
      <DashboardLayout
        narrow={true}
        title={ locale.t("replies.title") }
        subtitle={ locale.t("replies.subtitle_start") + ' ' + this.props.activity.length + ' ' + locale.t("replies.subtitle_end") }
        commonData={this.props.commonData}>

      <div className='max-w-xl'>
        <Row className="mb-6">

          <Col xs={{ span: 24, order: 2 }} xl={{ span: 24, order: 2 }} >
            
            { this.props.commonData.currentUserRole == 'collaborator' && (
              <Card>
                <ProfileToggle
                  commonData={this.props.commonData}
                  profiles={ this.props.commonData.profiles }
                  selectedProfile={ this.props.commonData.actAsLinkedInUserId }
                  style={{ height: 49 }}/>
              </Card>
            )}

            <div className="my-8">
              { this.state.loading &&
                <InlineSpinner title="Checking for new comments..." />
              }
              { this.state.newCommentsFound &&
                <p>Done checking for new comments. <a href='#' onClick={ () => window.location.reload() } >Click to reload</a></p>
              }
            </div>

            {this.props.activity.slice().map((x) => {
              return (
                <EngageBlock
                  isRepliesPage={true}
                  callParent={(ref) => this.scrollTo(ref)}
                  commonData={this.props.commonData}
                  pages={this.props.pages}
                  postBlock={this.postBlock(x)}
                  activity={x}
                />
              );
            })}

            {this.props.activity.length == 0 && <EmptyContent />}
        </Col>
      </Row>
    </div>

        <Modal
          title="Basic Modal"
          open={this.state.isModalOpen}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}>
          {this.state.modalContents}
        </Modal>
      </DashboardLayout>
    );
  }
}

export default Index;

