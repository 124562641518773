import React, { useState } from 'react';
import SessionLayout from '../Layouts/SessionLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { Typography, Form, Input, Select, Button } from 'antd';
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

const { Title } = Typography;

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

// Create Customers
// Create Subscription (returns payment intent)
// Complete payment by passing Payment

const CardForm = (props) => {

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

	const formRef = React.useRef(null);
	console.log('props -> ', props.props);
	console.log('session -> ', props.props.session);
  locale.locale = props.props.session.locale

	ApiHelper.prepHttpCall();

  initPostHog();
  console.log('signup_page_view');
  posthog.capture('signup_page_view');

  const onLocaleToggle = async (event) => {
    locale.locale = event
    // Force language update on select toggle
    forceUpdate()
  }

	const handleSubmit = async (event) => {
		let user = {
			email: event.email,
			password: event.password,
			password_confirmation: event.password_confirmation
		};

		let params = { user: user, organization: event.organization };

		params['plan_id'] = props.props.plan_id || 1;

    posthog.capture('form_submit_signup');
    // console.log('form_submit_signup')

		// console.log('params -> ', params);

		axios
			.post('/users', params, ApiHelper.headers())
			.then(function (response) {
				// console.log('response', response);
        try {
          posthog.identify(
            response.data.id,
            { email: response.data.email }
          );
        } catch (error) {
          console.log('error', error);
        }
				window.location.href = response.headers.location;
			})
			.catch(function (error) {
				if (error.response) {
					let errors = error.response.data.errors;

					if (errors !== undefined && errors.email !== undefined) {
						formRef.current.setFields([
							{
								name: 'email',
								errors: ['Email ' + errors.email[0]]
							}
						]);
					} else {
						console.log(error.response.data);
					}
				}
			});
	};

	return (
		<SessionLayout>
			<div style={{ textAlign: 'center' }}>
				<Title level={4} className="no-border">
          { locale.t("sign_up.start_free_trial_msg") }
				</Title>
				{/* <Title level={5} style={{ paddingBottom: '12px', marginTop: '0' }}> */}
				{/*   ${props.props.price} per month */}
				{/* </Title> */}
			</div>

			<Form
				name="user"
				initialValues={{
					remember: true
				}}
				onFinish={handleSubmit}
				ref={formRef}>
				<Form.Item
					name="email"
					rules={[
						{
							type: 'email',
              message: locale.t("sign_up.invalid_email_msg")
						},
						{
							required: true,
              message: locale.t("sign_up.please_input_email_msg")
						}
					]}>
					<Input prefix={<UserIcon width={20} />} placeholder={ locale.t("sign_up.email_placeholder") } />
				</Form.Item>

				{/* <Form.Item */}
				{/*   name="organization" */}
				{/*   rules={[ */}
				{/*     { */}
				{/*       required: true, */}
				{/*       message: 'Please input your organization name!' */}
				{/*     } */}
				{/*   ]}> */}
				{/*   <Input prefix={<UserIcon width={20} />} placeholder="Organization name" /> */}
				{/* </Form.Item> */}

				<Form.Item
					name="password"
					rules={[
						{
							required: true,
              message: locale.t("sign_up.please_input_password_msg")
						},
						{ min: 8, message: locale.t("sign_up.password_too_short_msg") }
					]}>
					<Input prefix={<LockClosedIcon width={20} />} type="password" placeholder={ locale.t("sign_up.password_placeholder") } />
				</Form.Item>

				<Form.Item
					name="password_confirmation"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
              message: locale.t("sign_up.please_confirm_password_msg")
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error( locale.t("sign_up.passwords_dont_match_msg") )
								);
							}
						})
					]}>
					<Input
						prefix={<LockClosedIcon width={20} />}
						type="password"
						placeholder={ locale.t("sign_up.password_confirmation_placeholder") }
					/>
				</Form.Item>

				<Form.Item>
					<Button
						style={{ width: '100%' }}
						type="primary"
						htmlType="submit"
						className="login-form-button">
            { locale.t("sign_up.create_account_btn") }
					</Button>
				</Form.Item>

				<Form.Item>
					<a href="/users/password/new">{ locale.t("sign_up.forgot_password_link_text") }</a>
				</Form.Item>

        <div style={{ width: '100%', textAlign: 'center' }}>
          <Select style={{ width: 120 }}
              defaultValue={{ value: locale.locale }}
              onChange={onLocaleToggle}
              options={[{ value: 'en', label: <span>English</span> }, { value: 'fr', label: <span>French</span> }]} />
        </div>

			</Form>
		</SessionLayout>
	);
};

const Signup = (props) => {
	return <CardForm props={props} />;
};

export default Signup;
