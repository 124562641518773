import React from 'react';
import SearchList from './SearchList';
import { Avatar } from 'antd';

class PeopleSearchList extends React.Component {
  constructor(props) {
    super(props);
  }

  headline(person) {
    if (person.headline != undefined) {
      return person.headline;
    } else {
      let co_name = '';

      if (person.company_name != undefined) {
        co_name = person.company_name;

        return person.title + ' at ' + co_name;
      } else {
        return person.title;
      }
    }
  }

  nameOrIdentifier(e) {
    if(e.full_name.trim()) {
      return e.full_name
    } else {
      return e.public_identifier
    }
  }

  render() {
    let cols = [
      {
        render: (e) => (
          <a href={e.profile_url} target="_blank">
            <Avatar src={e.headshot_url} style={{ width: '50px', height: '50px' }} />
          </a>
        )
      },
      {
        title: 'Name',
        width: 225,
        dataIndex: '',
        key: '',
        render: (e) => (
          <a href={e.profile_url} target="_blank">
            {this.nameOrIdentifier(e)}
          </a>
        )
      },
      { title: 'Company', render: (e) => e.company && e.company.name },
      { title: 'Headline', render: (e) => e.headline },
      { title: 'Num posts last 30 days', render: (e) => e.num_posts_last_30_days },
      { title: 'Avg post engagement', render: (e) => e.avg_post_engagement },
      {
        title: 'Last post detected',
        render: (e) => {
          const last_post = e.last_post_detected;
          if(last_post) {
            const eventDate = last_post.event_date;
            const formattedDate = new Date(eventDate).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
            if (last_post) {
              return (
                <a href={last_post.blob.permalink} target="_blank">
                  {formattedDate}
                </a>
              );
            }
          } else {
            return null;
          }
        }
      }

    ];

    return (
      <SearchList
        type="Relationship"
        records={this.props.records}
        listId={this.props.listId}
        linkedInUserId={this.props.linkedInUserId}
        onAutoCompleteSelect={this.props.onAutoCompleteSelect}
        cols={cols}
        commonData={this.props.commonData}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default PeopleSearchList;
