import React from 'react';
import { Input, Form, Button } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import DashboardLayout from '../Layouts/DashboardLayout';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class New extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale
    this.onFinish = this.onFinish.bind(this);
    ApiHelper.prepHttpCall();
  }

  onFinish(values) {
    let params = {
      list: {
        name: values.name,
        type: this.props.listType
      }
    };

    let self = this;
    let url = Helper.profileSettingsUrl(this.props.commonData) + 'lists';

    axios
      .post(url, params, ApiHelper.headers())

      .then(function (response) {
        // console.log('response', response);
        let url =
          Helper.profileSettingsUrl(self.props.commonData) + 'lists/' + response.data.listId;
        window.location.href = url;
      })

      .catch((res) => {
        console.log('Error');
        console.log(res);

        this.setState({
          hasError: true
        });
      });
  }

  render() {
    return (
      <DashboardLayout title={ locale.t("new_list.title") } commonData={this.props.commonData}>
        <Form name="list" onFinish={this.onFinish} style={{ width: '350px', margin: 'auto' }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true
              }
            ]}>
            <Input placeholder={ locale.t("new_list.list_name_placeholder") } />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              { locale.t("new_list.create_list_btn_text") } 
            </Button>
          </Form.Item>
        </Form>
      </DashboardLayout>
    );
  }
}

export default New;
