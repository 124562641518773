import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { Tooltip, Card, Row, Col, Switch, message, Radio, Button, Form, Select, Typography } from 'antd';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Spinner from '../Spinner';
import _ from 'underscore';

const { Title } = Typography;
const { Paragraph } = Typography;
const axios = require('axios');

class UserMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isEditable: true, isLoaded: false };
    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url =
      '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/salesforce/connect';

    let self = this;
    axios.get(url, ApiHelper.headers()).then(function (response) {
      self.setState(
        {
          advancedInputs: response.data.advancedInputs,
          liProfileUrl: response.data.advancedInputs.matchingOptions.liProfileUrl,
          firstNameLastNameAccountName:
            response.data.advancedInputs.matchingOptions.firstNameLastNameAccountName,
          firstNameLastName: response.data.advancedInputs.matchingOptions.firstNameLastName
        },
        () => {
          self.getSfdcFields();
        }
      );
    });
  }

  getSfdcFields() {
    let self = this;

    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/salesforce/contacts/custom_fields';

    axios
      .get(url, ApiHelper.headers())

      .then(function (response) {
        let data = response.data;

        self.setState({
          isLoaded: true,
          sfdcLeadStatusPicklistValues: data.leadStatusPicklistValues,
          sfdcLeadSourcePicklistValues: data.leadSourcePicklistValues
        });
      });
  }

  saveAdvancedInput(settingName, e) {
    let settings = this.state.advancedInputs;
    settings[settingName] = e;
    this.setState({ advancedInputs: settings });
  }

  saveMatchingOptions(settingName, e) {
    this.setState({ [settingName]: e });
    console.log('state', this.state);
  }

  popMessage(str) {
    message.info(str);
  }

  saveForm() {
    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/salesforce/connect/save_advanced_inputs';
    let inputData = this.state.advancedInputs;
    let matchingOptions = inputData['matchingOptions'];

    matchingOptions['liProfileUrl'] = this.state.liProfileUrl;
    matchingOptions['firstNameLastName'] = this.state.firstNameLastName;
    matchingOptions['firstNameLastNameAccountName'] = this.state.firstNameLastNameAccountName;
    inputData.matchingOptions = matchingOptions;

    let data = { advanced_inputs: inputData };

    let self = this;
    axios.put(url, data, ApiHelper.headers()).then(function (response) {
      self.popMessage('Advanced inputs saved');
    });
  }

  drawAdvancedInputs() {
    let r = (
      <>
        <Form layout="vertical">
          <Form.Item label="What Lead Source should we set in Salesforce for new leads?">
            <Select
              style={{ width: 249 }}
              disabled={!this.state.isEditable}
              defaultValue={this.state.advancedInputs.defaultLeadSource}
              onChange={(e, val) => this.saveAdvancedInput('defaultLeadSource', e)}>
              <Select.Option key="Aware" value="Aware">
                Aware
              </Select.Option>
              {this.state.sfdcLeadSourcePicklistValues.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Create new Lead records in CRM if none are found?">
            <Radio.Group
              onChange={(e) => this.saveAdvancedInput('createNewLeadRecords', e.target.value)}
              disabled={!this.state.isEditable}
              defaultValue={this.state.advancedInputs.createNewLeadRecords}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="What Lead Status should we set in Salesforce for new leads?">
            <Select
              defaultValue={this.state.advancedInputs.defaultLeadStatusForNewLeads}
              disabled={!this.state.isEditable}
              style={{ width: 249 }}
              onChange={(e, val) => this.saveAdvancedInput('defaultLeadStatusForNewLeads', e)}>
              {this.state.sfdcLeadStatusPicklistValues.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Title level={4}>Match on:</Title>

          <Form.Item label="LinkedIn profile URL (must be mapped in section above)" name="linkedInProfileURL">
            <Switch
              onChange={(checked, e) => this.saveMatchingOptions('liProfileUrl', checked)}
              checked={this.state.liProfileUrl}></Switch>
          </Form.Item>

          <Form.Item
            label="First Name, Last Name, Account name"
            name="firstNameLastNameAccountName">
            <Switch
              onChange={(checked, e) =>
                this.saveMatchingOptions('firstNameLastNameAccountName', checked)
              }
              checked={this.state.firstNameLastNameAccountName}></Switch>
            <Tooltip title="We often do not have the account name for a person, for various reasons. Selecting this option may dramatically reduce the number of matches found.">
              <QuestionMarkCircleIcon width="20px" style={{ color: 'gray', verticalAlign: 'middle' }} />
            </Tooltip>
          </Form.Item>

          <Form.Item label="First Name and Last Name" name="firstNameLastName">
            <Switch
              onChange={(checked, e) => this.saveMatchingOptions('firstNameLastName', checked)}
              checked={this.state.firstNameLastName}></Switch>
          </Form.Item>

          <Button
            type="primary"
            size="large"
            disabled={!this.state.isEditable}
            style={{ marginTop: '36px', marginRight: '100px', float: 'right' }}
            onClick={() => this.saveForm()}>
            Save
          </Button>
        </Form>
      </>
    );
    return r;
  }

  render() {
    return (
      <Card className='mb-16'>
				<Title level={4}>Map your Salesforce user</Title>
        <Row>

          <Col span={12}>
            <Paragraph>Which Salesforce user belongs to this LinkedIn profile?</Paragraph>
          </Col>

          <Col span={12}>
            {!this.state.isLoaded && <Spinner />}
            {this.state.isLoaded ? this.drawAdvancedInputs() : <></>}
          </Col>

        </Row>
      </Card>
    );
  }
}

export default UserMap;
