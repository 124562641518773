import React, { useState, useEffect, useRef, useContext } from "react";
import { CloseOutlined } from '@ant-design/icons';
import IconLike from "../icons/IconLike";
import IconComment from "../icons/IconComment";
import IconShare from "../icons/IconShare";
import IconSend from "../icons/IconSend";
import { LikeEmoji } from "../icons/Emojis";
import IconEarth from "../icons/IconEarth";
import { EditorThemeContext } from "../EditorContext";

const PostPreviewText = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(window.getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * 4; 
      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true);
      }
    }
  }, [content]);

  return (
    <div
      ref={contentRef}
      style={{
        paddingTop: "12px",
        fontSize: "14px",
        color: "var(--text-color-primary)",
        lineHeight: "150%",
        letterSpacing: "normal",
        maxHeight: isExpanded ? "none" : "4.5em",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: content || "" }} />
      {isTruncated && !isExpanded && (
        <span
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "0 5px",
            background: "var(--bg-color-secondary)",
            cursor: "pointer",
            color: "var(--action-link-color)",
          }}
          onClick={() => setIsExpanded(true)}
        >
          ...see more
        </span>
      )}
			{isExpanded && (
				<div style={{ textAlign: "right" }}>
					<span
						style={{
							color: "var(--action-link-color)",
							cursor: "pointer",
						}}
						onClick={() => setIsExpanded(false)}
					>
						...see less
					</span>
				</div>
			)}

    </div>
  );
};

const SocialPost = ({ attachment, handleRemoveAttachment }) => {
  const { deviceWidth, theme, editorHtml, commonData } = useContext(EditorThemeContext);

  const engagementButtonClass = `flex text-xs font-bold items-center justify-center ${
    theme === "light" ? "text-gray-500 hover:text-blue-600" : "text-white"
  } px-2 py-1 rounded cursor-pointer`;

  return (
    <div
      className="rounded overflow-hidden text-gray-800 m-2 post-container"
      style={{
        border: "1px solid --bg-color-secondary",
        backgroundColor: "var(--bg-color-secondary)",
        width: deviceWidth,
      }}
    >
      <div className="px-6 pt-4">
        <div className="flex items-center">
          <img
            className="w-12 h-12 rounded-full mr-2"
            onError={(event) => {
              event.target.src = "/images/default-avatar.png";
            }}
            src={commonData?.currentLinkedInUserAvatar || "/images/default-avatar.png"}
            alt="Profile avatar"
          />
          <div className="text-sm">
            <p
              className="leading-none font-bold"
              style={{ fontSize: "14px", lineHeight: "16px", color: "var(--text-color-primary)", margin: "0px" }}
            >
              {commonData?.currentLinkedInUserName || "User"}
            </p>
            <p
              className="text-gray-500"
              style={{ fontSize: "12px", lineHeight: "16px", marginTop: "2px", marginBottom: "0px" }}
            >
              LinkedIn /
            </p>
            <p className="text-gray-500 flex" style={{ fontSize: "12px", margin: "0px" }}>
              1h • <IconEarth />
            </p>
          </div>
        </div>

        <div className="preview-wrapper">
          <PostPreviewText content={editorHtml} />
        </div>

        <div className="mt-4" style={{ fontSize: "14px", color: "var(--text-color-primary)", lineHeight: "120%", letterSpacing: "normal" }}></div>
      </div>
      {attachment?.renderUrl && (
        <div className="relative my-2">
          <div
            onClick={handleRemoveAttachment}
            className="absolute top-0 right-0 z-10 bg-gray-700 hover:bg-gray-800 p-1 m-2 rounded-full cursor-pointer text-white text-sm"
          >
            <CloseOutlined />
          </div>
          <img src={attachment?.renderUrl} className="w-full object-cover" />
        </div>
      )}

      <div className="text-gray-500 flex justify-between px-4 pt-2 pb-1">
        <span className="text-xs flex">
          <img src={LikeEmoji} style={{ height: "16px" }} />
          <span>5</span>
        </span>
        <span className="text-xs text-muted">13 Comments</span>
      </div>
      <div className="px-2 pt-4 pb-2 grid grid-cols-4 grid-flow-col gap-1">
        <div className={engagementButtonClass}>
          <IconLike />
          <span className="ml-1">Like</span>
        </div>
        <div className={engagementButtonClass} style={{ minWidth: "96px" }}>
          <IconComment />
          <span className="ml-1">Comment</span>
        </div>
        <div className={engagementButtonClass}>
          <IconShare />
          <span className="ml-1">Share</span>
        </div>
        <div className={engagementButtonClass}>
          <IconSend />
          <span className="ml-1">Send</span>
        </div>
      </div>
    </div>
  );
};

export default SocialPost;

