import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import { Card, message, Button, Tabs, Select, Row, Col, Form, Typography } from 'antd';
import Spinner from '../Spinner';
import _ from 'underscore';

const { TabPane } = Tabs;
const axios = require('axios');
const { Title, Paragraph } = Typography;

class Mappings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: true,
      isLoaded: false
    };

    this.onContactMappingChange = this.onContactMappingChange.bind(this);
    // this.onLeadMappingChange = this.onLeadMappingChange.bind(this);

    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url =
      '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/hubspot/connect';

    let self = this;
    axios.get(url, ApiHelper.headers()).then(function (response) {
      console.log('reponse contact maps', response.data.contactMappings)
      self.setState(
        {
          hubspotContactMappings: response.data.contactMappings,
        },
        () => {
          if (!self.state.isLoaded) {
            self.getContactFields();
          }
        }
      );
    });
  }

  getContactFields() {
    let self = this;

    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/hubspot/contacts/custom_fields';
    axios
      .get(url, ApiHelper.headers())

      .then(function (response) {
        let data = response.data;

        self.setState(
          {
            isLoading: false,
            // hubspotAllLeadFields: data.allLeadFields,
            hubspotContactFields: data.contactFields,
            // hubspotLeadFields: data.leadFields
          },
          () => {
            self.renderContactMappingsTab();
            // self.renderLeadMappingsTab();
          }
        );
      })
  }

  renderContactMappingsTab() {
    let itemList = [];

    this.state.hubspotContactMappings.map((item, index) => {
      let type = this.state.hubspotContactMappings[index]['type'];

      let options = [];

      if (type in this.state.hubspotContactFields) {
        options = this.state.hubspotContactFields[type];
      }

      itemList.push(
        this.selectDraw(this.state.hubspotContactMappings[index], options, this.onContactMappingChange)
      );
    });

    this.setState({ contactMappings: itemList, isLoaded: true });
  }

  // renderLeadMappingsTab() {
  //   let itemList = [];
  //
  //   this.state.hubspotLeadMappings.map((item, index) => {
  //     let type = this.state.hubspotLeadMappings[index]['type'];
  //
  //     let options = [];
  //
  //     if (type == undefined && this.state.hubspotAllLeadFields.length > 0) {
  //       let optionId = this.state.hubspotLeadMappings[index]['option_id'];
  //
  //       options = this.optionForFieldByName(this.state.hubspotAllLeadFields, optionId);
  //     } else if (type in this.state.hubspotLeadFields) {
  //       options = this.state.hubspotLeadFields[type];
  //     }
  //
  //     if (options == undefined) {
  //       return;
  //     }
  //
  //     itemList.push(
  //       this.selectDraw(this.state.hubspotLeadMappings[index], options, this.onLeadMappingChange)
  //     );
  //   });
  //
  // }

  onTabChange(value) {
    switch (value) {
      case 'tab-lead-mappings':
        this.renderLeadMappingsTab();
        break;
      case 'tab-contact-mappings':
        this.renderContactMappingsTab();
        break;
      default:
    }
  }

  mappingsToSelections(mappings) {
    let data = {};
    mappings.map((item) => {
      if (item['selected'] != undefined) {
        data[item['id']] = item['selected'];
      }
    });
    return data;
  }

  onContactMappingChange(fieldId, e) {
    let item = _.findWhere(this.state.hubspotContactMappings, { id: fieldId });
    if (e == 'not-mapped') {
      item['selected'] = null;
    } else {
      item['selected'] = e;
    }
  }

  // onLeadMappingChange(fieldId, e) {
  //   let item = _.findWhere(this.state.hubspotLeadMappings, { id: fieldId });
  //   if (e == 'not-mapped') {
  //     item['selected'] = null;
  //   } else {
  //     item['selected'] = e;
  //   }
  // }

  saveMappings(type) {
    let self = this;

    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/hubspot/connect/save_mappings';

    let data = {};

    let hubspotContactSelections = this.mappingsToSelections(this.state.hubspotContactMappings);
    // let hubspotLeadSelections = this.mappingsToSelections(this.state.hubspotLeadMappings);

    if (type == 'contact') {
      data = { contact_mappings: hubspotContactSelections };
    } else {
      // data = { lead_mappings: hubspotLeadSelections };
    }

    axios.put(url, data, ApiHelper.headers()).then(function (response) {
      self.popMessage('Mappings saved');
    });
  }

  popMessage(str) {
    message.info(str);
  }

  selectDraw(mapping, hubspotOptions, callback) {
    return (
      <Row key={mapping['name']} style={{ marginBottom: '18px' }}>
        <Col xs={24} lg={12}>
          <Form.Item required={mapping['required']} label={mapping['name']}>
            <Select
              defaultValue={mapping['selected'] || 'not-mapped'}
              style={{ width: 249 }}
              disabled={!this.state.isEditable}
              onChange={(e, val) => callback(mapping['id'], e)}
              id={mapping['id']}>
              <Select.Option key="not-mapped" value="not-mapped">
                (not mapped)
              </Select.Option>

              {hubspotOptions.map((item) => {
                return (
                  <Select.Option key={item['id']} value={item['id']}>
                    {item['name']}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    );
  }

  renderContent() {
    return (
      <Tabs onChange={(e) => this.onTabChange(e)}>
        <TabPane tab="Contact mappings" key="tab-contact-mappings">
          <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} layout="vertical">
            {this.state.contactMappings}
          </Form>
          <Button
            type="primary"
            size="large"
            disabled={!this.state.isEditable}
            style={{ marginTop: '36px', marginRight: '100px', float: 'right' }}
            onClick={(e) => this.saveMappings('contact')}>
            Save
          </Button>
        </TabPane>

      </Tabs>
    );
  }

  render() {
    return (
      <Card className='mb-4'>
				<Title level={4}>Mappings <span style={{ fontSize: '14px' }}>(Selections here apply to all profiles in this Aware account)</span></Title>
        <Row>

          <Col xs={24} lg={12}>
            <Paragraph>Choose how you would like to map the data to Hubspot.</Paragraph>
          </Col>

          <Col xs={24} lg={12}>
            {!this.state.isLoaded && <Spinner />}
            {this.state.isLoaded && this.renderContent()}
          </Col>

        </Row>
      </Card>
    );
  }
}

export default Mappings;
