import React from 'react';
import Engage from '../Engage/Index';
import * as Helper from '../Helpers/Helpers';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {

  constructor(props) {
    locale.locale = props.commonData.locale
    super(props);
  }

  emptyContentMessage() {
    return (
      <>
        { locale.t("my_lists.empty_list_msg") }
        <br />
        <a href={Helper.profileSettingsUrl(this.props.commonData) + 'lists/'}>{ locale.t("my_lists.empty_list_new_list_btn") } </a>
        { locale.t("my_lists.empty_list_new_list_msg") }
        
      </>
    );
  }

  render() {
    return (
      <Engage
        commonData={this.props.commonData}
        pages={this.props.pages}
        prompts={this.props.prompts}
        lists={this.props.lists}
        customLists={this.props.customLists}
        selectedListValue={this.props.selectedListValue}
        listType={this.props.listType}
        pageName={this.props.pageName}
        title={ locale.t("my_lists.header") }
        subtitle={ locale.t("my_lists.sub_header") }
        emptyContentMessage={this.emptyContentMessage()}></Engage>
    );
  }
}

export default Index;
