import React from 'react';
import * as Utils from '../Helpers/Utils';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import { message, Button, Typography, Switch, Table } from 'antd';
import _ from 'underscore';

const axios = require('axios');
const { Title } = Typography;

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    super(props);

    locale.locale = props.commonData.locale

    ApiHelper.prepHttpCall();

    // console.log('init sel', this.props.selections);
    this.state = {
      header: this.props.header,
      selections: this.props.selections || []
    };
  }

  componentDidUpdate(prevProps) {
    // console.log('did up', this.props.selections);
    if (prevProps.selections !== this.props.selections) {
      this.setState({
        selections: this.props.selections
      });
    }
  }

  onCheckboxClick(v) {
    let arr = [];
    // console.log('sel', this.state.selections);
    if (this.state.selections.indexOf(v.id) === -1) {
      arr = [...this.state.selections, v.id];
    } else {
      arr = Utils.removeFromArray(this.state.selections, v.id);
    }

    this.setState({
      selections: arr
    });
  }

  popMessage(str) {
    message.info(str);
  }

  saveSelections() {
    let self = this;

    let url;

    // if (this.props.notifiableType == 'LinkedInUser') {
    //   url = '/api/v1/li/' + this.props.commonData.selectedLinkedInUserId + '/notifications';
    // } else {
      url =
        '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/notifications';
    // }

    axios
      .put(
        url,
        { li_user_id: this.props.commonData.linkedInUserId, selections: this.state.selections, notifiable_type: this.props.notifiableType },
        ApiHelper.headers()
      )
      .then(function (response) {
        self.popMessage("Selections saved");
      });
  }

  tableCols() {
    let k = [
      {
        title: locale.t("triggers.name_header"),
        render: (e) => e.name
      }
    ];

    this.props.colNames.map((x) => {
      k.push({
        title: x.replace('_', ' '),
        align: 'center',
        render: (e) => (
          <Switch
            onChange={() => this.onCheckboxClick(e, x)}
            checked={_.contains(this.state.selections, e.id)}></Switch>
        )
      });
    });

    return k;
  }

  render() {
    return (
      <>
        {this.state.header && <Title level={3}>{this.state.header}</Title>}

        <Table
          bordered
          rowKey="name"
          columns={this.tableCols()}
          dataSource={this.props.triggers.triggers}
          pagination={false}
        />

        <Button
          type="primary"
          size="large"
          style={{ marginTop: '36px', float: 'right' }}
          onClick={() => this.saveSelections()}>
          { locale.t("triggers.save_btn") }
        </Button>
      </>
    );
  }
}

export default Index;
