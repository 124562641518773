import React from 'react';
import Theme from './Theme';
import { Card, Layout } from 'antd';
const { Content } = Layout;

class SessionLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Theme>
        <Layout>
          <Content style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', width: '408px' }}>
              <Card className="top-card">
                <div style={{ textAlign: 'center' }}>
                  <a href="/">
                    <img className="logo-dark" />
                    <h2 style={{ padding: 0, fontWeight: 600, margin: '0 0 24px', textTransform: 'uppercase', color: '#5267698f' }}>Aware</h2>
                  </a>
                </div>

                {this.props.children}
              </Card>
            </div>
          </Content>
        </Layout>
      </Theme>
    );
  }
}

export default SessionLayout;
