import React from 'react';
import { Select } from 'antd';

const roleOptions = [
  { value: 'individual_contributor', label: 'Individual Contributor' },
  { value: 'team_observer', label: 'Team Observer' },
  { value: 'collaborator', label: 'Collaborator' }
];

function RoleSelect({ onChange, defaultValue, isMasterAdmin}) {
  return (
    <Select
      className='w-full'
      options={roleOptions}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}

export default RoleSelect;

