import { Button } from "antd";
import React, { useContext } from "react";
import { EditorThemeContext } from "../../Posts/EditorContext";
import { DocumentIcon } from "@heroicons/react/24/outline";

const NewPostButton = () => {
  const {
    setIsSlotDrawerOpen,
    setIsSchedulingPostNow,
    setSelectedPost,
    setEditorPostAttachment
  } = useContext(EditorThemeContext);

  return (
    <Button
      onClick={() => {
        setIsSchedulingPostNow(true);
        setIsSlotDrawerOpen(true);
        setEditorPostAttachment(null);
        setSelectedPost(null);
      }}
      type="primary"
      className="flex items-center space-x-1"
      icon={<DocumentIcon width="20px" />}
      size="middle"
    >
      New Post
    </Button>
  );
};

export default NewPostButton;

