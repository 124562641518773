import React from 'react';
import { Card, Row, Col } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import DashboardLayout from '../Layouts/DashboardLayout';
import Hubspot from '../Hubspot/Hubspot';
import Salesforce from '../Salesforce/Salesforce';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';
const axios = require('axios');

import { Icon } from '@iconify/react';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    locale.locale = props.commonData.locale
    super(props);

    initPostHog();
    // console.log('email', this.props.commonData.currentUserEmail);
    posthog.setPersonPropertiesForFlags({'email': this.props.commonData.currentUserEmail})
    this.state = {
      integrationPage: 'home'
    }
  }

  componentDidMount() {
    if (this.props.integrationPage == 'salesforce') {
      this.onSalesforceClick();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.integrationPage !== this.props.integrationPage) {
      this.setState({ integrationPage: this.props.integrationPage });
    }
  }

  onBack() {
    this.setState({ integrationPage: 'home' });
  }

  onHubspotClick() {
    this.setState({ integrationPage: 'hubspot' });
  }

  onSalesforceClick() {
    // if (this.props.commonData.hasSfdcAccess) {
    this.setState({ integrationPage: 'salesforce' });
    // } else {
    //   ApiHelper.prepHttpCall();

    //   let url =
    //     '/api/v1/accounts/' +
    //     this.props.commonData.currentAccountUniqueToken +
    //     '/billing/create_customer_portal_session';

    //   axios.post(url, {}, ApiHelper.headers()).then(function (response) {
    //     window.location.href = response.data.session_url;
    //   });
    // 
  }

  home() {

    return (
      <DashboardLayout
        className="fixed-width-wide"
        title={ locale.t("crm.title") }
        commonData={this.props.commonData}>

        <Row gutter={[16, 24]} style={{ marginTop: '24px', paddingBottom: '25px' }}>

          <Col xs={24} lg={12} xl={12}>
            <Card
              actions={[
                <>
                  Salesforce
                  {this.props.commonData.hasSfdcAccess && (
                    <>
                      <br />
                      <span style={{ fontStyle: 'italic', fontSize: '14px', color: 'red' }}>
                        Upgrade required
                      </span>
                    </>
                  )}
                </>
              ]}
              style={{ padding: '24px 0 0', textAlign: 'center' }}
              onClick={() => this.onSalesforceClick()}>
              <Icon icon="logos:salesforce" className='w-full h-32' />
            </Card>
          </Col>


          <Col xs={24} lg={12} xl={12}>
            <Card
              actions={[
                <>
                  Hubspot
                </>
              ]}
              style={{ padding: '24px 0 0', textAlign: 'center' }}
              onClick={() => this.onHubspotClick()}>
              <Icon icon="logos:hubspot" className='w-full h-32' />
            </Card>
          </Col>

        </Row>
      </DashboardLayout>
    );
  }

  hubspot() {
    return (
      <Hubspot
        onBack={() => this.onBack()}
        commonData={this.props.commonData}
        currentAccountUniqueToken={this.props.commonData.currentAccountUniqueToken}
      />
    );
  }

  salesforce() {
    return (
      <Salesforce
        onBack={() => this.onBack()}
        commonData={this.props.commonData}
        currentAccountUniqueToken={this.props.commonData.currentAccountUniqueToken}
      />
    );
  }

  component() {
    switch (this.state.integrationPage) {
      case 'home':
        return this.home();
      case 'hubspot':
        return this.hubspot();
      case 'salesforce':
        return this.salesforce();
      default:
        console.log('We are out of ideas.');
    }
  }

  render() {
    return <>{this.component()}</>;
  }
}

export default Index;
