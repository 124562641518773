import React, { useEffect, useRef, useContext } from 'react';
import PostEditor from './components/PostEditor';
import PostToolbar from './components/PostToolbar';
import SocialPost from './components/SocialPost';
import { EditorThemeContext } from './EditorContext';

export default function NewPostContainer({ 
  commonData, 
  postData = null, 
  mode = "publish", 
  prePostContent = null, 
}) {
  const attachmentInput = useRef(null);
  const { 
    theme, 
    selectedPost,
    contextHolder: toastContextHolder,
    setEditorHtml,
    setEditorText,
    editorAttachment, setEditorPostAttachment,
  } = useContext(EditorThemeContext);

  const editorStateChange = (postHtml, postText) => {
    setEditorHtml(postHtml);
    setEditorText(postText);
  }

  const handleImageChange = (e) => {
    const targetFiles = e.target.files;
    if (targetFiles.length < 1) return;

    const imageFile = targetFiles[0];
    console.log(imageFile);

    const fileSizeInBytes = imageFile.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    // Check if file size exceeds 5MB
    if (fileSizeInMB > 5) {
      alert("Image file size should not exceed 5MB.");
      return;
    }

    if (imageFile && imageFile.type.includes("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditorPostAttachment({
          file: imageFile,
          renderUrl: reader.result,
        });
      }
      reader.readAsDataURL(imageFile);
    }
  }

  return (
    <section className='max-w-xl post-editor-container'>
      {toastContextHolder}
      <section className={`${theme}`}>
        <PostEditor
          onChange={editorStateChange}
          handleImageChange={handleImageChange}
          attachmentInput={attachmentInput}
          commonData={commonData}
          prePostContent={selectedPost?.post_text ? selectedPost?.post_text : prePostContent}
        />
      </section>
      <section
        className={`m-0 mt-4 p-2 ${theme} demo-wrapper border border-solid border-gray-300 rounded-lg`}
      >
        <PostToolbar />
        <SocialPost
          attachment={editorAttachment}
          handleRemoveAttachment={() => {
            setEditorPostAttachment(null);
            if (attachmentInput.current) attachmentInput.current.value = "";
          }}
        />
      </section>
    </section>
  )
}

