import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function downloadMultipleReports(reports, fileName = 'files.zip') {
    const zip = new JSZip();
    
    reports.forEach(file => {
      zip.file(file.name, file.content);
    });
    
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      saveAs(content, fileName);
    });
}

function downloadCsv(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);
}

export {
    downloadCsv,  
    downloadMultipleReports
}