import React from 'react';
import { ConfigProvider } from 'antd';

class Theme extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Plus Jakarta Sans',
              fontSize: 16,
              bodySortBg: 'hsl(0, 0%, 100%)',
              // colorText: 'hsl(209, 28%, 39%)',
              colorText: 'hsl(26, 6%, 22%)',
              // colorLink: 'hsl(185, 57%, 50%)',
              colorLink: 'hsl(176, 48%, 56%)',
              // colorLink: '#ba5b38',
              // colorPrimary: 'hsl(185, 57%, 50%)',
              colorPrimary: 'hsl(176, 48%, 56%)',
              // colorPrimary: '#ba5b38',
              // colorPrimaryBgHover: 'hsl(209, 28%, 39%)',
              colorError: 'hsl(360, 71%, 66%)'
            }
          }}>
          {this.props.children}
        </ConfigProvider>
      </>
    );
  }
}

export default Theme;
