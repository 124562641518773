import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Spinner from '../Spinner';
import * as Helper from '../Helpers/Helpers';
import * as CookieHelper from '../Helpers/CookieHelper';
import { Card, Image, message, Typography, Button } from 'antd';
const { Text, Paragraph, Title } = Typography;
import { ArrowDownIcon } from '@heroicons/react/24/outline';
import chromeImg from '../../images/chrome_128x128.png';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

class Profile extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    initPostHog();
    super(props);
    // console.log('--', props);

    this.state = {
      step: 0
    };

    this.cookieCallback = this.cookieCallback.bind(this);
    CookieHelper.addCookieListener(this.cookieCallback, this.props.commonData.licenseKey);
  }

  componentDidMount() {
    console.log('mounted')
    const script = document.createElement("script");
    script.innerHTML = "fpr('referral', { email: '" + this.props.commonData.currentUserEmail + "' }, () => { console.log('fired') } )"
    script.async = true;
    document.body.appendChild(script);
  }

  cookieCallback(cookieFound) {
    console.log('Profile: cookieCallback', message);

    let currentStep = 2;
    if (cookieFound) {
      currentStep = 3;
    }

    this.setState({ step: currentStep });
  }

  onChromeExtClick(evt) {
    evt.preventDefault();
    console.log('open');
    window.open(
      'https://chrome.google.com/webstore/detail/aware/mkialmjffglbmngpamedjgplfemoiope',
      '_blank',
      'noreferrer'
    );
    this.setState({ step: 1 });
  }

  popMessage(str) {
    message.info(str);
  }

  render() {
    let accountSettingsUrl = Helper.accountSettingsUrl(this.props.commonData);

    if( this.state.step == 0 ) {
      try {
        posthog.capture('setup_profile_step_0');
        console.log('step 0');
      } catch(e) {}
    } else if( this.state.step == 1 ) {
      try {
        posthog.capture('setup_profile_step_1_clicked_add_to_chrome');
      } catch(e) {}
    } else if( this.state.step == 2 ) {
      try {
        posthog.capture('setup_profile_step_2_ext_found');
      } catch(e) {}
    } else if( this.state.step == 3 ) {
      try {
        posthog.capture('setup_profile_step_3_done');
      } catch(e) {}
    }

    return (
      <DashboardLayout
        muteCookieListener={true}
        isSetup={true}
        title=""
        subtitle=""
        hideCookieWarning={true}
        commonData={this.props.commonData}>
        <Card
          className="top-card"
          style={{ marginBottom: '16px', textAlign: 'center', padding: '24px 12px' }}>
          {this.state.step == 0 && (
            <>
              <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
                Welcome, {this.props.commonData.currentOrgName}
              </Paragraph>
              <Paragraph style={{ fontSize: '42px', marginBottom: '8px' }}>First step,</Paragraph>

              <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
                Install the <b>Aware</b> browser extension to get started.
                <br />
                Note: Once we're connected to LinkedIn, you can use Aware with any browser you prefer.
              </Paragraph>

              <Paragraph style={{ fontSize: '14px', marginBottom: '8px' }}>Click here!</Paragraph>

              <div>
                <ArrowDownIcon width={18} className="bounce" />
              </div>
            </>
          )}
          {this.state.step == 1 && (
            <>

              <Title level={3}>
                Welcome! 
              </Title>
              <Paragraph className="mb-8">
                In under two minutes, we'll show you how to get the most out of Aware.
              </Paragraph>
    

              <iframe width="560" height="315" src="https://www.youtube.com/embed/gpZSznxg-0M?si=Tlk9kneuYNuK406Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>

              <Paragraph className="mt-4" style={{ fontSize: '18px', marginBottom: '24px' }}>
                <a
                  href="#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    window.location.reload();
                  }}>
                  Click here
                </a>{' '}
                once you've installed the Aware Chrome Extension and watched the video above.
              </Paragraph>
            </>
          )}
          {this.state.step == 2 && (
            <>
              <Spinner title="Chrome extension found. Please login to LinkedIn in this same browser." />
              <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}></Paragraph>
            </>
          )}
          {this.state.step == 3 && (
            <>
              <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
                You are amazing, you're in!
                <br />
                <a href="/">
                  We're still pulling in data, but you can head on over to your dashboard
                </a>
              </Paragraph>
            </>
          )}

          {this.state.step == 0 && (
            <>
              <Button
                id="install-chrome-ext"
                onClick={(e) => this.onChromeExtClick(e)}
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  backgroundColor: 'hsl(185, 57%, 50%)',
                  padding: '12px 24px',
                  height: '100%'
                }}>
                <Image width={40} src={chromeImg} />
                <span style={{ marginLeft: '16px' }}>Add to Chrome</span>
              </Button>
            </>
          )}

        </Card>

        {/* {this.props.commonData.isAccountOwner && ( */}
        {/*   <a href={accountSettingsUrl + 'setup/invite'}>Later, I want to invite my team first</a> */}
        {/* )} */}
      </DashboardLayout>
    );
  }
}

export default Profile;
