
require.context('../images');

// import 'css/App.less';
import '/app/assets/builds/tailwind.css';

// Support component names relative to this directory:

var componentRequireContext = require.context('components', true);

var ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
