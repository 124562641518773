import React, {useState} from 'react';
import { Tooltip, Button, Table, Popconfirm, Popover, Input } from 'antd';
import * as Helper from '../Helpers/Helpers';
import * as ApiHelper from '../Helpers/ApiHelpers';
import DashboardLayout from '../Layouts/DashboardLayout';
import { QuestionMarkCircleIcon, PlusIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'underscore';
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props', props);
    locale.locale = props.commonData.locale
    this.state = {
      lists: this.props.lists,
      editingListId: null,
      newListName: '',
    };
    ApiHelper.prepHttpCall();
  }

  removeItem(listId) {
    // console.log(listId);
    let newArr = _.reject(this.state.lists, function (e) {
      return e.id == listId;
    });

    this.setState({
      lists: newArr
    });

    let url = this.urlHelper(listId);

    console.log('url', url);
    axios.delete(url, ApiHelper.headers()).then(function (response) {  });
  }

  editItem(listId, newName) {
    console.log(listId, newName);
    let newList = _.map(this.state.lists, function (e) {
      if (e.id == listId) {
        e.name = newName;
      }
      return e;
    });

    this.setState({
      lists: newList,
      editingListId: null,
      newListName: '',
    });

    let url = this.urlHelper(listId);

    console.log('url', url);
    axios.patch(url, { name: newName }, ApiHelper.headers()).then(function (response) { });
  }

  urlHelper(listId) {
    return Helper.profileSettingsUrl(this.props.commonData) + 'lists/' + listId;
  }

  handleInputChange = (event) => {
    this.setState({ newListName: event.target.value });
  };

  handleEditButtonClick = (listId, name) => {
    this.setState({ editingListId: listId, newListName: name });
  };

  handleCancelButtonClick = () => {
    this.setState({ editingListId: null, newListName: '' });
  };

  listNameBlock = (e) => {
  // URL to the edit page for the list
  let editUrl = Helper.profileSettingsUrl(this.props.commonData) + 'lists/' + e.id;

  // Render the list name as a link to the edit page
  let listName = (
    <a href={editUrl}>
      {e.name} 
    </a>
  );

  let name = (
    <>
      {listName}
      {this.state.editingListId === e.id ? (
        <div style={{ display: 'flex' }}>
          <Input
            className="w-1/3"
            value={this.state.newListName}
            onChange={this.handleInputChange}
          />
          <Button className="ml-3" onClick={() => this.editItem(e.id, this.state.newListName)}>
            Save
          </Button>
          <Button className="ml-3" onClick={this.handleCancelButtonClick}>
            Cancel
          </Button>
        </div>
      ) : (
        <a
          className="float-right ml-3"
          onClick={() => this.handleEditButtonClick(e.id, e.name)}
          style={{ cursor: 'pointer' }}
        >
          rename list
        </a>
      )}
    </>
  );

  if (e.query) {
    return (
      <>
        {name}
      </>
    );
  } else {
    return <>{name}</>;
  }
};

  render() {
    let columns = [
      {
        title: locale.t("add_edit_lists.list_name_col_header"),
        render: (e) => (
          <div>
            {this.listNameBlock(e)}
          </div>
        )
      },
      {
        title: locale.t("add_edit_lists.search_data_col_header"),
        render: (e) => (
          e.query
        )
      },
      {
        width: '40px',
        title: '',
        render: (record) => (
          <Popconfirm
            title={ locale.t("add_edit_lists.confirm_delete_msg") }
            onConfirm={() => this.removeItem(record.id)}
          >
            <Button size="small" type="primary" danger>Delete</Button>
          </Popconfirm>
        ),
      },
    ];

    return (
      <DashboardLayout
        title={ locale.t("add_edit_lists.title") }
        subtitle={ locale.t("add_edit_lists.sub_title") }
        commonData={this.props.commonData}>

      <div className="flex flex-col">
        <div className="flex items-center justify-between mt-4 space-x-4">
          <Button href={Helper.profileSettingsUrl(this.props.commonData) + 'my_lists'}>
            <ChevronLeftIcon width="16px" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
            {locale.t("add_edit_lists.back_btn")}
          </Button>

          <div className="flex space-x-4">
            <Button
              type="primary"
              href={Helper.profileSettingsUrl(this.props.commonData) + 'lists/new_from_search'}>
              <PlusIcon width="16px" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
              <Tooltip title={locale.t("add_edit_lists.keyword_search_btn_tooltip")}>
                {locale.t("add_edit_lists.keyword_search_btn_text")}
              </Tooltip>
            </Button>

            <Button
              className='!ml-4'
              type="primary"
              href={Helper.profileSettingsUrl(this.props.commonData) + 'lists/new'}>
              <PlusIcon width="16px" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
              <Tooltip title={locale.t("add_edit_lists.list_by_name_tooltip")}>
                {locale.t("add_edit_lists.list_by_name_btn_text")}
              </Tooltip>
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <Table bordered columns={columns} dataSource={this.state.lists} />
        </div>
      </div>

      </DashboardLayout>
    );
  }

}

export default Index;
