function countMentions(str) {
    if (str == null) {
        return 0
    }
    let mentions = 0;
    for (const ch of str) {
        if (ch === "@") {
            ++mentions;
        }
    }
    return mentions;
}

function countHashtags(str) {
    if (str == null) {
        return 0
    }
    let hashtags = 0;
    for (const ch of str) {
        if (ch === "#") {
            ++hashtags;
        }
    }
    return hashtags;
}

const truncateString = (str, num) => {
    if (str == null) {
        return '';
    }
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
};

const removeNonAlphanumericExceptHash = (str) => {
    if (str == null) {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9# ]/g, '');
};


export { countMentions, countHashtags, truncateString, removeNonAlphanumericExceptHash  };