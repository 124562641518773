import React from 'react';
import Engage from '../Engage/Index';

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    locale.locale = props.commonData.locale
    // console.log('Community props', props.lists);
    super(props);
  }

  render() {
    return (
      <Engage
        commonData={this.props.commonData}
        pages={this.props.pages}
        prompts={this.props.prompts}
        selectedListValue={this.props.selectedListValue}
        hideListSelect={true}
        lists={this.props.lists}
        customLists={this.props.customLists}
        title={ locale.t("community.title") }
        subtitle={ locale.t("community.subtitle") }>
      </Engage>
    );
  }
}

export default Index;
