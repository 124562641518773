import React from 'react';
import SessionLayout from '../Layouts/SessionLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';

import { Select, Form, Input, Button } from 'antd';

import { LockClosedIcon } from '@heroicons/react/24/outline';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.onFinish = this.onFinish.bind(this);
    // locale.locale = props.session.locale
    locale.locale = 'en'

    this.state = {
      hasError: false,
      resetPasswordToken: this.props.resetPasswordToken
    };
    ApiHelper.prepHttpCall();
  }

  onLocaleToggle(event) {
    locale.locale = event
    console.log('event', event)

    // let params = {
    //   locale: event
    // };
    //
    // let url = '/api/v1/users/' + + '/locale';
    //
    // axios.put(url, params, ApiHelper.headers()).then(function (response) {
    //   console.log('response', response);
    // });

    let params = {
      locale: event
    };

    let url = '/locale'

    axios.put(url, params, ApiHelper.headers()).then(function (response) {
      console.log('response', response);
    });

    this.forceUpdate()
    // return
  }

  onFinish(values) {
    let params = {
      user: {
        reset_password_token: this.state.resetPasswordToken,
        email: values.email,
        password: values.password
      }
    };

    let self = this;
    let url = '/users/password';

    axios
      .put(url, params, ApiHelper.headers())

      .then(function (response) {
        self.onFormResponse(response);
      })

      .catch((res) => {
        this.setState({
          hasError: true
        });
      });
  }

  onFormResponse(response) {
    window.location.href = '/';
    return;
  }

  render() {
    return (
      <SessionLayout>
        <Form
          name="email"
          initialValues={{
            remember: true
          }}
          onFinish={this.onFinish}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password'
              },
              { min: 8, message: 'Username must be minimum 8 characters.' }
            ]}>
            <Input prefix={<LockClosedIcon width={20} />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                }
              })
            ]}>
            <Input
              prefix={<LockClosedIcon width={20} />}
              type="password"
              placeholder="Password confirmation"
            />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Reset password
            </Button>
            <br />
            <br />
            Or <a href="/users/sign_up">register now!</a>
          </Form.Item>

          <div style={{ width: '100%', textAlign: 'center' }}>
            <Select style={{ width: 120 }}
                defaultValue={{ value: 'en' }}
                onChange={this.onLocaleToggle}
                options={[{ value: 'en', label: <span>English</span> }, { value: 'fr', label: <span>French</span> }]} />
          </div>
        </Form>
      </SessionLayout>
    );
  }
}

export default ResetPassword;
