
import React, { useState, useEffect } from 'react';

const ProgressBar = ({
  weeklyCommentGoal,
  weeklyCommentGoalProgress,
  monthlyPostingGoal,
  monthlyPostingGoalProgress
}) => {
  const [prevWeeklyComment, setPrevWeeklyComment] = useState(weeklyCommentGoalProgress);
  const [animateWeeklyComment, setAnimateWeeklyComment] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    // Animate progress bar fill
    setProgressWidth((weeklyCommentGoalProgress / weeklyCommentGoal) * 100);

    // Trigger the number animation
    if (weeklyCommentGoalProgress !== prevWeeklyComment) {
      setAnimateWeeklyComment(true);
      setTimeout(() => {
        setAnimateWeeklyComment(false);
        setPrevWeeklyComment(weeklyCommentGoalProgress);
      }, 500); // Duration of the animation for numbers
    }
  }, [weeklyCommentGoalProgress, prevWeeklyComment, weeklyCommentGoal]);

  const surpassedWeekly = weeklyCommentGoalProgress >= weeklyCommentGoal;

  return (
    <div className="w-full my-2">
      <div className="flex justify-between items-center mb-2">
        <span className="text-gray-700 font-semibold">Weekly comment progress</span>
        <div className="flex items-center text-sm text-gray-500 font-semibold">
          <div
            className={`transition-transform duration-500 ease-in-out ${
              animateWeeklyComment ? 'transform -translate-y-full opacity-0' : 'opacity-100'
            }`}
          >
            {prevWeeklyComment}/{weeklyCommentGoal}
          </div>
          <div
            className={`transition-transform duration-500 ease-in-out ${
              animateWeeklyComment ? 'opacity-100' : 'transform translate-y-full opacity-0'
            }`}
          >
            {weeklyCommentGoalProgress}/{weeklyCommentGoal}
          </div>
        </div>
      </div>
      <div className="relative w-full h-3 bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`h-full rounded-full transition-all duration-700 ease-out ${
            surpassedWeekly ? 'bg-green-500' : 'bg-yellow-500'
          }`}
          style={{ width: `${progressWidth}%` }}
        ></div>
        <div
          className="absolute top-0 h-full w-1 bg-black rounded-full"
          style={{ left: `${progressWidth}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;

