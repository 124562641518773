import React from 'react';
import { Dropdown, Avatar, Menu } from 'antd';
import { UserIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import * as Helper from '../Helpers/Helpers';
import posthog from 'posthog-js';

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.commonData);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  handleChange(e) {
    let url = Helper.urlHelper(this.props.commonData.currentAccountUniqueToken, e, 'feed');
    window.location.href = url;
  }

  logout() {
    console.log('resetting posthog');
    posthog.reset();
    window.location.href = '/users/sign_out';
  }

  render() {
    let menu = (
      <Menu>
        <Menu.Item style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          {this.props.commonData.currentUserEmail}
        </Menu.Item>

        <Menu.Divider />

        {this.props.commonData.isAccountOwner && (
          <>
            <Menu.Item>
              <CreditCardIcon width={18} />
              <a href={Helper.accountSettingsUrl(this.props.commonData) + 'billing'}>Billing</a>
            </Menu.Item>

            <Menu.Divider />
          </>
        )}

        <Menu.Item>
          <a href="#" onClick= { () => this.logout() }>Log out</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <div style={{ float: 'right' }}>
          <Dropdown
            overlayClassName="logged-in-user-dropdown"
            overlay={menu}
            placement="bottomRight"
            trigger={['click']}
            arrow>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <Avatar size="large" src={this.props.commonData.currentUserAvatar} />
            </a>
          </Dropdown>
        </div>
      </>
    );
  }
}
export default TopBar;
