import React, { useEffect, useState } from 'react';
import { Row, Col, Card, message, Form, Input, Table, Button, Typography } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import SetupLayout from '../Layouts/SetupLayout';
import { UserIcon } from '@heroicons/react/24/outline';
import _ from 'underscore';

const { Text, Title } = Typography;
const axios = require('axios');

// Users
function Invite(props) {
  const [activeUsers, setActiveUsers] = useState(props.activeUsers);
  const [pendingUsers, setPendingUsers] = useState(props.pendingUsers || []);

  useEffect(() => {
    ApiHelper.prepHttpCall();
    update();
  }, []);

  const update = () => {
    let url = '/api/v1/accounts/' + props.commonData.currentAccountUniqueToken + '/users';

    axios.get(url, ApiHelper.headers()).then(function (response) {
      setActiveUsers(response.data.activeUsers);
      setPendingUsers(response.data.pendingUsers);
    });
  };

  const [form] = Form.useForm();

  const popMessage = (str) => {
    message.info(str);
  };

  const popError = (str) => {
    message.error(str);
  };

  // const cancelInvitationClick = (event, e) => {
  //   event.preventDefault();

  //   ApiHelper.prepHttpCall();

  //   let url = '/users/invitation/revoke/' + e.id;
  //   axios
  //     .get(url)
  //     .then(function (response) {
  //       let newArray = [...otherUsers];
  //       newArray = _.without(otherUsers, _.findWhere(otherUsers, { id: response.data.user.id }));

  //       setPendingInvitations(newArray);

  //       newArray = [...revokedUsers];
  //       newArray.push(response.data.user);

  //       setRevokedUsers(newArray);

  //       popMessage('User revoked');
  //     })
  //     .catch(function (error) {
  //       console.log('Error', error);
  //     });

  //   return;
  // };

  // const revokeInvitationLink = (event, e) => {
  //   event.preventDefault();

  //   axios
  //     .get('/users/invitation/revoke/' + e.id)
  //     .then(function (response) {
  //       let newArray = [...revokedUsers];
  //       newArray.push(response.data.user);

  //       setRevokedUsers(newArray);

  //       popMessage('User revoked');
  //     })
  //     .catch(function (error) {
  //       console.log('Error', error);
  //     });

  //   return;
  // };

  // const resendInvitationClick = (event, e, moveItem = true) => {
  //   event.preventDefault();

  //   let url = '/users/invitation/resend/' + e.id;

  //   axios
  //     .get(url)
  //     .then(function (response) {
  //       if (moveItem) {
  //         let newArray = _.without(
  //           revokedUsers,
  //           _.findWhere(revokedUsers, { id: response.data.user.id })
  //         );

  //         setRevokedUsers(newArray);

  //         newArray = [...otherUsers];
  //         newArray.push(response.data.user);

  //         setPendingInvitations(newArray);
  //       }
  //       popMessage('Invitation resent');
  //     })
  //     .catch(function (error) {
  //       console.log('Error', error);
  //     });

  //   return;
  // };

  const otherUsersTable = () => {
    let cols = [
      {
        title: 'Email',
        width: 250,
        dataIndex: '',
        key: '',
        render: (e) => e.email
      }
    ];
    return (
      <Table
        bordered
        columns={cols}
        pagination={false}
        dataSource={pendingUsers}
        rowKey={(record) => record.id}
      />
    );
  };

  const handleSubmit = (event) => {
    let user = {
      email: event.email,
    };

    let params = { user: user };

    let url = '/api/v1/users/invitation';

    axios
      .post(url, params, ApiHelper.headers())
      .then(function (response) {
        let newArray = [...pendingUsers];
        newArray.push(response.data.user);

        setPendingUsers(newArray);

        form.resetFields();

        popMessage('User invited');
      })
      .catch(function (error) {
        popError(error);
        popError(error.response.data.message);
        return;
      });
  };

  return (
    <SetupLayout title="" commonData={props.commonData}>
      <Card className="top-card">
        <Title level={4}>Super-charge your growth</Title>

        <Row style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Text>Invite each member of your team to Aware.</Text>
          </Col>

          <Col span={24} style={{ margin: '16px auto auto', maxWidth: '400px' }}>
            <Form layout="vertical" form={form} name="invite-user" onFinish={handleSubmit}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: "That doesn't look like a valid email"
                  },
                  {
                    required: true,
                    message: 'Please input your email'
                  }
                ]}>
                <Input size="large" prefix={<UserIcon width={18} />} placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" size="large" style={{ float: 'right' }} htmlType="submit">
                  Send invitation
                </Button>
              </Form.Item>
            </Form>

            <a href={'/'}>I'm all done, take me to my dashboard.</a>

            <div style={{ marginTop: '32px' }} className={`${(pendingUsers.length > 0) ? '' : 'hidden'}`}>
              <Title level={4} className="no-border">
                Pending Invitations
              </Title>

              {otherUsersTable()}
            </div>

          </Col>
        </Row>
      </Card>

    </SetupLayout>
  );
}

export default Invite;
