import React, { useState } from 'react';
import { Typography } from 'antd';
import SessionLayout from '../Layouts/SessionLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { Alert, Form, Input, Button, Checkbox } from 'antd';
import { LockClosedIcon } from '@heroicons/react/24/outline';

const { Paragraph, Title } = Typography;

const axios = require('axios');

const CardForm = (props) => {
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (event) => {
    ApiHelper.prepHttpCall();

    let user = {
      invitation_token: props.invitationToken,
      password: event.password,
      password_confirmation: event.password_confirmation
    };

    let params = { user: user };

    let url = '/api/v1/users/invitation';

    axios
      .put(url, params, ApiHelper.headers())
      .then(function (response) {
        if (response.data != undefined) {
          window.location.href = '/';
        } else {
          console.log('WTF response.data');
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log('Error response', error.response);
          console.log('Error response', error.response.data.error_message);
          setErrorMessage(error.response.data.error_message);
        }
      });
  };

  return (
    <SessionLayout>
      <Title level={4}>Welcome!</Title>
      <Paragraph>Create a password to accept this invitation.</Paragraph>

      <Alert
        style={{ marginBottom: '4px' }}
        message={errorMessage}
        type="error"
        className={`${errorMessage ? '' : 'hidden'}`}
      />

      <Form
        name="user"
        initialValues={{
          remember: true
        }}
        onFinish={handleSubmit}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password'
            },
            { min: 8, message: 'Username must be minimum 8 characters.' }
          ]}>
          <Input prefix={<LockClosedIcon width={20} />} type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            })
          ]}>
          <Input
            prefix={<LockClosedIcon width={20} />}
            type="password"
            placeholder="Password confirmation"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>

        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox style={{ float: 'right' }}>Remember me</Checkbox>
          </Form.Item>

          <a href="/users/password/new">Forgot password</a>
        </Form.Item>
      </Form>
    </SessionLayout>
  );
};

const Signup = (props) => {
  return (
    <CardForm
      invitationToken={props.invitationToken}
      currentAccountUniqueToken={props.currentAccountUniqueToken}
    />
  );
};

export default Signup;
