
import React, { useEffect, useContext } from "react";
import he from 'he';
import DrawerComponent from "./DrawerComponent";
import { ClockCircleOutlined } from '@ant-design/icons';
import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
import { EditorThemeContext } from "../../Posts/EditorContext";
import dayjs from "dayjs";

const locale = new I18n(translations);

const DateDisplay = (props) => {
  locale.locale = props.commonData.locale;

  const userTimeZone = props.commonData?.timezone ?? "UTC";
  // console.log('date dis props', props);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return he.decode(doc.body.innerHTML
      .replace(/<\/p>/gi, '\n\n')  // Replace closing </p> tags with double newlines
      .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> tags with single newlines
      .replace(/<[^>]+>/g, '')  // Remove all remaining HTML tags
      .trim());  // Remove leading and trailing whitespace
  };

  const PostContent = ({ content }) => {
    console.log('Post Content', content);
    const plainTextContent = stripHtmlTags(content);
    return <span>{plainTextContent}</span>;
  };

  const [text, setText] = React.useState("");
  const {
    openPostDrawer,
    isLoadingSlots,
    slotContent,
    postsData,
    isPostsDataLoading,
    setPostsData,
    queueSlotsData,
    isSlotDrawerOpen,
    generateDates,
    fetchTimeSlotsHandler,
    handleDrawerClose,
  } = useContext(EditorThemeContext);

  const showDrawer = (time, originalDate, index) => {
    openPostDrawer(originalDate, time, index);
    setText('cool');
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    setPostsData(props.postData);
  }, []);

  useEffect(() => {
    if (!isPostsDataLoading) {
      fetchTimeSlotsHandler().then(qSlots => {
        generateDates(qSlots);
      });
    }
  }, [postsData, queueSlotsData?.length, isPostsDataLoading]);

return (
  <div>
    <ul className="space-y-3 w-full p-0">
      {isLoadingSlots ? <p>Loading... just a moment!</p> :
        Object.keys(slotContent).length > 0 ? Object.keys(slotContent).map((dateKey, index) => {
          const dateContent = slotContent[dateKey];
        
          return (
            <li key={index} className="w-full" style={{ listStyle: "none" }}>
              <div className="text-md font-semibold tracking-wide text-gray-800 mt-4">
                {dayjs(dateKey).format("dddd | DD MMMM YYYY")}
              </div>
              {dateContent.map((entry, sIndex) => {
                const slot = entry.slot;

                const openDrawerForDate = () => {
                  const isScheduledPost = slot.linked_in_user_id;
                  const slotTime = isScheduledPost ? dayjs(slot.scheduled_at).tz(userTimeZone).format('hh:mm a') : slot.timeFormatted;
                  showDrawer(slotTime, dateKey, sIndex)
								  console.log('slotClicked', slot);
                }

                return (
                  <div
                    key={sIndex}
                    style={{ width: "70%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', backgroundColor: slot.linked_in_user_id ? "#59c5bd" : "#F3F4F6" }}
                    className={`px-4 hover:bg-gray-300 py-2 my-2 mt-4 text-base font-semibold text-zinc-400 rounded-md cursor-pointer `}
                    onClick={openDrawerForDate}
                  >
                    <div
                      style={{
                        paddingLeft: "5px",
                        paddingRight: "15px",
                        alignSelf: "center",
                        display: "flex",
                        color: slot.linked_in_user_id ? "white" : "lightgrey",
                        alignItems: "center",
                      }}
                    >
                      <ClockCircleOutlined width="25px" />
                    </div>
                    <div style={{
                      minWidth: "65px",
                      paddingRight: "6px",
                      fontSize: "14px",
                      color: slot.linked_in_user_id ? "white" : "grey"
                    }}
                    >
                      {slot.timeFormatted}
                    </div>
                    <div style={{
                      paddingLeft: "5px", whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      {slot.linked_in_user_id ? (
                        <span className="ml-2" style={{ color: "white" }}>
                          <PostContent content={slot.post_text || ''} />
                        </span>
                      ) : (
                        <span className="ml-2" style={{ color: "lightgrey", fontSize: "12px", fontStyle: "italic" }}>
                          Click here to place your post
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </li>
          );
        }) :
        <>
          Setup some time-slots from the above to check the calendar.
        </>
      }
    </ul>

    <DrawerComponent
      open={isSlotDrawerOpen}
      text={text}
      handleClose={handleDrawerClose}
      handleTextChange={handleTextChange}
      handleAddToQueue={() => { }}
      postData={props.postData || []}
      commonData={props.commonData}
    />
  </div>
);

}


export default DateDisplay;

