import React from 'react';

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
    let classes = ''
    if(props.size == 'small') {
    } else {
      classes = 'pt-6 pb-4'
    }
    this.state = {
      classes: classes
    }
  }

  render() {
    return (
      <div className={this.state.classes}>
        <div className="flex space-between" style={{display: 'flex', justifyContent: 'space-between'}}>
          <div> 
            <h3 className="font-medium leading-6 text-gray-700 p-0 m-0">{this.props.title}</h3>
            <p className="mt-1 text-sm text-gray-500">{this.props.subtitle}</p>
          </div>
          {this.props.right && (
            <div>
              {this.props.right}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SectionHeader;
