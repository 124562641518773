import React, { Component, useState } from "react";
import { Button, Modal, Tabs } from "antd";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import DateDisplay from "./Queue/components/DateDisplay";
import TimeTableComponent from "./Queue/components/TimeTable";

const EditQueueModal = ({ timeList, setTimeList }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button
                type="primary"
                className="flex items-center space-x-1 "
                onClick={showModal}
                icon={<PencilSquareIcon width="25px" />}
                size="large"
            >
                Edit Queue
            </Button>
            <Modal
                title={<p className="text-2xl font-bold">Edit your post schedule</p>}
                className="text-xl font-bold"
                width={900}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <TimeTableComponent timeList={timeList} setTimeList={setTimeList} />
            </Modal>
        </>
    );
};
const items = [
    {
        key: "1",
        label: "Scheduled Posts",
        children: <DateDisplay />,
    },
    {
        key: "2",
        label: "Published Posts",
        children: <EditQueueModal />,
    },
    {
        key: "3",
        label: "Drafts",
        children: "Draft Section"
    }
];

class MyQueue extends Component {
    render() {
        return (
            <div className="h-screen bg-blue-300" style={{ padding: "5rem" }}>
                <div className="text-4xl font-semibold text-gray-700">My Queue</div>
                <div className="flex justify-evenly items-start w-full">
                    <Tabs
                        className="w-full text-2xl"
                        tabBarStyle={{ fontSize: 20 }}
                        defaultActiveKey="1"
                        size="large"
                        items={items}
                    />
                    <div className="flex space-x-3 items-center">
                        <EditQueueModal />
                    </div>
                </div>
            </div>
        );
    }
}

export default MyQueue;
