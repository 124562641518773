import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import TriggersTable from './TriggersTable';
import { message, Form, Select, Card, Row, Col, Typography } from 'antd';
import Spinner from '../Spinner';

const { Title, Paragraph } = Typography;
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale

    this.state = {
      isLoaded: false,
      crm: this.props.crm,
      triggers: [],
      notificationSelections: [],
      crmUsers: [],
      selectedCrmUserId: null
    };

    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  popMessage(str) {
    message.info(str);
  };

  loadComponent() {
    let url =
      '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/notifications';

    let data = {
      params: { crm: this.state.crm, li_user_id: this.props.commonData.linkedInUserId },
      headers: ApiHelper.headers()
    }
    let self = this;
    axios.get(url, data, ApiHelper.headers()).then(function (response) {
      console.log('resp', response.data.notificationSelections);
      console.log('selected crm', response.data.selectedCrmUserId);
      self.setState({
        isLoaded: true,
        triggers: response.data.triggers,
        notificationSelections: response.data.notificationSelections,
        crmUsers: response.data.users,
        selectedCrmUserId: response.data.selectedCrmUserId
      });
    });
  }

  updateMappedUser(e) {
    // console.log('e - - - ', e)
    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken + "/li/" + 
      this.props.commonData.linkedInUserId +
      '/update_crm_user';

    let data = {
      li_user_id: this.props.commonData.linkedInUserId,
      crm: this.state.crm,
      crm_user_id: e,
    };

    let self = this;
    axios.put(url, data, ApiHelper.headers()).then(function (response) {
      self.popMessage( locale.t("sfdc_notifications.crm_user_updated_msg"));
    });
  }

  renderContent() {
    return (
      <>
        <Title level={4}>
          { locale.t("sfdc_notifications.notifications_do_not_apply_to_all_profiles_msg") }
        </Title>

        <Row>
          <Col xs={24} lg={12}>
            <Paragraph>{ locale.t("sfdc_notifications.choose_user_in_crm_msg") }</Paragraph>
          </Col>

          <Col xs={24} lg={12}>
            <Form layout="vertical">
              <Form.Item valuePropName='value' label="">
                <Select
                  onChange={(e) => this.updateMappedUser(e)}
                  defaultValue={this.state.selectedCrmUserId}
                  style={{ width: 249 }}>
                  {this.state.crmUsers.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col xs={24} lg={12}>
            <Paragraph>
              { locale.t("sfdc_notifications.choose_activities_to_sync_msg") }
            </Paragraph>
          </Col>

          <Col xs={24} lg={12}>
            <TriggersTable
              commonData={this.props.commonData}
              triggers={this.state.triggers}
              notifiableType="LinkedInUser"
              colNames={[ locale.t("sfdc_notifications.send_to_sfdc_col_header") ]}
              selections={this.state.notificationSelections}
            />
          </Col>
        </Row>
      </>
    )
  }

  render() {
    return (
      <Card className='mb-4'>

        {!this.state.isLoaded && <Spinner />}
        {this.state.isLoaded && this.renderContent()}

      </Card>
    );
  }
}

export default Notifications;
