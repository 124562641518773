
import React from 'react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

class PagesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 0, // Default value
    };
  }

  onListChange(evt) {
    const selectedValue = evt === 0 ? '' : evt;
    this.setState({ selectedPage: selectedValue });
    this.props.onPageChange(selectedValue);
  }

  render() {
    const { commonData, pages } = this.props;
    const { selectedPage } = this.state;
    const selectedPageObj = selectedPage === 0
      ? { logo: commonData.currentLinkedInUserAvatar, label: commonData.currentLinkedInUserName }
      : pages.find(page => page.key === selectedPage);

    return (
      <Popover className="relative">
        <Popover.Button className="flex h-[42px] items-center border-none rounded-full bg-gray-100 pl-2 pr-4 text-gray-700 text-sm">
          <img src={selectedPageObj.logo} className="w-8 rounded-full ml-1 mr-2" style={{ verticalAlign: 'middle' }} />
          <span>{selectedPageObj.label}</span>
          <ChevronDownIcon className="w-4 h-4 text-gray-400" />
        </Popover.Button>
        <Popover.Panel className="absolute z-10 mt-2 bg-white rounded-md shadow-lg min-w-[300px]">
          <div className="py-2">
            <div
              key={0}
              className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
              onClick={() => this.onListChange(0)}
            >
              <img src={commonData.currentLinkedInUserAvatar} className="w-8 rounded-full ml-1 mr-2" style={{ verticalAlign: 'middle' }} />
              <span>{commonData.currentLinkedInUserName}</span>
            </div>

            {pages.map((x) => (
              <div
                key={x.key}
                className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={() => this.onListChange(x.key)}
              >
                <img src={x.logo} className="w-8 rounded-full ml-1 mr-2" style={{ verticalAlign: 'middle' }} />
                <span>{x.label}</span>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Popover>
    );
  }
}

export default PagesDropdown;

