import React from 'react';
import PeopleOrAccountSearch from './PeopleOrAccountSearch';
import * as Helper from './Helpers/Helpers';
import Spinner from './Spinner';
import * as ApiHelper from './Helpers/ApiHelpers';
import { Button, Table } from 'antd';
import _ from 'underscore';

const axios = require('axios');

class SearchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      records: this.props.records,
      tableData: this.setTableData(this.props.cols)
    };

    this.onAutoCompleteSelect = this.onAutoCompleteSelect.bind(this);
    ApiHelper.prepHttpCall();
    this.callback = this.callback.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.records !== this.props.records && this.props.records != undefined) {
      this.setState({ records: this.props.records });
    }
  }

  onAutoCompleteSelect(value, option) {
    let self = this;

    this.setState({
      loading: true
    });

    let profileSettingsUrl = Helper.urlHelper(
      this.props.commonData.currentAccountUniqueToken,
      this.props.commonData.linkedInUserId,
      ''
    );
    let url = profileSettingsUrl + 'lists/' + this.props.listId + '/people';
    axios
      .post(
        url,
        {
          entity_urn: value,
          li_user_id: self.props.linkedInUserId
        },
        ApiHelper.headers()
      )
      .then(function (response) {
        console.log('response', response);
        Helper.startPolling(self.props.commonData, response.data.jobId, value, self.callback);
      });
  }

  callback(entityUrnId) {
    console.log('callback', entityUrnId);

    let url = '/api/v1/people/' + entityUrnId;

    let self = this;
    axios.get(url, ApiHelper.headers()).then(function (res) {
      let hasIt = self.state.records.some(item => res.data.person.id === item.id);
      if(hasIt) {
        self.setState({
          loading: false
        });
      } else {
        let newArr = self.state.records.concat(res.data.person);
        self.setState({
          records: newArr,
          loading: false
        });
      }
    });
  }

  urlHelper() {
    let profileSettingsUrl = Helper.urlHelper(
      this.props.commonData.currentAccountUniqueToken,
      this.props.commonData.linkedInUserId,
      ''
    );

    let url = profileSettingsUrl + 'lists/' + this.props.listId + '/people/';
    return url;
    // return '/api/v1/li/' + this.props.commonData.linkedInUserId + '/' + rel + '/';
  }

  removeItem(relationshipId) {
    console.log(relationshipId);
    let newArr = _.reject(this.state.records, function (e) {
      return e.id == relationshipId;
    });

    this.setState({
      records: newArr
    });

    let url = this.urlHelper() + relationshipId;

    console.log('url', url);
    axios.delete(url, ApiHelper.headers()).then(function (response) {});
  }

  setTableData(data) {
    // let k = this.props.cols;
    let k = data;
    console.log('push', k);
    k.push({
      title: '',
      render: (e) => <Button onClick={() => this.removeItem(e.id)}>Remove</Button>
    });
    // this.setState({ tableData: k });
    return k;
  }

  render() {
    return (
      <>
        {this.props.pageHeader}

        {!this.state.loading && (
          <PeopleOrAccountSearch
            type={this.props.type}
            relationship={this.props.relationship}
            linkedInUserId={this.props.linkedInUserId}
            onSearch={this.handleSearch}
            onAutoCompleteSelectHandler={this.onAutoCompleteSelect}
            placeholder={this.props.placeholder}
          />
        )}

        {this.state.loading && <Spinner title="Fetching profile information" size="small" />}

        <Table
          bordered
          size="small"
          columns={this.state.tableData}
          dataSource={this.state.records}
          pagination={{ defaultPageSize: 50 }}
          rowKey={(record) => record.id}
        />

        {this.props.children}
      </>
    );
  }
}

export default SearchList;
