import React from 'react';
import { Switch, Table } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { ExclamationCircleIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';

const axios = require('axios');

class Profiles extends React.Component {
  constructor(props) {
    super(props);
    // console.log('p-', props);
    this.state = {};
    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url = '/api/v1/admin/profiles';
    let self = this;

    axios.get(url, ApiHelper.headers()).then(function (response) {
      // console.log('ap', response);
      // console.log('ap', response.data.activeProfiles);
      self.setState({
        allActiveProfiles: response.data.activeProfiles,
        activeProfiles: response.data.activeProfiles
      });
    });
  }

  liUser(e) {
    let url = '/api/v1/admin/users/' + e.user.id + '/become';

    axios.get(url, ApiHelper.headers()).then(function (response) {
      window.location.href = '/?admin=true';
    });
  }

  emailLink(email) {
    return <a href={'mailto:' + email}>{email}</a>;
  }

  toggleRunnable(checked, e, profileId) {
    let url = '/api/v1/admin/li/' + profileId;
    let data = {
      runnable: checked
    };
    let self = this;
    axios.put(url, data, ApiHelper.headers()).then(function (response) {
      self.loadComponent();
    });
  }

  render() {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    const cols = [
      {
        dataIndex: '',
        key: '',
        render: (e) => (
          <a href="#" onClick={() => this.liUser(e)}>
            Login as...
          </a>
        )
      },
      {
        title: 'ID',
        dataIndex: 'id',
        className: 'subtle-table-col',
        key: 'id'
      },
      {
        title: 'Created on',
        dataIndex: 'created_at',
        className: 'subtle-table-col',
        key: 'created_at',
        render: (e) => new Date(e).toLocaleDateString('en-US', options)
      },
      {
        title: 'Account',
        dataIndex: '',
        key: '',
        render: (e) => e.user.account.id
      },
      {
        title: 'User email',
        dataIndex: '',
        key: '',
        render: (e) => this.emailLink(e.user.email)
      },
      {
        title: 'Stripe customer id',
        dataIndex: '',
        key: '',
        render: (e) => e.user.account.stripe_customer_id
      },
      {
        title: 'Profile',
        dataIndex: '',
        render: (e) => (
          <a href={'https://linkedin.com/in/' + e.public_identifier}>{e.public_identifier}</a>
        )
      },
      {
        title: 'Status',
        dataIndex: '',
        key: '',
        render: (e) => e.error || <HandThumbUpIcon width={24} color="#ccc" />
      },
      {
        title: 'Running',
        dataIndex: '',
        key: '',
        render: (e) => (
          <Switch
            checked={e.runnable}
            onChange={(checked, event) => this.toggleRunnable(checked, event, e.id)}
          />
        )
      }
    ];

    const onFilterChange = (value) => {
      if (!value) {
        this.setState({ activeProfiles: this.state.allActiveProfiles });
        return;
      }

      const filteredProfiles = this.state.activeProfiles.filter((profile) => {
        return profile.user.email.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({ activeProfiles: filteredProfiles });
    }

    return (
      <div className='w-full'>
        <div className="flex items-center space-x-2 mb-4">
          <label className="text-gray-700">Filter by email:</label>
          <input 
            type="text" 
            className="border border-gray-300 rounded-md p-2"
            placeholder="Enter email to find"
            onChange={(e) => onFilterChange(e.target.value)} 
          />
        </div>

        <Table
          columns={cols}
          size="small"
          rowKey="id"
          dataSource={this.state.activeProfiles}
          pagination={{ showSizeChanger: false, pageSize: 250 }}
        />
      </div>
    );
  }
}

export default Profiles;
