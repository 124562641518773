import React from "react";
import { Tabs } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import DateDisplay from "./components/DateDisplay";
import EditQueueModal from "./components/EditQueue";
import DashboardLayout from "../Layouts/DashboardLayout";
import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json"
import { EditorThemeProvider } from "../Posts/EditorContext";
import PostEditableTable from "../Posts/PostEditableTable";
import ProfileToggle from "../ProfileToggle";
const locale = new I18n(translations);

import NewPostButton from "./components/NewPostButton";

dayjs.extend(utc);
dayjs.extend(timezone);

const MenuItems = (commonData, postData) => [
  {
    key: "1",
    label: "Scheduled Slots",
    children: <DateDisplay commonData={commonData} postData={postData} />,
  },
  {
    key: "draft",
    label: "Drafts",
    children: <div>
      <PostEditableTable items={postData} commonData={commonData} category={"draft"} />
    </div>,
  },
  {
    key: 'published',
    label: locale.t("post_scheduling.tab_published_label"),
    children: (
      <div>
        <PostEditableTable items={postData} commonData={commonData} category={"published"} />
      </div>
    ),
  },
];

const Queues = (props) => {
  // console.log('QueuesProps', props)
  locale.locale = props.commonData.locale
  const timeZone = props.commonData.timezone;

  return (
    <DashboardLayout
      commonData={props.commonData}
      narrow={false}
      title={props.title}
      subtitle={props.subtitle}
    >
      <EditorThemeProvider commonData={props.commonData}>
        <div className="flex flex-1 flex-col space-y-5  min-w-[800px]">

          { props.commonData.currentUserRole == 'collaborator' && (
            <div>
            <ProfileToggle
              commonData={props.commonData}
              profiles={ props.commonData.profiles }
              selectedProfile={ props.commonData.actAsLinkedInUserId }
              style={{ height: 49 }}/>
            </div>
          )}

          <div className="w-3/4 flex items-center justify-between">
            <div className="flex justify-center items-center gap-1">
              <EditQueueModal commonData={ props.commonData }/>
              <NewPostButton />
            </div>
            <h6 className="text-sm font-medium">All times are in <strong>{timeZone}</strong></h6>
            {/* <p className="text-xs">Current time: {dayjs().tz(timeZone).format('MMMM D, YYYY h:mm A')}</p> */}
          </div>
          {

          }
          <Tabs
            defaultActiveKey="1"
            size="middle"
            items={MenuItems(props.commonData, props.postData)}
          />
        </div>
      </EditorThemeProvider>
    </DashboardLayout>
  );
}

export default Queues;
