import React, { useState } from 'react';
import { Button, Table, Tooltip, Popconfirm, notification } from 'antd';
import TimeAgo from 'react-timeago'
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import axios from 'axios';
import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
import { EditorThemeContext } from './EditorContext';

const locale = new I18n(translations);

const PostEditableTable = ({ items, commonData, category }) => {
    const { postsData,setPostsData, setIsSlotDrawerOpen, setIsSchedulingPostNow, setSelectedPost, showToastNotification, setEditorText, setEditorHtml, contextHolder:toastContext } = React.useContext(EditorThemeContext);
    const [showDeletePopover, setShowDeletePopover] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    const showNotification = (message, placement = "topRight", title = "Something went wrong!") => {
        api.info({
            message: title,
            description: message,
            duration: 3000,
            placement,
        });
    };

    const handleDeletePopover = async post_id => {
        ApiHelper.prepHttpCall();

        const url = `/api/v1/posts/delete`;
        const payload = {
            post_id: post_id
        };
        await axios.post(url, payload, ApiHelper.headers())
            .then((res) => {
                // window.location.href = Helper.profileSettingsUrl(commonData) + 'queue/';
                setPostsData(prevPosts => prevPosts.filter(p => p.id !== post_id) )
                setSelectedPost({})
                showToastNotification("Post deleted successfully!", 'success', 3)
            })
            .catch((err) => {
                showNotification(err?.response?.data, "topRight")
            }).
            finally(() => setShowDeletePopover(false))
    };

    const stripHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, " ");
    }

    const renderPostText = (text) => {
        if (text.length > 20) {
            return <span>{stripHtmlTags(`${text.substring(0, 20)}...`)}</span>;
        }
        return <span>{stripHtmlTags(text)}</span>;
    };

    const renderTimeAgo = (time) => {
        return (
            <TimeAgo
                date={time}
                formatter={(value, unit, suffix) => {
                    const utcTime = new Date(time).toUTCString();
                    if (value < 1 && unit === "second") {
                        return locale.t("post_scheduling.just_now")
                    } else {
                        return (
                            <Tooltip title={utcTime.toLocaleString()}>
                                <span>{value} {unit}{value > 1 ? "s" : ""} {suffix}</span>
                            </Tooltip>
                        );
                    }
                }}
            />
        );
    };

    const handleEditButton = (post) => {
        setIsSlotDrawerOpen(true);
        setIsSchedulingPostNow(false);
        setSelectedPost(post)
        setEditorText(post.post_text)
        setEditorHtml(post.post_html)
    }

    const renderActionButton = (record) => {
        return (
            <>
                {/* <Button href={`${Helper.profileSettingsUrl(commonData)}new_post/${record.id}`}>Edit</Button> */}
                <Button onClick={() => handleEditButton(record)} className='mr-2'>Edit</Button>
                <Popconfirm
                    description={locale.t("post_scheduling.confirm_delete_msg")}
                    open={showDeletePopover === record.id}
                    onConfirm={async () => (await handleDeletePopover(record.id))}
                    onCancel={() => setShowDeletePopover(false)}
                >
                    <Button onClick={() => setShowDeletePopover(record.id)} danger>Delete</Button>
                </Popconfirm>
            </>
        );
    };

    const viewPostLink = (data) => {
        if (data.post_url != undefined) {
            return (
                <a href={data.post_url} target="_blank">
                    View post
                </a>
            );
        } else {
            return '';
        }
    }

    const viewOriginalPostLink = (data) => {
        if (data.linkedin_urn != undefined) {
            return (
                <a href={data.linkedin_urn} target="_blank">
                    Original post
                </a>
            );
        } else {
            return '';
        }
    }

    const columns = [
        {
            title: locale.t("post_scheduling.post_text_col_header"),
            dataIndex: 'post_text',
            key: 'post_text',
            render: renderPostText,
        },
        {
            title: locale.t("post_scheduling.last_update_col_header"),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: renderTimeAgo,
        },
        {
            title: locale.t("post_scheduling.view_post_col_header"),
            dataIndex: 'post_url',
            key: 'post_url',
            render: (_, data) => { return viewPostLink(data) },
        },
        {
            title: 'Original Post',
            dataIndex: 'linkedin_urn',
            key: 'linkedin_urn',
            render: (_, data) => { return viewOriginalPostLink(data) },
        },
        {
            title: ' ',
            key: 'action',
            render: (_, record) => {
                if (category == "published") return (<></>)
                return renderActionButton(record);
            },
        },
    ];

    if (category == "scheduled") {
        columns.splice(1, 0, {
            title: locale.t("post_scheduling.scheduled_at_col_header"),
            dataIndex: 'scheduled_at',
            key: 'scheduled_at',
            render: renderTimeAgo,
        })
    }

    return (
        <>
            {contextHolder}
            {toastContext}
            <Table
                bordered
                dataSource={postsData.filter(p => p.post_status == category)}
                columns={columns}
                pagination={false}
            />
        </>
    );
};

export default PostEditableTable;
