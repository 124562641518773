import React, { useState, useEffect } from 'react';
import { Tooltip, Table, Select, Space } from 'antd';
import _ from 'underscore';
import * as ApiHelper from './Helpers/ApiHelpers';
import { countMentions, countHashtags, truncateString } from "./Helpers/StringHelper";
import * as Helper from './Helpers/Helpers';
import { EyeIcon, ShareIcon, ChatBubbleLeftRightIcon, ChatBubbleLeftIcon, AtSymbolIcon, HashtagIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

function Posts(props) {
  const [posts, setPosts] = useState(props.posts);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState();

  useEffect(() => {
    ApiHelper.prepHttpCall();
    fetchData();
    setPosts(props.posts)
    locale.locale = props.commonData.locale
  }, [props] )

  const fetchData = () => {
    setLoading(true);
    setTags(setOptions(props.tags))
  };

  async function addTag(tagNameOrId, postId, linkedinid, isNewTag) {
    try {

      ApiHelper.prepHttpCall();

      let data = {
        post_id: postId,
        linkedin_user_id: linkedinid
      }
      if(isNewTag) {
        data['tag_name'] = tagNameOrId
      } else {
        data['tag_id'] = tagNameOrId
      }

      const response = await axios.post('/api/v1/tags', data,
          {
            headers: {
              accept: 'application/json',
              Authorization: 'Bearer a29868cd-2767-48ea-96c8-4f0238db57ad'
            }
          });
      // console.log('response.data', response.data)
      setTags(setOptions(response.data))
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to add tag');
    }
  }

  async function handleTagChange(tagId, option, recordId) {

    // TagId is the tag name if it's a new tag
		// console.log('tagId', tagId)
		// console.log('option', option)
		// console.log('recordId', recordId)
		//
  //   console.log('postId', recordId)
  //   console.log('userid', props.userid)

    let tagName = tagId

    // If tag is new, add it to the database and the post, and return all tags
    let allTags = null
    let isNewTag = true
    if(_.isEmpty(option)) {
      allTags = await addTag(tagName, recordId, props.userid, true);
    } else {
      // If tag exists, add it to the post, and return all tags
      isNewTag = false
      allTags = await addTag(option.value, recordId, props.userid, false);
    }

    // Add tag to the post
    const newDataSource = posts.map(item => {
      if (item.id === recordId) {
        if(isNewTag) {
          let newTag = allTags.filter((tag) => tag.tag_name === tagName)
          return { ...item, tags: item.tags.concat(newTag) };
        } else {
          let newTag = allTags.filter((tag) => tag.id === tagId)
          return { ...item, tags: item.tags.concat(newTag) };
        }
      }
      return item;
    });
    setPosts(newDataSource);
  };

  async function deleteTag(activityId, tagId) {
    const url = `/api/v1/tags/${activityId}?tagId=${tagId}`

    axios.delete(url, ApiHelper.headers()).then(function (response) {

      // console.log("activityId", activityId)
      // console.log("tagId", tagId)
      // console.log("response", response)
      setTags(setOptions(response.data))

      const newDataSource = posts.map(item => {
        if (item.id === activityId) {
          let deleteTag = item.tags.filter((tag) => tag.id !== tagId)
          return { ...item, tags: deleteTag };
        }
        return item;
      });

      setPosts(newDataSource);
      return response.data;
    });

  }

  function setOptions(tags) {

    const options = [];
    _.map(tags, function(i) {
      options.push({
        value: i.id,
        label: i.tag_name
      });
    })
    return options
  }

  async function handleDeleteTag(activityId, tagId) {
    await deleteTag(activityId, tagId);
  }

  const post_columns = [
    {
      title: locale.t("performance.post_date_header"),
      dataIndex: 'event_date',
      key: 'event_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => Date.parse(a.event_date) - Date.parse(b.event_date),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Content',
      width: 200,
      dataIndex: 'blob',
      key: 'blob',
      render: (blob) => {
        return (
          <a href={blob['permalink']} target="_blank">
          {truncateString(blob['post_content'], 25)}
          </a>
        )
      }
    },
    {
      title: <Tooltip title="Likes"><HandThumbUpIcon width={18}/></Tooltip>,
      // title: 'Likes',
      dataIndex: 'blob',
      key: 'blob',
      // render: (blob) => blob['num_likes'].toLocaleString(),
      render: (blob) => (blob['num_likes'] !== undefined ? blob['num_likes'].toLocaleString() : 'N/A'),
      sorter: (a, b) => a.blob['num_likes'] - b.blob['num_likes'],
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      // title: 'Comments',
      title: <Tooltip title="Comments"><ChatBubbleLeftIcon width={18}/></Tooltip>,
      dataIndex: 'blob',
      key: 'blob',
      // render: (blob) => blob['num_comments'].toLocaleString(),
      render: (blob) => (blob['num_comments'] !== undefined ? blob['num_comments'].toLocaleString() : 'N/A'),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.blob['num_comments'] - b.blob['num_comments'],
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Views',
      // title: <Tooltip title="Views"><EyeIcon width={18}/></Tooltip>,
      dataIndex: 'blob',
      key: 'blob',
      hidden: props.hideViews || false,
      render: (blob) => {
        return blob['num_views'] ? blob['num_views'].toLocaleString() : '0';
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.blob['num_views'] - b.blob['num_views'],
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Shares',
      // title: <Tooltip title="Shares"><ShareIcon width={18}/></Tooltip>,
      dataIndex: 'blob',
      key: 'blob',
      render: (blob) => {
        return blob['num_shares'];
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.blob['num_shares'] - b.blob['num_shares'],
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      // title: 'Hashtags',
      title: <Tooltip title="Hashtags"><HashtagIcon width={18}/></Tooltip>,
      dataIndex: 'blob',
      key: 'blob',
      render: (blob) => {
        return countHashtags(blob['post_content']);
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => countHashtags(a.blob['post_content']) - countHashtags(b.blob['post_content']),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      // title: 'Mentions',
      title: <Tooltip title="Mentions"><AtSymbolIcon width={18}/></Tooltip>,
      dataIndex: 'blob',
      key: 'blob',
      render: (blob) => {
        return countMentions(blob['post_content']);
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => countMentions(a.blob['post_content']) - countMentions(b.blob['post_content']),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Engmnt rate',
      // title: <Tooltip title="Engagement rate"><ChatBubbleLeftRightIcon width={18}/></Tooltip>,
      dataIndex: 'engagement_rate',
      key: 'engagement_rate',
      render: (k) => {
        return k + '%';
      },
      sorter: (a, b) => a.engagement_rate - b.engagement_rate,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: locale.t("performance.post_tags_header"),
      dataIndex: 'new_section',
      key: 'new_section',
      render: (text, record) => (
        <Select
          mode="tags"
          style={{
            width: '100%',
            minWidth: '50px',
            maxWidth: '500px',
          }}
          value={_.map(record.tags, function(tag) { return tag.id })}
          options={tags}
          className='w-full'
          placeholder="Tags Mode"
          onDeselect={(value) => {
            handleDeleteTag(record.id, value)
          }}
          // onChange={(tagId, tag) => handleTagChange(tagId, tag, record.id)}
          onSelect={(tagId, tag) => handleTagChange(tagId, tag, record.id)}
          // onSelect={(value, other) => onTagChange(value, other, record)}
        />
      ),
    }
  ].filter((item) => !item.hidden);

  function onFilterChange(value) {
    const filteredData = props.posts.filter((post) => {
      return post.blob.post_content && post.blob.post_content.toLowerCase().includes(value.toLowerCase());
    });
    setPosts(filteredData);
  }

  return (

    <>
      <div className="flex items-center space-x-2 mb-4">
        <label className="text-gray-700">Filter posts by keyword:</label>
        <input 
          type="text" 
          className="border border-gray-300 rounded-md p-2"
          placeholder="Enter keyword"
          onChange={(e) => onFilterChange(e.target.value)} 
        />
      </div>

      <Table
        bordered
        showSorterTooltip={false}
        tableLayout="auto"
        size="small"
        columns={post_columns}
        pagination={{ defaultPageSize: 50 }}
        dataSource={posts}
      />
    </>
  );
}

export default Posts;
