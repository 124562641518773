import React from 'react';
import ProfileToggle from '../ProfileToggle';
import DashboardLayout from '../Layouts/DashboardLayout';
import { Popover } from '@headlessui/react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import * as ApiHelper from '../Helpers/ApiHelpers';
import Spinner from '../Spinner';
import Card from '../Card';
import PersonListDropdown from '../Engage/PersonListDropdown';
import { Popconfirm, Slider, Row, Col, Tooltip, Avatar, Table, Checkbox, Button } from 'antd';
import { CalendarIcon, ChevronDownIcon, QuestionMarkCircleIcon, EnvelopeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import _ from 'underscore';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale;
    ApiHelper.prepHttpCall();
    this.state = {
      records: [],
      loading: true,
      incomingCounts: [],
      outgoingCounts: [],
      pageSize: 20,
      start: 0,
      timePeriod: 30,
      isFetching: false,
      hasMoreResults: true,
      filterByHeadline: props.commonData.icpSettings.filterByHeadline,
      isFilterEnabled: props.commonData.icpSettings.isFilterEnabled
    };
    this.getRecords = this.getRecords.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
    this.onTimePeriodChange = this.onTimePeriodChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.submitFilterState = this.submitFilterState.bind(this);
    this.updatePeopleToLists = this.updatePeopleToLists.bind(this);
    this.getRecords();
  }

  onFilterChange(value) {
    if (!this.state.isFilterEnabled) {
      this.setState({ records: this.state.allRecords });
      return;
    }
    
    this.setState({ filterByHeadline: value }, () => {
      if (!value) {
        this.setState({ records: this.state.allRecords });
        return;
      }

      const filteredRecords = this.state.allRecords.filter((record) => {
        return record.title_or_headline.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({ records: filteredRecords });
      this.submitFilterState();
    });
  }

  onTimePeriodChange(evt) {
    this.setState({
      records: [],
      start: 0,
      timePeriod: evt
    }, () => this.getRecords());
  }

  handleOnScroll(e) {
    if (this.state.isFetching) {
      return;
    }
    if (!this.state.hasMoreResults) {
      return;
    }
    this.setState({
      isFetching: true,
      start: this.state.start + this.state.pageSize
    }, () => this.getRecords());
  }

  getRecords() {
    let url = '/api/v1/lists/top_leads/';
    let params = {
      listId: this.props.listId,
      start: this.state.start,
      count: this.state.pageSize,
      timePeriod: this.state.timePeriod
    }

    axios.get(url, { params, headers: ApiHelper.headers() }).then((response) => {
      let hasMoreResults = response.data.records.length > 0;
      console.log('People To Lists', response.data.peopleToLists);
      this.setState({
        incomingCounts: response.data.incomingCounts,
        outgoingCounts: response.data.outgoingCounts,
        allRecords: _.uniq(this.state.records.concat(response.data.records), false, (p) => p.id),
        records: _.uniq(this.state.records.concat(response.data.records), false, (p) => p.id),
        isFetching: false,
        hasMoreResults: hasMoreResults,
        peopleToLists: response.data.peopleToLists,
        customLists: response.data.customLists,
        loading: false
      });
      this.onFilterChange(this.state.filterByHeadline);
    });
  }

  hidePerson(personId) {
    let newArr = _.reject(this.state.records, (e) => e.id === personId);
    this.setState({ records: newArr });

    let url = '/api/v1/lists/' + this.props.listId + '/people/' + personId + '/suppress'
    axios.put(url, ApiHelper.headers()).then(() => {});
  }

  handleCheckboxChange(e) {
    this.setState({ isFilterEnabled: e.target.checked }, () => {
      this.onFilterChange(this.state.filterByHeadline);
      this.submitFilterState();
    });
  }

  handleFilterChange(e) {
    this.onFilterChange(e.target.value);
  }

  submitFilterState() {
    const { isFilterEnabled, filterByHeadline } = this.state;
    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken + "/li/" + 
      this.props.commonData.linkedInUserId +
      '/update_icp_filters';

    let data = {
      isFilterEnabled,
      filterByHeadline
    };
    axios.put(url, data, { headers: ApiHelper.headers() }).then((response) => {
      console.log('Filter state submitted:', response);
    });
  }

  updatePeopleToLists(personId, listId, action) {
    this.setState(prevState => {
      const updatedPeopleToLists = { ...prevState.peopleToLists };
      if (action === 'remove') {
        updatedPeopleToLists[personId] = updatedPeopleToLists[personId].filter(id => id !== parseInt(listId));
      } else if (action === 'add') {
        updatedPeopleToLists[personId] = [...updatedPeopleToLists[personId], parseInt(listId)];
      }
      return { peopleToLists: updatedPeopleToLists };
    });
  }

  render() {
    let cols = [
      {
        title: locale.t("top_leads.name_col_header"),
        width: 250,
        dataIndex: '',
        key: '',
        render: (e) => (
          <>
            <div className="flex items-center space-x-4">
              <div className="h-10 w-10 flex-shrink-0">
                <a href={e.profile_url} target="_blank">
                  <img className="h-10 w-10 rounded-full" src={e.headshot_url} alt="" />
                </a>
              </div>
              <div className="ml-4">
                <a href={e.profile_url} target="_blank">
                  <div className="font-medium">{e.full_name}</div>
                </a>
              </div>
          
              <div className="-mt-4 ml-auto">
                <PersonListDropdown
                  personId={e.id}
                  personName={e.full_name}
                  // activity={activity}
                  peopleToLists={this.state.peopleToLists}
                  customLists={this.state.customLists}
                  onUpdateLists={this.updatePeopleToLists}
                  isRepliesPage={true}
                />
              </div>
            </div>
          </>
        ),
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      { title: "Headline", render: (e) => e.title_or_headline },
      {
        title: locale.t("top_leads.incoming_engagements_col_header"),
        width: 190,
        defaultSortOrder: 'descend',
        className: 'text-right',
        render: (e) => this.state.incomingCounts[e.id],
        sorter: (a, b) => this.state.incomingCounts[a.id] - this.state.incomingCounts[b.id],
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: locale.t("top_leads.outgoing_engagements_col_header"),
        width: 190,
        defaultSortOrder: 'descend',
        className: 'text-right',
        render: (e) => this.state.outgoingCounts[e.id],
        sorter: (a, b) => this.state.outgoingCounts[a.id] - this.state.outgoingCounts[b.id],
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        width: '100px',
        title: ' ',
        render: (e) => (
          <div className="flex justify-center">
            <Tooltip className="inline-block" title={ locale.t("top_leads.send_a_dm_msg") }>
              <a href={e.profile_url} target="_blank">
                <EnvelopeIcon width="24px" />
              </a>
            </Tooltip>

            <Popconfirm
              title={ locale.t("top_leads.mark_as_not_lead_popconfirm_title") }
              description={ locale.t("top_leads.mark_as_not_lead_popconfirm_desc") }
              onConfirm={() => this.hidePerson(e.id)}
              okText="Yes"
              cancelText="No"
            >
              <a className="inline-block pl-4">
                <EyeSlashIcon width="24px" />
              </a>
            </Popconfirm>
          </div>
        )
      }
    ];

    let tip = <>
        <div>Use this to search for keywords in the Headlines of profiles that have engaged with you.</div>
        <br />
        <div>This could be titles you sell to, like "marketing" or "CEO".</div>
        <br />
        <div>Alternatively, think about searching for company names: "Nike" or "Blackstone". Or, you could look for a more general keyword like "LinkedIn", to try to find anyone who brands themselves as a "LinkedIn strategist" or "LinkedIn ghostwriter" etc.</div>
        <br />
      </>

    return (
      <DashboardLayout
        title={ locale.t("top_leads.title") }
        subtitle={ locale.t("top_leads.subtitle") }
        commonData={this.props.commonData}>

        <div className='w-full'>
          { this.props.commonData.currentUserRole == 'collaborator' && (
            <Card>
              <ProfileToggle
                commonData={this.props.commonData}
                profiles={ this.props.commonData.profiles }
                selectedProfile={ this.props.commonData.actAsLinkedInUserId }
                style={{ height: 49 }}/>
            </Card>
          )}

          {this.state.loading && <Spinner title="Fetching top leads" size="small" />}

      <div className="my-8 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0">
          <Checkbox 
            checked={this.state.isFilterEnabled} 
            onChange={this.handleCheckboxChange}
            className="mr-2 filter-label"
          >
            Filter by Headline keywords:
          </Checkbox>
          <input 
            type="text" 
            className={`border border-gray-300 rounded-md p-2 ml-0 sm:ml-2 mt-2 sm:mt-0 ${this.state.isFilterEnabled ? '' : 'bg-gray-100 text-gray-500 cursor-not-allowed'}`}
            placeholder="Founder, CEO"
            disabled={!this.state.isFilterEnabled}
            value={this.state.filterByHeadline}
            onChange={this.handleFilterChange} 
          />
          <Tooltip title={tip} className="ml-0 sm:ml-4 mt-2 sm:mt-0">
            <QuestionMarkCircleIcon width="20px" style={{ color: 'gray', verticalAlign: 'middle' }} />
          </Tooltip>
        </div>

        <Popover className="relative">
          {({ close }) => (
            <>
              <Popover.Button className="flex border-none items-center gap-1 rounded-full bg-gray-100 px-2 py-2 text-gray-700 text-xs">
                <CalendarIcon className="w-4 h-4" />
                <span>
                  {`${locale.t("top_leads.top_leads_in_last_msg_start")} ${this.state.timePeriod} ${locale.t("top_leads.top_leads_in_last_msg_end")}`}
                </span>
                <ChevronDownIcon className="w-4 h-4 text-gray-400" />
              </Popover.Button>
              <Popover.Panel className="absolute text-sm z-10 mt-2 border bg-gray-100 border-gray-200 p-2 rounded-md shadow-lg">
                <Slider
                  className="w-48"
                  defaultValue={this.state.timePeriod}
                  min={1}
                  max={60}
                  onAfterChange={(value) => {
                    this.onTimePeriodChange(value);
                    close(); // Close the popover after selection
                  }}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>

      </div>


          <Table
            bordered
            style={{ width: '100%' }}
            size="small"
            columns={cols}
            dataSource={this.state.records}
            pagination={false}
            rowKey={(record) => record.id}
          />
          <BottomScrollListener onBottom={this.handleOnScroll} offset={299} />
        </div>

      </DashboardLayout>
    );
  }
}

export default Index;

