import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { Card, Row, Col, Switch, message, Radio, Button, Form, Select, Alert, Typography } from 'antd';
import Spinner from '../Spinner';
import _ from 'underscore';

const { Title } = Typography;
const { Paragraph } = Typography;
const axios = require('axios');

class Advanced extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isEditable: true, isLoaded: false };
    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url =
      '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/hubspot/connect';

    let self = this;
    axios.get(url, ApiHelper.headers()).then(function (response) {
      self.setState(
        {
          advancedInputs: response.data.advancedInputs,
          liProfileUrl: response.data.advancedInputs.matchingOptions.liProfileUrl,
          firstNameLastNameAccountName:
            response.data.advancedInputs.matchingOptions.firstNameLastNameAccountName,
          firstNameLastName: response.data.advancedInputs.matchingOptions.firstNameLastName
        },
        () => {
          self.getSfdcFields();
        }
      );
    });
  }

  getSfdcFields() {
    let self = this;

    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/hubspot/contacts/custom_fields';

    axios
      .get(url, ApiHelper.headers())

      .then(function (response) {
        let data = response.data;

        self.setState({
          isLoaded: true,
          // hubspotLeadStatusPicklistValues: data.leadStatusPicklistValues,
          // hubspotLeadSourcePicklistValues: data.leadSourcePicklistValues
        });
      });
  }

  saveAdvancedInput(settingName, e) {
    let settings = this.state.advancedInputs;
    settings[settingName] = e;
    this.setState({ advancedInputs: settings });
  }

  saveMatchingOptions(settingName, e) {
    this.setState({ [settingName]: e });
    console.log('state', this.state);
  }

  popMessage(str) {
    message.info(str);
  }

  saveForm() {
    let url =
      '/api/v1/accounts/' +
      this.props.commonData.currentAccountUniqueToken +
      '/hubspot/connect/save_advanced_inputs';
    let inputData = this.state.advancedInputs;
    let matchingOptions = inputData['matchingOptions'];

    matchingOptions['liProfileUrl'] = this.state.liProfileUrl;
    matchingOptions['firstNameLastName'] = this.state.firstNameLastName;
    matchingOptions['firstNameLastNameAccountName'] = this.state.firstNameLastNameAccountName;
    // matchingOptions['createNewLeadRecords'] = this.state.advancedInputs.createNewLeadRecords;
    inputData.matchingOptions = matchingOptions;

    let data = { advanced_inputs: inputData };

    let self = this;
    axios.put(url, data, ApiHelper.headers()).then(function (response) {
      self.popMessage('Advanced inputs saved');
    });
  }

  drawAdvancedInputs() {
    let r = (
      <>
        <Form layout="vertical">

          <Form.Item label="Create new Contact records in CRM if none are found?">
            <Radio.Group
              onChange={(e) => this.saveAdvancedInput('createNewLeadRecords', e.target.value)}
              disabled={!this.state.isEditable}
              defaultValue={this.state.advancedInputs.createNewLeadRecords}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>

          <Title level={4}>Match on:</Title>

          <Alert
            message="The following fields MUST be mapped above, or these selections will be ignored."
            type="warning"
            style={{ marginBottom: '12px' }}
          />

          <Form.Item label="LinkedIn profile URL" name="linkedInProfileURL">
            <Switch
              onChange={(checked, e) => this.saveMatchingOptions('liProfileUrl', checked)}
              checked={this.state.liProfileUrl}></Switch>
          </Form.Item>

          <Form.Item
            label="First Name, Last Name, Account name"
            name="firstNameLastNameAccountName">
            <Switch
              onChange={(checked, e) =>
                this.saveMatchingOptions('firstNameLastNameAccountName', checked)
              }
              checked={this.state.firstNameLastNameAccountName}></Switch>
          </Form.Item>

          <Form.Item label="First Name and Last Name" name="firstNameLastName">
            <Switch
              onChange={(checked, e) => this.saveMatchingOptions('firstNameLastName', checked)}
              checked={this.state.firstNameLastName}></Switch>
          </Form.Item>

          <Button
            type="primary"
            size="large"
            disabled={!this.state.isEditable}
            style={{ marginTop: '36px', marginRight: '100px', float: 'right' }}
            onClick={() => this.saveForm()}>
            Save
          </Button>
        </Form>
      </>
    );
    return r;
  }

  render() {
    return (
      <Card className='mb-16'>
				<Title level={4}>Advanced options <span style={{ fontSize: '14px' }}>(Selections here apply to all profiles in this Aware account)</span></Title>
        <Row>

          <Col xs={24} lg={12}>
            <Paragraph>Choose advanced options for how data is synced to Hubspot.</Paragraph>
          </Col>

          <Col xs={24} lg={12}>
            {!this.state.isLoaded && <Spinner />}
            {this.state.isLoaded ? this.drawAdvancedInputs() : <></>}
          </Col>

        </Row>
      </Card>
    );
  }
}

export default Advanced;
