
import React from 'react';
import ReactQuill from 'react-quill';
import * as Emoji from 'quill-emoji';
import axios from 'axios';
import { I18n } from 'i18n-js';
import translations from '/app/frontend/translations.json';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-mention';

const locale = new I18n(translations);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props', props);
    this.state = {
      placeholder: props.placeholder,
      comment: '',
      theme: 'snow',
      editorContent: props.content,
    };
    this.reactQuillRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.getTypeAheadResults = this.getTypeAheadResults.bind(this);
    this.formatOptions = this.formatOptions.bind(this);
    this.handleBold = this.handleBold.bind(this);
    this.handleItalic = this.handleItalic.bind(this);
    this.handleStrike = this.handleStrike.bind(this);

    this.editorFormats = [
      'mac', 'boldUnicode', 'italicUnicode', 'strikethroughUnicode',
      'link', 'image', 'emoji'
    ];

    this.editorModules = {
      toolbar: this.toolbarOptions(),
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      // mention: {
      //   blotName: 'mac',
      //   allowedChars: /^[A-Za-z\s]*$/,
      //   mentionDenotationChars: ["@"],
      //   dataAttributes: ['id', 'value', 'entityUrn'],
      //   source: async (searchTerm, renderList) => {
      //     const matchedPeople = await this.getTypeAheadResults(searchTerm);
      //     renderList(matchedPeople);
      //   },
      // }
    };
  }

  componentDidMount() {
    if (this.reactQuillRef.current && !this.props.isComment) {
      const toolbar = this.reactQuillRef.current.getEditor().getModule('toolbar');
      toolbar.addHandler('bold', this.handleBold);
      toolbar.addHandler('italic', this.handleItalic);
      toolbar.addHandler('strike', this.handleStrike);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.content !== prevProps.content) {
      // console.log('Prop has changed:', this.props.content);
      this.setState({ editorContent: this.props.content });
    }
    if (this.props.placeholder !== prevProps.placeholder) {
      this.setState({ placeholder: this.props.placeholder });
    }
  }

  handleChange(content) {
    this.setState({ editorContent: content });
    this.props.onChangeCallback(content);
  }

  async getTypeAheadResults(query) {
    query = escape(query);
    if (query === '') return;

    const headers = {
      params: {
        query: query,
        li_user_id: this.props.commonData.linkedInUserId
      },
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    return axios.get('/api/v1/typeahead/person_or_account', headers).then(response => {
      return response.data.map(this.formatOptions);
    });
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  formatOptions(item) {
    const navigationUrl = item.entityLockupView.navigationUrl.split('fsd_profile%3A')[1].split('&fetch')[0];
    return {
      id: navigationUrl,
      value: this.titleCase(item.entityLockupView.title.text),
      entityUrn: item.entityLockupView.trackingUrn
    };
  }

  handleBold() {
    if (this.reactQuillRef && this.reactQuillRef.current) {
      const quill = this.reactQuillRef.current.getEditor();
      let selection = quill.getSelection();
      if (selection) {
        let text = quill.getText(selection.index, selection.length);
        let unicodeText = [...text].map(char => {
          if (char >= 'A' && char <= 'Z') {
            return String.fromCodePoint(char.codePointAt(0) + 0x1D400 - 0x41);
          } else if (char >= 'a' && char <= 'z') {
            return String.fromCodePoint(char.codePointAt(0) + 0x1D41A - 0x61);
          }
          return char;
        }).join('');
        quill.deleteText(selection.index, selection.length);
        quill.insertText(selection.index, unicodeText);
      }
    }
  }

  handleItalic() {
    if (this.reactQuillRef && this.reactQuillRef.current) {
      const quill = this.reactQuillRef.current.getEditor();
      let selection = quill.getSelection();
      if (selection) {
        let text = quill.getText(selection.index, selection.length);
        let unicodeText = [...text].map(char => {
          if (char >= 'A' && char <= 'Z') {
            return String.fromCodePoint(char.codePointAt(0) + 0x1D434 - 0x41);
          } else if (char >= 'a' && char <= 'z') {
            return String.fromCodePoint(char.codePointAt(0) + 0x1D44E - 0x61);
          }
          return char;
        }).join('');
        quill.deleteText(selection.index, selection.length);
        quill.insertText(selection.index, unicodeText);
      }
    }
  }

  handleStrike() {
    if (this.reactQuillRef && this.reactQuillRef.current) {
      const quill = this.reactQuillRef.current.getEditor();
      let selection = quill.getSelection();
      if (selection) {
        let text = quill.getText(selection.index, selection.length);
        let unicodeText = [...text].map(char => char + '\u0336').join('');
        quill.deleteText(selection.index, selection.length);
        quill.insertText(selection.index, unicodeText);
      }
    }
  }

  toolbarOptions() {
    if (this.props.isComment) {
      return null;
    }
    return {
      container: [['bold', 'italic', 'strike'], ['emoji']],
      handlers: {
        bold: this.handleBold,
        italic: this.handleItalic,
        strike: this.handleStrike,
        emoji: function () { }
      }
    };
  }

  render() {
    return (
      <div className="parent-scroll">
        <div id="scrolling-container">
          <ReactQuill
            ref={this.reactQuillRef}
            theme={this.state.theme}
            onChange={this.handleChange}
            bounds="#scrolling-container"
            scrollingContainer=".parent-scroll"
            modules={this.editorModules}
            formats={this.editorFormats}
            defaultValue={this.props.content}
            value={this.state.editorContent}
            placeholder={this.state.placeholder}
            key={this.state.placeholder}
          />
        </div>
      </div>
    );
  }
}

export default Editor;

