import React from 'react';
import { Button, Popconfirm, Card, Typography } from 'antd';
import DashboardLayout from '../Layouts/DashboardLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';

const { Title } = Typography;
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale
    this.onClick = this.onClick.bind(this);
    this.state = { cardDetails: '' };
    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url = '/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/billing';

    let self = this;
    axios.get(url, ApiHelper.headers()).then(function (response) {
      console.log('response', response);

      self.setState({
        cardDetails: response.data.cardDetails
      });
    });
  }

  onClick(e) {
    window.location.href =
      'https://billing.stripe.com/p/login/bIY7vp08f5wDdq07ss?prefilled_email=' +
      this.props.commonData.currentUserEmail;
  }

	showForm() {
    return (
        <Card>
          <div className="text-lg font-bold mb-4">Payment methods and plan</div>
          <div className="flex justify-between items-center mb-4">
            <div className="text-base">Payment method</div>

            <a
              href="#"
              className="px-4 rounded-lg text-blue-600 hover:text-blue-800"
              onClick={this.onClick}
            >
              {locale.t("billing.update_payment_method_link_text")}
            </a>

          </div>
          <hr className="my-4" />

          <div className="flex justify-between items-center mb-4">
            <div className="text-base">{locale.t("billing.change_plan_title")}</div>

            <a
              href="#"
              className="px-4 rounded-lg text-blue-600 hover:text-blue-800"
              onClick={this.onClick}
            >
              {locale.t("billing.upgrade_downgrade_link_text")}
            </a>

          </div>
          <hr className="my-4" />

          <div className="flex justify-between items-center mb-4">
            <div className="text-base">{locale.t("billing.view_past_invoices_title")}</div>

            <a
              href="#"
              className="px-4 rounded-lg text-blue-600 hover:text-blue-800"
              onClick={this.onClick}
            >
              {locale.t("billing.view_invoices_link_text")}
            </a>
          </div>

          <hr className="my-4" />

          <div className="flex justify-between items-center">
            <div className="text-base">Cancel subscription</div>
            <Button danger className="px-4 rounded-lg" onClick={this.onClick}>
              Cancel subscription
            </Button>
          </div>
        </Card>
    );
  }

  render() {
    return <div>{this.showForm()}</div>;
  }
}

export default Index;
