import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import Spinner from '../Spinner';
import DashboardLayout from '../Layouts/DashboardLayout';
import Posts from '../Posts';
import PeopleOrAccountSearch from '../PeopleOrAccountSearch';
import StatisticCard from '../Dashboard/StatisticCard';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { Divider, Row, Col, Avatar, Card, Table, Typography } from 'antd';

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const axios = require('axios');

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      engagements: this.props.engagements,
      connection: this.props.connection,
      data: this.props.data
    };
    ApiHelper.prepHttpCall();

    this.callback = this.callback.bind(this);
  }

  onAutoCompleteSelect(value) {
    console.log('onAutoCompleteSelect', value);

    this.setState({
      loading: true
    });

    let self = this;
    let url = '/api/v1/explorer';
    axios
      .post(
        url,
        {
          entity_urn: value,
          li_user_id: self.props.commonData.linkedInUserId
        },
        ApiHelper.headers()
      )
      .then(function (response) {
        console.log('response', response);
        Helper.startPolling(self.props.commonData, response.data.jobId, value, self.callback);
      });
  }

  callback(entityUrnId) {
    let url = '/0/' + this.props.commonData.currentAccountUniqueToken + '/explorer/' + entityUrnId;
    window.location.href = url;
  }

  companyName(obj) {
    if (obj.company == undefined) {
      return '';
    } else {
      return 'at ' + obj.company.name;
    }
  }

  viewPostLink(data) {
    if (data.blob != undefined && data.blob.permalink != undefined) {
      return (
        <a href={data.blob.permalink} target="_blank">
          View post
        </a>
      );
    } else {
      return '';
    }
  }

  cardView() {
    if (this.state.connection == undefined) {
      return <> </>;
    }
    let footer = (
        <div style={{ borderTop: '', paddingTop: '16px', marginTop: '32px' }}>
          <MapPinIcon width="16px" />
          <span style={{ marginLeft: '4px' }}>{this.state.connection.location}</span>
          <Divider type="vertical" />
          <span style={{ fontWeight: 'bold' }}>{this.state.data.followers} </span>
          Followers
        </div>
    );

    return (
      <Card className="no-top-margin">
        <Title level={4}>{this.props.connection.full_name}</Title>

        <div className="box">
          <div className="avatar-x-large" style={{ textAlign: 'center', flex: '0 0 148px' }}>
            <Avatar src={this.props.connection.headshot_url} />
          </div>

          <div style={{ padding: '0 24px' }}>
            <Paragraph>
              <Title level={5} className="no-padding">
                {this.props.connection.title} {this.companyName(this.props.connection)}
              </Title>
              {this.props.connection.headline}
            </Paragraph>
          </div>
        </div>

        <Meta description={footer} />
      </Card>
    );
  }

  connectionView() {
    // console.log(this.state.connection);
    if (this.state.connection == undefined) {
      return <> </>;
    }
    const engagement_columns = [
      {
        title: 'Date',
        dataIndex: 'event_date',
        key: 'event_date'
      },
      {
        title: 'Details',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: '',
        render: (e) => this.viewPostLink(e)
      }
    ];
    return (
      <>
        <Row gutter={24} style={{ marginTop: '24px' }}>
          {/* <Col xs={24} lg={12} xl={8} xxl={6}> */}
          {/*   <StatisticCard title="Posts" data={this.props.data.posts_count} /> */}
          {/* </Col> */}

          <Col xs={24} lg={12} xl={8} xxl={6}>
            <StatisticCard title="Engagements" data={this.props.data.engagements} />
          </Col>

          <Col xs={24} lg={12} xl={8} xxl={6}>
            <StatisticCard title="Followers" data={this.props.data.followers} />
          </Col>
        </Row>

        {/* <Card> */}
        {/*   <Title level={4} className="no-border"> */}
        {/*     Your engagement history with {this.props.connection.full_name} */}
        {/*   </Title> */}
        {/*   <Table columns={engagement_columns} dataSource={this.state.engagements} /> */}
        {/* </Card> */}

        <Title level={5} className="top-card section-header">
          Recent Posts
        </Title>

        <Posts posts={this.props.posts} hideViews={true} />
      </>
    );
  }

  showForm() {
    return (
      <DashboardLayout
        title="Profile insights"
        subtitle="Search on a LinkedIn profile for detailed insights, and engagement history"
        commonData={this.props.commonData}>
        <div style={{ margin: '16px auto auto', maxWidth: '500px' }}>
          {!this.state.loading && (
            <PeopleOrAccountSearch
              linkedInUserId={this.props.commonData.linkedInUserId}
              onAutoCompleteSelectHandler={(e) => this.onAutoCompleteSelect(e)}
              placeholder="Enter a person's name"
            />
          )}
        </div>

        {this.state.loading && <Spinner title="Fetching profile and engagement info" />}

        <Row gutter={24} style={{ marginTop: '16px' }}>
          <Col span={14}>{!this.state.loading && this.cardView()}</Col>
        </Row>

        {!this.state.loading && this.connectionView()}
      </DashboardLayout>
    );
  }

  render() {
    return <div>{this.showForm()}</div>;
  }
}

export default Index;
