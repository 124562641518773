import React from 'react';
import * as ApiHelper from '../Helpers/ApiHelpers';
import SessionLayout from '../Layouts/SessionLayout';
import { Select, Typography, Form, Input, Button, Checkbox } from 'antd';
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

const { Paragraph, Text } = Typography;
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Login extends React.Component {

  constructor(props) {
    super(props);
    initPostHog();

    this.onFinish = this.onFinish.bind(this);
    this.onLocaleToggle = this.onLocaleToggle.bind(this);
    locale.locale = props.session.locale

    this.state = {
      hasError: false
    };
    ApiHelper.prepHttpCall();
  }

  onLocaleToggle(event) {
    locale.locale = event
    // console.log('event', event)

    // let params = {
    //   locale: event
    // };
    //
    // let url = '/api/v1/users/' + + '/locale';
    //
    // axios.put(url, params, ApiHelper.headers()).then(function (response) {
    //   console.log('response', response);
    // });

    let params = {
      locale: event
    };

    let url = '/locale'

    axios.put(url, params, ApiHelper.headers()).then(function (response) {
      // console.log('response', response);
    });

    this.forceUpdate()
    // return
  }

  onFinish(values) {
    // console.log('Received values of form: ', values);
    let params = {
      user: {
        email: values.email,
        password: values.password
      }
    };

    let self = this;
    let url = '/users/sign_in';

    axios
      .post(url, params)

      .then(function (response) {
        try {
          // console.log('response id', response.data.id);
          // console.log('response', response.data.email);
          // console.log('Posthog identify', gon.user_id, gon.user_email, gon.user_name);
          posthog.identify(
            response.data.id,
            { email: response.data.email }
          );
        } catch (e) {
          console.log('Posthog error');
          console.log(e);
        }
        self.onFormResponse(response);
      })

      .catch((res) => {
        console.log('Error');
        console.log(res);

        this.setState({
          hasError: true
        });
      });
  }

  onFormResponse(response) {
    window.location.href = '/';
    return;
  }

  render() {
    return (
      <SessionLayout>
        <Form
          name="email"
          initialValues={{
            remember: true
          }}
          onFinish={this.onFinish}>
          <Text type="danger" className={`${this.state.hasError ? '' : 'hidden'}`}>
            Invalid login. Please try again.
          </Text>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: locale.t("sign_in.please_input_email_msg")
              }
            ]}>
            <Input prefix={<UserIcon width={20} />} placeholder={ locale.t("sign_up.email_placeholder") } />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: locale.t("sign_in.please_input_password_msg")
              }
            ]}>
            <Input prefix={<LockClosedIcon width={20} />} type="password" placeholder={ locale.t("sign_up.password_placeholder") } />
          </Form.Item>

          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox style={{ float: 'right' }}>{ locale.t("sign_in.remember_me") }</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              htmlType="submit"
              className="login-form-button">
              Log in
            </Button>
            <br />
            <br />
          </Form.Item>

          <Paragraph>
            <a href="/users/password/new">{ locale.t("sign_in.forgot_password_link_text") }</a>
          </Paragraph>

          <Paragraph>
            { locale.t("sign_in.dont_have_account_msg") }
            <a href="/users/sign_up">
              <br />
              { locale.t("sign_in.start_trial_link_text") }
            </a>
          </Paragraph>

          <div style={{ width: '100%', textAlign: 'center' }}>
            <Select style={{ width: 120 }}
                defaultValue={{ value: this.props.session.locale }}
                onChange={this.onLocaleToggle}
                options={[{ value: 'en', label: <span>English</span> }, { value: 'fr', label: <span>French</span> }]} />
          </div>
        </Form>
      </SessionLayout>
    );
  }
}

export default Login;
