import React from 'react';
import { Rings } from 'react-loader-spinner';
import { Typography } from 'antd';
const { Title } = Typography;

class InlineSpinner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="flex justify-center items-center mb-4">
        <Rings
          wrapperStyle={{ display: 'inline-block' }}
          height={32}
          width={32}
          color="#1890ff"
          radius="16"
          visible={true}
          ariaLabel="rings-loading"
        />
        <div className="ml-2 text-gray-500">{this.props.title}</div>
      </div>

    );
  }
}
export default InlineSpinner;
