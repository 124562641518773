import React from "react";
import { Button } from 'antd';
import { EditorThemeContext } from "../EditorContext";

const PostToolbar = () => {
  const { theme, setTheme, device, setDevice } = React.useContext(EditorThemeContext);
  return (
    <div className={`flex justify-between items-center p-2 text-xs rounded mx-2 ${theme  == "light" ? "bg-gray-100":"bg-gray-700"}`}>
      <div className="flex gap-2 items-center ">
        {/* <span>Device:</span> */}
        <Button
        onClick={() => setDevice("desktop")}
          className={`p-2 rounded-full ${
            device == "desktop" ? "bg-gray-300 border-gray-700" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{width:"16px", height:"16px"}}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
            />
          </svg>
        </Button>
        <Button
        onClick={() => setDevice("tablet")}
          className={`p-2 rounded-full ${
            device == "tablet" ? "bg-gray-300 border-gray-700" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{width:"16px", height:"16px"}}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
        </Button>
        <Button
          onClick={() => setDevice("mobile")}
          className={`p-2 rounded-full ${
            device == "mobile" ? "bg-gray-300 border-gray-700" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{width:"16px", height:"16px"}}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
            />
          </svg>
        </Button>
      </div>
      <div className="flex gap-2 items-center">
        {/* <span>Theme:</span> */}
        <Button
         onClick={() => setTheme("light")}
          className={`p-2 rounded-full ${
            theme == "light" ? "bg-gray-300" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{width:"16px", height:"16px"}}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          </svg>
        </Button>
        <Button onClick={() => setTheme("dark")} className={`p-2 rounded-full ${
            theme == "dark" ? "bg-gray-300" : ""
          }`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{width:"16px", height:"16px"}}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default PostToolbar;
