import React from 'react';
import { Button, Popconfirm, Alert, Table } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import _ from 'underscore';

const axios = require('axios');

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    ApiHelper.prepHttpCall();
    this.loadComponent();
  }

  loadComponent() {
    let url = '/api/v1/admin/accounts';
    let self = this;

    axios.get(url, ApiHelper.headers()).then(function (response) {
      self.setState({
        activeAccounts: response.data.activeAccounts
      });
    });
  }

  cancelSubscription(value) {
    let url = '/admin/users/' + value;
    let self = this;

    axios.delete(url, ApiHelper.headers()).then(function (response) {
      let d = response.data.message;
      self.setState({
        message: d
      });
    });
  }

  users(e) {
    let self = this;
    if (e == undefined) {
      return '';
    } else {
      let r = [];
      _.map(e.users, function (k) {
        r.push(self.emailLink(k.email));
      });
      if (r.length) {
        let k = r.reduce((prev, curr) => [prev, <br key={e.id} />, curr]);
        return <span key={e.id}>{k}</span>;
      } else {
        return '';
      }
    }
  }

  liUsers(e) {
    let self = this;
    if (e == undefined) {
      return '';
    } else {
      let r = [];
      _.map(e.linked_in_users, function (k) {
        r.push(k.public_identifier);
      });
      return r.join(', ');
    }
  }

  emailLink(email) {
    return (
      <a href={'mailto:' + email} key={email}>
        {email}
      </a>
    );
  }

  liUser(e) {
    let url = '/api/v1/admin/users/' + e.users[0].id + '/become';

    axios.get(url, ApiHelper.headers()).then(function (response) {
      window.location.href = '/';
    });
  }

  render() {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    const cols = [
      {
        dataIndex: '',
        key: '',
        render: (e) => (
          <a href="#" onClick={() => this.liUser(e)}>
            Login as...
          </a>
        )
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        className: 'subtle-table-col',
        key: 'created_at',
        render: (e) => new Date(e).toLocaleDateString('en-US', options)
      },
      {
        title: 'User emails',
        key: 'email',
        render: (e) => this.users(e)
      },
      {
        title: 'Profile emails',
        className: 'profile-email-col',
        render: (e) => this.liUsers(e)
      },
      {
        title: '',
        render: (e) => (
          <Popconfirm
            title="Are you sure you want to cancel this subscription?"
            onConfirm={() => this.cancelSubscription(e.id)}>
            <Button size="small" type="link" danger>
              Cancel subscription
            </Button>
          </Popconfirm>
        )
      }
    ];

    return (
      <>
        <Alert
          className={`${this.state.message ? '' : 'hidden'}`}
          type="success"
          message={this.state.message}
        />

        <Table
          size="small"
          rowKey="id"
          columns={cols}
          dataSource={this.state.activeAccounts}
          pagination={{ pageSize: 50 }}
        />
      </>
    );
  }
}

export default Accounts;
