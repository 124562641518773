import React from 'react';
import Theme from './Theme';
import TopBar from './TopBar';
import { Typography, Layout } from 'antd';
const { Header, Content } = Layout;
const { Title } = Typography;

class PaywallLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Theme>
        <Layout>
          <Content>
            <div class="mx-auto max-w-7xl" style={{ margin: 'auto', textAlign: 'center' }}>
              {this.props.children}
            </div>
          </Content>
        </Layout>
      </Theme>
    );
  }
}

export default PaywallLayout;
