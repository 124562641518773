import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import { Card } from 'antd';
import * as Helper from '../Helpers/Helpers';
import Preferences from './Preferences';
import Billing from '../Billing/Index';
import Users from '../Users/Index';
import { CreditCardIcon, AdjustmentsHorizontalIcon, UsersIcon } from '@heroicons/react/24/outline';

class Index extends React.Component {
  constructor(props) {
    super(props);

    // Determine the initial tab based on the URL path
    const path = window.location.pathname;
    let selectedTab = 'preferences'; // default to settings
    if (path.includes('billing')) {
      selectedTab = 'billing';
    } else if (path.includes('users')) {
      selectedTab = 'users';
    } else if (path.includes('preferences')) {
      selectedTab = 'preferences';
    }

    this.state = {
      selectedTab,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tab, url) {
    // Update the URL without reloading the page
    window.history.pushState({}, '', url);
    this.setState({ selectedTab: tab });
  }

  renderContent() {
    const { selectedTab } = this.state;
    const { commonData } = this.props;

    switch (selectedTab) {
      case 'billing':
        return <Billing commonData={commonData} />;
      case 'users':
        return <Users commonData={commonData} />;
      case 'preferences':
      default:
        return <Preferences commonData={commonData} />;
    }
  }

  render() {
    const { selectedTab } = this.state;
    const billing_url = Helper.accountSettingsUrl(this.props.commonData) + 'billing';
    const preferences_url = Helper.accountSettingsUrl(this.props.commonData) + 'settings';
    const users_url = Helper.accountSettingsUrl(this.props.commonData) + 'users';

    return (
      <DashboardLayout title="Settings" commonData={this.props.commonData} className="fixed-width">
        <div className="md:flex w-full gap-x-12">
          <ul className="list-none flex-column p-0 space-y-4 text-sm text-tertiary md:me-4 mb-4 md:mb-0">
            <li>
              <a
                href={preferences_url}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleTabClick('preferences', preferences_url);
                }}
                className={`inline-flex items-center px-4 py-3 rounded-lg w-full hover:bg-gray-100 text-tertiary ${
                  selectedTab === 'preferences'
                    ? 'bg-[#ecf8f7] '
                    : ''
                }`}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-4" />
                Preferences
              </a>
            </li>
            {this.props.commonData.isAccountOwner && (
              <li>
                <a
                  href={billing_url}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleTabClick('billing', billing_url);
                  }}
                  className={`inline-flex items-center px-4 py-3 rounded-lg w-full hover:bg-gray-100 text-tertiary ${
                    selectedTab === 'billing'
                      ? 'bg-[#ecf8f7] '
                      : ''
                  }`}
                >
                  <CreditCardIcon className="w-5 h-5 mr-4" />
                  Billing
                </a>
              </li>
            )}
            {this.props.commonData.isAccountOwner && (
              <li>
                <a
                  href={users_url}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleTabClick('users', users_url);
                  }}
                  className={`inline-flex items-center px-4 py-3 rounded-lg w-full hover:bg-gray-100 text-tertiary ${
                    selectedTab === 'users'
                      ? 'bg-[#ecf8f7] '
                      : ''
                  }`}
                >
                  <UsersIcon className="w-5 h-5 mr-4" />
                  Users
                </a>
              </li>
            )}
          </ul>

          <div className='w-full'>
            {this.renderContent()}
          </div>

        </div>
      </DashboardLayout>
    );
  }
}

export default Index;

