const axios = require('axios');
import * as ApiHelper from './ApiHelpers';

export function addCookieListener(myCallback, licenseKey) {
  // console.log('CookieHelper: add cookie listener')
  window.addEventListener('message', function (e) {
    // console.log('CookieHelper: add cookie listener - message received')

    var data = e.data;
    var extInstalled = data.extInstalled;
    var cookieFound = data.cookieFound;
    if(extInstalled == undefined) {
      // console.log('CookieHelper: message received: BAILING ', e);
      // console.log('CookieHelper: extension not found');
      return
    }
    //
    // console.log('CookieHelper: extInstalled', extInstalled);
    // console.log('CookieHelper: cookieFound', cookieFound);
    // console.log('CookieHelper: --- license', licenseKey);

    if (cookieFound) {
      sendCookie(cookieFound, licenseKey);
      myCallback(true);
    } else if (extInstalled) {
      myCallback(false);
    }
  });
}

export function sendCookie(data, licenseKey) {
  // console.log('CookieHelper: sendCookie', data);

  // let url = 'https://457c-93-138-82-1.ngrok.io/api/v1/cookies';
  let url = '/api/v1/cookies';

	let params

	if(typeof data === 'string' || data instanceof String)	{
		// Old extension
		// console.log('old ext')
		params = {
			licenseKey: licenseKey,
			cookie: data
		};

	} else {
		// New extension
		console.log('new ext')
    data['licenseKey'] = licenseKey
		params = data
	}

  if (licenseKey != undefined) {
    axios.post(url, params, ApiHelper.headers()).then(function (response) {
      console.log('CookieHelper: Cookie saved');
    });
  }
}
