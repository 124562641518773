import React from 'react';
import { Avatar, Input, AutoComplete } from 'antd';

const axios = require('axios');

class PeopleOrAccountSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      typing: false,
      typingTimeout: -1,
      searchInput: ''
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getTypeAheadResults = this.getTypeAheadResults.bind(this);
    this.formatOptions = this.formatOptions.bind(this);
    this.personOptions = this.personOptions.bind(this);
  }

  getTypeAheadResults(query) {

    // console.log('query', query)
    // console.log('encoded', encodeURIComponent(query))
    // console.log('query escape', escape(query))

    query = encodeURIComponent(query)

    if(query == '') {
      return
    }

    let headers = {
      params: {
        query: query,
        li_user_id: this.props.linkedInUserId
      },
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let self = this;

    axios.get('/api/v1/typeahead/person_or_account', headers).then(function (response) {
      let d = response.data;
      // console.log('... response', d)
      self.setState({
        dataSource: d.map(self.formatOptions)
      });
    });
  }

  companyImage(item) {
    if (item['companyPictureDisplayImage']) {
      let json = item['companyPictureDisplayImage'];
      let rootUrl = json['rootUrl'];
      let path = json['artifacts'][0]['fileIdentifyingUrlPathSegment'];
      return rootUrl + path;
    } else {
      return '';
    }
  }

  handleSelect(e) {
    this.setState({
      searchInput: ''
    });
    this.props.onAutoCompleteSelectHandler(e);
    return;
  }

  onChange(value) {
    this.setState({
      searchInput: value
    });
  }

  handleSearch(value) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    let self = this;

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getTypeAheadResults(value);
      }, 200)
    });
  }

  personOptions(item) {
    var vector = item.image.attributes[0].detailData.nonEntityProfilePicture.vectorImage
    var image = ''
    if( vector !== null ) {
      image = vector.artifacts[0]['fileIdentifyingUrlPathSegment'] 
    }
    return (
      <div>
        <Avatar src={ image } />
        <span> <b>{item.title.text}</b></span>
        <span>{item.subtitle.text}</span>
      </div>
    );
  }

  // accountOptions(item) {

  // 	return (
  // 		<div>
  // 			<Avatar src={this.companyImage(item)} />
  // 			{item.name} * {item.location} * {item.industry}
  // 		</div>
  // 	)
  // }

  formatOptions(item) {
    let node;

    // let kc = item.item.knowledgeCardV2.heroEntity
    // console.log('item', item)
    node = this.personOptions(item.entityLockupView);

    let navigationUrl = item.entityLockupView.navigationUrl.split('fsd_profile%3A')[1].split('&fetch')[0]
    // console.log('url', navigationUrl)
    return {
      value: navigationUrl,
      label: node
    };
  }

  render() {
    return (
      <>
        <AutoComplete
          dropdownMatchSelectWidth={true}
          style={{ width: '100%' }}
          onSearch={this.handleSearch}
          onSelect={this.handleSelect}
          onChange={this.onChange}
          options={this.state.dataSource}
          value={this.state.searchInput}>
          <Input.Search
            style={{ marginBottom: '24px' }}
            size="large"
            placeholder={this.props.placeholder}
          />
        </AutoComplete>
      </>
    );
  }
}

export default PeopleOrAccountSearch;
