import React, { Component } from "react";
import { Button, Modal, message } from "antd";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import TimeTableComponent from "./TimeTable";

class EditQueueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      newTime: null,
    };
  }

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOk = async () => {
    this.setState({ isModalOpen: false });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.state;

    return (
      <>
        <Button
            type="primary"
            className="flex items-center space-x-1"
            onClick={this.showModal}
            icon={<PencilSquareIcon width="25px" />}
            size="middle" >
          Edit Queue
        </Button>

        <Modal
          title={<p className="text-2xl font-bold">Edit your post schedule</p>}
          className="text-xl font-bold"
          width={900}
          open={isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
                Cancel
            </Button>,
            <Button
                key="submit"
                type="primary"
                onClick={this.handleOk}>
                Ok
            </Button>,
          ]}
        >
        <TimeTableComponent
          commonData={this.props.commonData}
          handleTimeChange={(newTime) => {
            this.setState({
                newTime
            })
          }} />
        </Modal>
      </>
    );
  }
}

export default EditQueueModal;
