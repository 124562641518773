import React from 'react';
import { Bar } from '@ant-design/plots';
import { Row, Col, Typography } from 'antd';

const { Text, Title } = Typography;

class BarCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.data || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        chartData: this.props.data,
      });
    }
  }

  render() {
    const { className, title, subtitle } = this.props;
    const { chartData } = this.state;

    return (
      <div className={`${className} rounded-lg bg-white px-4 py-8 shadow-md statistic`}>
        <Row>
          <Col span={18}>
            <Title level={5} className='mb'>{title}</Title>
            <div className='mb-4'>{subtitle}&nbsp;</div>
          </Col>
        </Row>

        <div width="100%">
          <Bar
            data={chartData}
            color={['#b4d5d7', '#5bced4']}
            isGroup={true} 
            legend={false}
            xField='value'
            seriesField='type'
            yField='label'
            appendPadding={10}
            marginRatio={0}
          />
        </div>
      </div>
    );
  }
}

export default BarCard;

