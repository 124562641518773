import React from 'react';
import { message, Input, Switch, Form , Button, Popconfirm, Typography } from 'antd';
import Card from '../Card';
import DashboardLayout from '../Layouts/DashboardLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';

const { Title } = Typography;
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Preferences extends React.Component {

  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale
    ApiHelper.prepHttpCall();

    this.state = { email: props.commonData.currentUserEmail, sendWeeklyDigest: props.commonData.settings.send_weekly_digest, profileDisconnected: props.commonData.settings.send_profile_disconnected_notification };
    console.log('sendWeeklyDigest', props.commonData.send_weekly_digest);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleWeeklyDigestToggle = this.handleWeeklyDigestToggle.bind(this);
    this.handleProfileDisconnectedToggle = this.handleProfileDisconnectedToggle.bind(this);
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleEmailChange() {
    console.log('val', this.state.email);
    let url = '/api/v1/accounts/' + this.props.commonData.currentAccountUid + '/users/' + this.props.commonData.currentUserId + '/';

    let self = this

    axios.patch(url, { email: this.state.email }, ApiHelper.headers())
      .then(function (response) {
        console.log('response', response);
        self.popMessage('Settings updated successfully');
      })
      .catch(function (error) {
        console.log('error', error);
        if (error.response && error.response.status === 422) {
          self.popMessage('We were unable to update your email address. Please try again.');
        } else {
          self.popMessage('We were unable to update your email address. Please try again.');
        }
      });
  }

  handleProfileDisconnectedToggle(checked) {
    let url = '/api/v1/accounts/' + this.props.commonData.currentAccountUid + '/users/' + this.props.commonData.currentUserId + '/';

    let self = this
    axios.patch(url, { send_profile_disconnected_notification: checked }, ApiHelper.headers()).then(function (response) {
      self.setState({ profileDisconnected: checked }, () => {
        self.popMessage('Settings updated successfully');
      });
    });
  }

  handleWeeklyDigestToggle(checked) {
    let url = '/api/v1/accounts/' + this.props.commonData.currentAccountUid + '/users/' + this.props.commonData.currentUserId + '/';

    let self = this
    axios.patch(url, { send_weekly_digest: checked }, ApiHelper.headers()).then(function (response) {
      self.setState({ sendWeeklyDigest: checked }, () => {
        self.popMessage('Settings updated successfully');
      });
    });

  }

  popMessage(str) {
    message.info(str);
  }

  showForm() {

    const billing_url = Helper.accountSettingsUrl(this.props.commonData) + 'billing';

    return (

      <Card>
				<Form layout="vertical">
					<Form.Item
						label="Change your login email address"
						name="email"
						rules={[
							{
								type: 'email',
								message: 'The input is not a valid email!',
							},
							{
								required: true,
								message: 'Please input your email address!',
							}
						]}
					>
						<div style={{ display: 'flex', gap: '10px' }}>
							<Input
								defaultValue={this.state.email}
								onChange={this.onChangeEmail}
								style={{ flexGrow: 1 }}
							/>
							<Popconfirm
								title="Are you sure?"
								description="Please double check that you have entered the correct email address."
								onConfirm={this.handleEmailChange}
								okText="Yes"
								cancelText="No"
							>
								<Button type="primary" htmlType="submit">
									Update email address
								</Button>
							</Popconfirm>
						</div>
					</Form.Item>
				</Form>

        <Form layout="vertical">
          <Form.Item label="Receive weekly analytics email digest">
            <Switch checked={this.state.sendWeeklyDigest} onChange={this.handleWeeklyDigestToggle} />
          </Form.Item>

          <Form.Item label="Receive email notification when your LinkedIn profile needs to be reconnected">
            <Switch checked={this.state.profileDisconnected} onChange={this.handleProfileDisconnectedToggle} />
          </Form.Item>
        </Form>

    </Card>


    );
  }

  render() {
    return <div>{this.showForm()}</div>;
  }
}

export default Preferences;
