import React from 'react';
import Theme from './Theme';
import TopBar from './TopBar';
import { Typography, Layout } from 'antd';
const { Header, Content } = Layout;
const { Title } = Typography;

class SetupLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Theme>
        <Layout>
          <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
            <TopBar commonData={this.props.commonData} />
          </Header>

          <Content>
            <Title style={{ textAlign: 'center' }} level={2} className="page-header">
              {this.props.title}
              <img className="logo-dark" />
            </Title>

            <div style={{ margin: 'auto', textAlign: 'center', width: '500px' }}>
              {this.props.children}
            </div>
          </Content>
        </Layout>
      </Theme>
    );
  }
}

export default SetupLayout;
