import Quill from "quill";

const MentionBlot = Quill.import("blots/mention");

class MacBlot extends MentionBlot {

  static create(data) {
    const node = super.create(data);
    node.setAttribute('data-entity-urn', 'urn:li:fsd_profile:' + data.id);
    node.setAttribute('data-original-text', data.value.trim());
    return node;
  }

}

MacBlot.blotName = "mac";
MacBlot.tagName = "a";
MacBlot.className = "ql-mention";

Quill.register(MacBlot);
export default MacBlot;
