import React from 'react';
import { Empty } from 'antd';

class Card extends React.Component {
	constructor(props) {
		super(props);
	}

	message() {
		if (this.props.message == undefined) {
			return "You're all caught up here!";
		} else {
			return this.props.message;
		}
	}

	render() {
		return (
			<div className="bg-card shadow-[0_0_4px_rgba(0,0,0,0.1)] rounded-lg p-6 mx-auto mb-4">
				<div class="px-4 sm:px-6">
					{this.props.title && (
						<div class="border-b border-gray-201">
							<h3 class="text-lg font-medium leading-6 text-gray-700">{this.props.title}</h3>
							<p class="mt-1 text-sm text-gray-500">{this.props.subtitle}</p>
						</div>
					)}
				</div>
				<div class="px-4">{this.props.children}</div>
			</div>
		);
	}
}

export default Card;
