import React from 'react';
import { Empty } from 'antd';

class EmptyContent extends React.Component {
	constructor(props) {
		super(props);
	}

	message() {
		if (this.props.message == undefined) {
			return "You're all caught up here!";
		} else {
			return this.props.message;
		}
	}

	render() {
		return (
			<Empty
				image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
				style={{ marginTop: '48px', fontSize: '22px', color: '#999' }}
				imageStyle={{
					display: 'inline-block',
					height: 250
				}}
				description={this.message()}
			/>
		);
	}
}

export default EmptyContent;
