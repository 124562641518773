import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import { Input, Tooltip, Card, Image, message, Typography, Button } from 'antd';
import { ArrowDownIcon } from '@heroicons/react/24/outline';
const { Paragraph } = Typography;
const axios = require('axios');

class NoProfile extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    ApiHelper.prepHttpCall();

    this.createCookieCallback();
  }

  createCookieCallback() {
    console.log('call');
    let self = this;
    document.addEventListener('cookieBack', function (e) {
      // console.log('callback', e.detail.li_at);
      console.log('callback -> ', e);
      console.log('callback --> ', e.detail);
      // console.log('mac ---> ', window.mac);
      // console.log('callback ----> ', e.detail['li_at']);
      // console.log('callback -----> ', e.detail["li_at"]);
      self.formRef.current.setFieldsValue({
        liAtCookie: e.detail
        // liAtCookie: e.detail.li_at
      });
      self.setState({ liAtCookie: e.detail });
    });
  }

  onGetCookie() {
    console.log('onGetCookie');
    document.dispatchEvent(new CustomEvent('shiz'));
  }

  handleSubmit(values) {
    let params = {
      linked_in_user: {
        li_at_cookie: values.liAtCookie
      }
    };

    let self = this;
    let url = '/api/v1/li/';

    if (this.state.linkedInUserId != undefined) {
      url += this.state.linkedInUserId;

      axios.put(url, params, ApiHelper.headers()).then(function (response) {
        self.popMessage('Cookie saved');
      });
    } else {
      axios.post(url, params, ApiHelper.headers()).then(function (response) {
        self.popMessage('Cookie saved');
      });
    }
  }

  popMessage(str) {
    message.info(str);
  }

  render() {
    // const onFinish = (values) => {
    //   this.handleSubmit(values);
    // };

    return (
      <DashboardLayout
        className="fixed-width-extra-wide"
        title=""
        commonData={this.props.commonData}>
        <Card
          className="top-card"
          style={{ width: '500px', margin: 'auto', textAlign: 'center', padding: '24px 12px' }}>
          <Paragraph style={{ fontSize: '16px', marginBottom: '8px' }}>
            You're just a step away from awesomeness.
          </Paragraph>
          <Paragraph style={{ fontSize: '24px', marginBottom: '8px' }}>
            We need access to your LinkedIn profile to continue.
          </Paragraph>
          <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
            Install the <b>Aware</b> browser extension
          </Paragraph>
          <Paragraph style={{ fontSize: '14px', marginBottom: '8px' }}>Click here!</Paragraph>

          <div>
            <ArrowDownIcon width={18} className="bounce" />
          </div>

          <Button
            style={{
              color: '#fff',
              fontWeight: 'bold',
              backgroundColor: 'hsl(185, 57%, 50%)',
              padding: '12px 24px',
              height: '100%'
            }}
            href="">
            <Image width={40} src="../images/chrome_128x128.png" />
            <span style={{ marginLeft: '16px' }}>Add to Chrome</span>
          </Button>

          <Input.Group compact style={{ marginTop: '60px' }}>
            <Input
              style={{ color: '#666', width: 'calc(100% - 80px)' }}
              disabled
              defaultValue={this.props.commonData.licenseKey}
            />
            <Tooltip title="Copy license key to clipboard">
              <Button
                onClick={() => {
                  console.log('click');
                  navigator.clipboard.writeText(this.props.commonData.licenseKey);
                }}>
                Copy
              </Button>
            </Tooltip>
          </Input.Group>
        </Card>
      </DashboardLayout>
    );
  }
}

export default NoProfile;
