import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Notifications from '../Notifications/Notifications';
import Mappings from './Mappings';
import Advanced from './Advanced';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import { Card, Popconfirm, Row, Col, Button, Typography } from 'antd';
import _ from 'underscore';

const { Paragraph } = Typography;
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class Salesforce extends React.Component {
	constructor(props) {
		super(props);
    locale.locale = props.commonData.locale
		this.state = { isLoaded: false };
		ApiHelper.prepHttpCall();
		this.loadComponent();
	}

	loadComponent() {
		let url =
			'/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/salesforce/connect';

		let self = this;
		axios.get(url, ApiHelper.headers()).then(function (response) {
    console.log('sf', response.data.sfdcConnection.encrypted_access_token)
			self.setState(
				{
					isLoading: false,
					sfdcConnection: response.data.sfdcConnection
				},
				() => {
					self.setState({ isLoaded: true });
				}
			);
		});
	}

	lastRunAt() {
		let sfdcConnection = this.state.sfdcConnection;
		if (!sfdcConnection || sfdcConnection.last_run_at == null) {
			return;
		}
		var options = { year: 'numeric', month: 'short', day: 'numeric' };
		var date = new Date(sfdcConnection.last_run_at).toLocaleDateString('en-US', options);
		let s = <Paragraph>{'Last run on: ' + date}</Paragraph>;
		return s;
	}

	nextStep() {
		window.location.href = Helper.accountSettingsUrl(this.props.commonData) + 'notifications';
	}

	adminView() {
		let sfdcConnection = this.state.sfdcConnection;
		let r;

		if (!sfdcConnection || sfdcConnection.encrypted_access_token == null) {
			let url =
				'/api/v1/accounts/' +
				this.props.commonData.currentAccountUniqueToken +
				'/salesforce/connect/new';

			r = (
				<Button type="primary" onClick={() => (window.location.href = url)}>
          { locale.t("sfdc.authorize_salesforce_btn")} {' '}
				</Button>
			);
		} else {
			let name = sfdcConnection.sfdc_oauth_display_name;
			let el = (
				<>
          { locale.t("sfdc.connect_to_account_msg_start")} {' '}
					<b>
						<i>{name}</i>
					</b>{' '}
          { locale.t("sfdc.connect_to_account_msg_end") }
				</>
			);
			r = (
				<>
					<Card title={el} style={{ marginBottom: '16px' }}>
            <Popconfirm
              title={ locale.t("sfdc.disconnect_title") }
              onConfirm={() => this.disconnect()}>
              <div style={{ clear: 'both', marginBottom: '48px', marginTop: '36px' }}>
                <Button
                  type="link"
                  danger
                  size="large"
                  style={{ float: 'right' }}>
                  { locale.t("sfdc.disconnect_btn") }
                </Button>
              </div>
            </Popconfirm>
          </Card>

					{this.lastRunAt()}

{/* 					<div style={{ marginBottom: '48px', marginTop: '36px' }}> */}
{/* 						<Button */}
{/* 							style={{ float: 'right' }} */}
{/* 							onClick={() => this.nextStep()} */}
{/* 							size="large" */}
{/* 							type="primary" */}
{/* 							htmlType="submit"> */}
{/* 							{this.state.onboardingComplete ? 'Save' : 'Continue'} */}
{/* 						</Button> */}
{/* 					</div> */}

				</>
			);
		}

		return r;
	}

	disconnect() {
		let self = this;
		let url =
			'/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/salesforce/connect';

		axios.delete(url, ApiHelper.headers()).then(function (response) {
			self.setState({ sfdcConnection: null });
		});
	}


	render() {
		return (
			<>
				<DashboardLayout
					className="fixed-width-extra-wide"
          title={ locale.t("sfdc.oauth_title") }
					commonData={this.props.commonData}>
					<Row>
						<Col span={24}>
							<span className={`${this.state.isLoading ? 'hidden' : ''}`}>
								{this.adminView()}
							</span>
						</Col>
					</Row>

          {this.state.sfdcConnection != null && this.state.sfdcConnection.encrypted_access_token != null && (
            <>
              <Notifications commonData={this.props.commonData} crn='salesforce' />
              <Mappings commonData={this.props.commonData} />
              <Advanced commonData={this.props.commonData} />
            </>
          )}

				</DashboardLayout>
			</>
		);
	}
}

export default Salesforce;
