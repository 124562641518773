const axios = require('axios');

export function headers() {
  return {
    headers: {
      accept: 'application/json',
      Authorization: 'Bearer a29868cd-2767-48ea-96c8-4f0238db57ad'
    }
  };
}

export function prepHttpCall() {
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
}
