import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { I18n } from 'i18n-js';
import translations from "/app/frontend/translations.json";
import * as ApiHelper from '../Helpers/ApiHelpers';
import { message } from "antd";

dayjs.extend(utc);
dayjs.extend(timezone);

const locale = new I18n(translations);

export const EditorThemeContext = React.createContext();

const deviceConfig = {
  desktop: 540,
  tablet: 493,
  mobile: 399,
};

export function EditorThemeProvider({ children, commonData }) {
  const userTimeZone = commonData?.timezone ?? "UTC";
  const todayFormatted = dayjs().tz(userTimeZone).format('YYYY-MM-DD');

  const [todaySlotSkipped, setTodaySlotSkipped] = useState(0);
  const [mode, setMode] = useState("publish");
  const [theme, setTheme] = useState('light');
  const [device, setDevice] = useState("desktop");

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPost, setSelectedPost] = useState({});

  const [postId, setPostId] = useState(null);
  const [editorText, setEditorText] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  const [editorAttachment, setEditorPostAttachment] = useState(null);

  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [calendarDates, setCalendarDates] = useState([]);
  const [isLoadingPostEditor, setIsLoadingPostEditor] = useState(false);
  const [isLoadingSlots, setIsLoadingSlots] = useState(true);

  const [slotContent, setSlotContent] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [queueBtnSubtitle, setQueueBtnSubtitle] = useState("");
  
  const [postsData, setPostsData] = useState([]);
  const [isPostsDataLoading, setIsPostsDataLoading] = useState(false);

  const [queueSlotsData, setQueueSlotsData] = useState([]);
  const [selectedQueueSlotTime, setSelectedQueueSlotTime] = useState(null);
  const [isSlotDrawerOpen, setIsSlotDrawerOpen] = useState(false);
  const [isSchedulingPostNow, setIsSchedulingPostNow] = useState(true);
  const [scheduleBtnText, setScheduleBtnText] = useState("Add to Queue");
  const [firstSlotContent, setFirstSlotContent] = useState(null);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(-1);

  const deviceWidth = window.innerWidth < deviceConfig[device] ? `${window.innerWidth - 20}px` : `${deviceConfig[device]}px`;

  const hasAvailableSlots = timeSlotsData.some(slot => {
    return slot.Mon || slot.Tue || slot.Wed || slot.Thur || slot.Fri || slot.Sat || slot.Sun;
  });

  const generateDates = (qSlots = []) => {
    let xTodaySlotSkipped = 0;
    const today = dayjs().tz(userTimeZone).startOf('day'); // Start of the day in user's timezone
    const now = dayjs().tz(userTimeZone); // Current time in user's timezone
    const nextYear = today.add(1, "year");
    const weekMap = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: []
    };

    for (const s of qSlots) {
      s.days?.split(",").forEach(d => {
        if (!weekMap[d]) weekMap[d] = [];
        weekMap[d].push({
          id: s.id,
          time: s.time,
          timeFormatted: dayjs.unix(s.time).tz(userTimeZone).format('hh:mm a'),
          isSlot: true
        });
      });
    }

    const dateList = [];
    const initSlotContent = {};
    let currentDate = today;

    while (currentDate.isBefore(nextYear)) {
      const dayOfWeekInN = currentDate.day();
      const dayOfWeekNormalized = dayOfWeekInN === 0 ? 7 : dayOfWeekInN;
      let timeSlots = weekMap[dayOfWeekNormalized];

      if (timeSlots?.length > 0) {
        let timeSlotForDay = null;
        if (currentDate.isAfter(today)) {
          timeSlotForDay = timeSlots;
        } else {
          // Logic for Today's slots
          const timeFormat = 'HH:mm:ss';
          const todayFormattedUTC = dayjs().utc().format(timeFormat);
          const todayTimeSlots = weekMap[dayOfWeekNormalized].filter(slot => {
            // const timeFormattedInTimezoneUTC = dayjs.unix(slot.time).utc().substract(1,'hour').format(timeFormat);
            const timeFormattedInTimezoneUTC = dayjs.unix(slot.time).utc().format(timeFormat);
            return timeFormattedInTimezoneUTC > todayFormattedUTC;
          });

          xTodaySlotSkipped = timeSlots.length - todayTimeSlots.length;
          setTodaySlotSkipped(xTodaySlotSkipped);
          timeSlotForDay = todayTimeSlots;
        }

        // Sort timeslot in ascending order
        timeSlotForDay.sort((a, b) => a.time - b.time);

        const dateKey = currentDate.format("YYYY-MM-DD");
        dateList.push({
          date: currentDate.format("dddd | DD MMMM YYYY"),
          dateKey,
          originalDate: currentDate,
          dayOfWeek: currentDate.format("E"),
          timeSlots: timeSlotForDay,
        });

        if (timeSlotForDay.length > 0) {
          initSlotContent[dateKey] = timeSlotForDay.map(slot => ({ slot }));
        }
      }
      currentDate = currentDate.add(1, "day");
    }

    const scheduledPosts = postsData?.filter(post =>
      post.post_status === "scheduled" &&
      dayjs(post.scheduled_at).isAfter(now)
    );

    for (const sPost of scheduledPosts) {
      const dateScheduled = dayjs(sPost.scheduled_at).tz(userTimeZone);
      const dateKey = dateScheduled.format("YYYY-MM-DD");
      const timeSlot = dateScheduled.format("HH:mm");

      sPost.timeFormatted = dayjs(sPost.scheduled_at).tz(userTimeZone).format('hh:mm a');

      const slotEntry = qSlots
        .filter(sEntries => sEntries.days.includes(String(dateScheduled.day() === 0 ? 7 : dateScheduled.day())))
        .find(x => timeSlot === dayjs.unix(x.time).tz(userTimeZone).format('HH:mm'));

      if (!initSlotContent[dateKey]) initSlotContent[dateKey] = [];
      
      if (slotEntry) {
        const slotIndex = initSlotContent[dateKey].findIndex(entry => entry.slot && entry.slot.id === slotEntry.id);
        if (slotIndex !== -1) {
          initSlotContent[dateKey][slotIndex] = { slot: sPost };
        } else {
          initSlotContent[dateKey].push({ slot: sPost });
        }
      } else {
        initSlotContent[dateKey].push({ slot: sPost });
      }
    }

    for (const dateKey in initSlotContent) {
      initSlotContent[dateKey].sort((a, b) => {
        const timeA = a.slot.time ? a.slot.time : dayjs(a.slot.scheduled_at).unix();
        const timeB = b.slot.time ? b.slot.time : dayjs(b.slot.scheduled_at).unix();
        return timeA - timeB;
      });

      if (initSlotContent[dateKey].length === 0) {
        delete initSlotContent[dateKey];
      }
    }

    const sortedSlotContent = Object.keys(initSlotContent)
      .sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
      .reduce((acc, key) => {
        acc[key] = initSlotContent[key];
        return acc;
      }, {});

    // console.log('initSlotContent', sortedSlotContent);

    // console.log('props', commonData);
    setIsLoadingSlots(false);
    setSlotContent(sortedSlotContent);
    findNextAvailableSlot(dateList, sortedSlotContent, xTodaySlotSkipped);
  };

  const findNextAvailableSlot = (calendarDates, slotContent, skipTodayIndex) => {

    for (const date of calendarDates) {
      const dateKey = dayjs(date.originalDate).tz(userTimeZone).format("YYYY-MM-DD");

      if (slotContent[dateKey]) {
        for (let slotIndex = 0; slotIndex < slotContent[dateKey].length; slotIndex++) {
          const entry = slotContent[dateKey][slotIndex];
          // console.log('entry', entry, entry.slot.isSlot);

          if (entry.slot.isSlot) {
            // console.log( '==========================' )
            const nextAvailableSlot = {
                date: date.date,
                originalDate: date.originalDate,
                slotIndex,
                slotTime: entry.slot.time,
                dateKey
            }
            // console.log({ nextAvailableSlot, todaySlotSkipped })
            setFirstSlotContent(nextAvailableSlot);
            return
          }
        }
      }
    }

    // If no open slot is found, return null
    return null;
  };

  const [messageApi, contextHolder] = message.useMessage();
  const showToastNotification = (message, type = 'success', duration = 4) => {
    messageApi.open({
      type,
      content: message,
      duration,
    });
  };

  const handleDrawerClose = () => {
    setIsSlotDrawerOpen(false);
    setSelectedPost(null);
    setEditorPostAttachment(null)
    setEditorHtml('');
  }

  const createOrUpdatePostHandler = async (
    post_status = 'DRAFT',
    scheduledAt = null,
    postId = null,
    isEditingPost = false
  ) => {
    ApiHelper.prepHttpCall();
    const url = `/api/v1/posts/create`;

    setIsLoadingPostEditor(true);
    const formData = new FormData();
    if (editorAttachment?.file) {
      formData.append('image', editorAttachment?.file);
    } else if (selectedPost?.image_url && editorAttachment?.renderUrl) {
      formData.append('image', selectedPost.image_url);
    }
    formData.append('post_status', post_status ?? "SCHEDULED");
    formData.append('post_text', editorText);
    formData.append('post_text_html', editorHtml);

    if (scheduledAt) formData.append('post_scheduled_at', dayjs(scheduledAt).utc().format());
    const post_id = selectedPost?.id ?? postId ?? "";

    // All slots have an id, so the presence of a linked_in_user_id means it's an existing post we're updating
    const isExistingPost = selectedPost?.linked_in_user_id;
    if (isExistingPost) formData.append('post_id', post_id);

    if (isEditingPost) {
      formData.append('edited_scheduled_at', dayjs(scheduledAt).utc().format());
    }
    console.log('commonDate?.linkedInUserId', commonData?.actAsLinkedInUserId);
    const postPayload = {
      post_status: post_status?.toLowerCase(),
      post_text: editorText,
      post_text_html: editorHtml,
      scheduled_at: scheduledAt,
      linked_in_user_id: commonData?.actAsLinkedInUserId,
      is_visible_to_connection_only: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };

    if (!editorText || !editorHtml) {
      return showToastNotification("Post cannot be empty", 'error', 5);
    }

    setIsPostsDataLoading(true);
    axios.post(url, formData, {
      headers: {
        ...ApiHelper.headers(),
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      const updatedPost = res?.data?.data
      const uploadedPostId = updatedPost?.id;
      console.log('uploadedPostId', uploadedPostId);

      let successMessage = 'success';
      if (post_status === 'DRAFT') successMessage = locale.t("post_scheduling.post_saved_successfully");
      if (post_status === 'SCHEDULED') successMessage = locale.t("post_scheduling.post_scheduled_successfully");
      if (post_status === 'PUBLISHED') successMessage = locale.t("post_scheduling.post_available_soon");

      let latestPostsData = [...postsData];
      if (isExistingPost) {
        // Of course now the post should be saved, but this is refering to if the post existing prior to this method being called
        const postIndex = latestPostsData.findIndex(p => p.id === postId);
        latestPostsData[postIndex] = updatedPost;
      } else {
        latestPostsData.push(updatedPost);
      }
      console.log('latestPostsData', latestPostsData);
      setPostsData(latestPostsData);
      showToastNotification(successMessage, 'success', 4);
      setEditorHtml("");
      setEditorText("");

    }).catch((err) => {
      showToastNotification(err?.response?.data, 'error', 5);
    }).finally(() => {
      setIsPostsDataLoading(false);
      setIsLoadingPostEditor(false);
      setScheduleBtnText("Scheduled");
      handleDrawerClose()

      if (isEditingPost) {
        window.location.href = Helper.profileSettingsUrl(commonData) + 'queue/';
      }
    });
  };

  const deletePostHandler = async (postId, cb = null) => {
    ApiHelper.prepHttpCall();
    const postDetails = postsData?.find(p => p.post_id === postId);

    const url = `/api/v1/posts/delete`;
    const payload = {
      post_id: postId ?? postDetails?.id,
    };
    showToastNotification("Please wait...", 'loading', 2);
    await axios.post(url, payload, ApiHelper.headers())
      .then((res) => {
        handleDrawerClose()
        setPostsData(prevPosts => prevPosts.filter(p => p.id !== postId));
        setSelectedPost({});
        showToastNotification("Post deleted successfully!", 'success', 3);
      })
      .catch((err) => {
        showToastNotification(err?.message, 'error', 4);
      })
      .finally(() => {
        if (cb) cb();
      });

    return true;
  };

  const fetchTimeSlotsHandler = async () => {
    try {
      const response = await axios.get("/api/v1/scheduled_post_slot/get");
      const queueSlots = response.data?.data || [];
      const timeFormat = "hh:mm A";
      const formattedData = queueSlots.map((item) => {
        const daysArray = item.days.split(",").map((day) => parseInt(day));
        const timeRow = {
          id: item.id,
          key: item.id,
          unixTime: item.time,
          time: dayjs.unix(item.time).tz(userTimeZone).format(timeFormat),
          Mon: daysArray.includes(1),
          Tue: daysArray.includes(2),
          Wed: daysArray.includes(3),
          Thur: daysArray.includes(4),
          Fri: daysArray.includes(5),
          Sat: daysArray.includes(6),
          Sun: daysArray.includes(7),
        };
        return timeRow;
      });

      formattedData.sort((a, b) => {
        const timeA = dayjs(a.time, timeFormat);
        const timeB = dayjs(b.time, timeFormat);
        return timeA.isAfter(timeB) ? 1 : -1;
      });

      setQueueSlotsData(queueSlots);
      setTimeSlotsData(formattedData);
      return queueSlots;
    } catch (err) {
      showToastNotification('Something Went Wrong', 'error', 4);
    }
  };

  const addToFirstAvailableSlot = () => {
    const time = firstSlotContent?.slotTime;
    const timeSlot = dayjs.unix(time).tz(userTimeZone).format("hh:mm a");
    const slotLabel = `${dayjs(firstSlotContent?.originalDate).format("MMMM DD YYYY")} - ${timeSlot}`;
    // console.log('addToFirstAvailableSlot', firstSlotContent);
    setIsSchedulingPostNow(false);
    setScheduleBtnText("Add to Queue");
    setSelectedTimeSlot(timeSlot);
    setSelectedSlotIndex(firstSlotContent?.slotIndex);
    setSelectedDate(firstSlotContent?.originalDate);
    setQueueBtnSubtitle(slotLabel);
  };

  const openPostDrawer = (originalDate, timeSlot, slotIndex) => {
    // console.log('openPostDrawer', originalDate, timeSlot, slotIndex);
    // const timeSlot = dayjs(unixTime).tz(userTimeZone).format("hh:mm a");
    // console.log('slotContent', slotContent);
    // console.log('timeSlot', timeSlot);
    const dateKey = dayjs(originalDate).format('YYYY-MM-DD');
    const slotPost = slotContent[dateKey][slotIndex].slot;
    const slotText = slotPost?.post_text || "";
    const slotLabel = `${dayjs(originalDate).format("MMMM DD YYYY")} - ${timeSlot}`;
    const sDatetime = dayjs.tz(`${dateKey} ${timeSlot}`, 'YYYY-MM-DD hh:mm a', userTimeZone);

    setIsSlotDrawerOpen(true);
    setSelectedTimeSlot(timeSlot);
    setSelectedSlotIndex(slotIndex);
    setSelectedDate(sDatetime);

    if (slotPost) setSelectedPost(slotPost);
    else setSelectedPost({});

    setIsSchedulingPostNow(false);
    setEditorText(slotText);
    setQueueBtnSubtitle(slotLabel);
    setScheduleBtnText("Add to Queue");
    return slotText;
  };

  useEffect(() => {
    if (userTimeZone) dayjs.tz.setDefault(userTimeZone);
  }, [userTimeZone]);

  useEffect(() => {
    if (!selectedDate) return;
    console.log('Selected Date is Updated', selectedDate);
    console.log('Selected Date in Default Timezone', dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ[Z]'));
    console.log('Selected Date in UTC Timezone', dayjs(selectedDate).utc().format('YYYY-MM-DDTHH:mm:ssZ[Z]'));
    console.log(`Selected Date in User Timezone: ${userTimeZone}`, dayjs(selectedDate).tz(userTimeZone).format('YYYY-MM-DDTHH:mm:ssZ[Z]'));
    console.log('Selected Time', dayjs(selectedDate).format('HH:mm:ss'));
  }, [selectedDate]);

  useEffect(() => {
    // console.log('useEffect 2: Selected Post Updated', selectedPost);
    if (selectedPost?.isSlot == false) {
      const date = dayjs(selectedPost?.scheduled_at).tz(userTimeZone);
      const time = dayjs(selectedPost?.scheduled_at).tz(userTimeZone).format("hh:mm a");
      const slotLabel = selectedPost.post_status === "scheduled" ? `${dayjs(date, "dddd | DD MMMM YYYY").format("MMMM DD YYYY")} - ${time}` : '';
      setScheduleBtnText(`${selectedPost.post_status === "draft" ? "Save Draft" : "Update Post."}`);
      setQueueBtnSubtitle(slotLabel);
    }
    if (selectedPost?.image_url) setEditorPostAttachment({
      renderUrl: selectedPost.image_url,
    });
    if (selectedPost?.post_text_html) {
      setEditorHtml(selectedPost?.post_text_html);
    } else {
      setEditorHtml('');
    }
  }, [selectedPost]);

  useEffect(() => {
    if (commonData?.locale) {
      locale.locale = commonData.locale;
    }
  }, [commonData?.locale]);

  return (
    <EditorThemeContext.Provider value={{
      todayFormatted,
      todaySlotSkipped,
      commonData,
      userTimeZone,

      mode, setMode,
      theme, setTheme,
      device, setDevice,

      postId, setPostId,
      editorHtml, setEditorHtml,
      editorText, setEditorText,
      editorAttachment, setEditorPostAttachment,

      selectedPost, setSelectedPost,
      calendarDates, setCalendarDates,
      isLoadingPostEditor,
      isLoadingSlots, setIsLoadingSlots,
      slotContent, setSlotContent,
      selectedDate, setSelectedDate,
      selectedTimeSlot, setSelectedTimeSlot,
      queueBtnSubtitle, setQueueBtnSubtitle,
      postsData, setPostsData,
      isPostsDataLoading, setIsPostsDataLoading,
      queueSlotsData, setQueueSlotsData,
      selectedQueueSlotTime, setSelectedQueueSlotTime,
      isSlotDrawerOpen, setIsSlotDrawerOpen,
      isSchedulingPostNow, setIsSchedulingPostNow,
      scheduleBtnText, setScheduleBtnText,
      firstSlotContent, setFirstSlotContent,
      selectedSlotIndex, setSelectedSlotIndex,

      createOrUpdatePostHandler,
      deletePostHandler,

      timeSlotsData,
      setTimeSlotsData,

      fetchTimeSlotsHandler,
      handleDrawerClose,

      generateDates,
      findNextAvailableSlot,
      addToFirstAvailableSlot,

      openPostDrawer,
      showToastNotification, contextHolder,

      dayjs,

      locale,
      hasAvailableSlots
    }}>
      {children}
    </EditorThemeContext.Provider>
  );
}

