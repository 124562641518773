import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Notifications from '../Notifications/Notifications';
import Mappings from './Mappings';
import Advanced from './Advanced';
import * as ApiHelper from '../Helpers/ApiHelpers';
import * as Helper from '../Helpers/Helpers';
import { Card, Popconfirm, Row, Col, Button, Typography } from 'antd';
import _ from 'underscore';

const { Paragraph } = Typography;
const axios = require('axios');

class Hubspot extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isLoaded: false };
		ApiHelper.prepHttpCall();
		this.loadComponent();
	}

	loadComponent() {
		let url =
			'/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/hubspot/connect';

		let self = this;
		axios.get(url, ApiHelper.headers()).then(function (response) {
    console.log('sf', response.data.hubspotConnection.encrypted_access_token)
			self.setState(
				{
					isLoading: false,
					hubspotConnection: response.data.hubspotConnection
				},
				() => {
					self.setState({ isLoaded: true });
				}
			);
		});
	}

	lastRunAt() {
		let hubspotConnection = this.state.hubspotConnection;
		if (!hubspotConnection || hubspotConnection.last_run_at == null) {
			return;
		}
		var options = { year: 'numeric', month: 'short', day: 'numeric' };
		var date = new Date(hubspotConnection.last_run_at).toLocaleDateString('en-US', options);
		let s = <Paragraph>{'Last run on: ' + date}</Paragraph>;
		return s;
	}

	// nextStep() {
	// 	window.location.href = Helper.accountSettingsUrl(this.props.commonData) + 'notifications';
	// }

	adminView() {
		let hubspotConnection = this.state.hubspotConnection;
		let r;

		if (!hubspotConnection || hubspotConnection.encrypted_access_token == null) {
			let url =
				'/api/v1/accounts/' +
				this.props.commonData.currentAccountUniqueToken +
				'/hubspot/connect/new';

			r = (
				<Button type="primary" onClick={() => (window.location.href = url)}>
					Authorize Hubspot
				</Button>
			);
		} else {
			let name = hubspotConnection.hubspot_oauth_display_name;
			let el = (
				<>
					You are currently connected to the{' '}
					<b>
						<i>{name}</i>
					</b>{' '}
					Hubspot account.
				</>
			);
			r = (
				<>
					<Card title={el} style={{ marginBottom: '16px' }}>
            <Popconfirm
              title="Are you sure you want to disconnect this Hubspot account?"
              onConfirm={() => this.disconnect()}>
              <div style={{ clear: 'both', marginBottom: '48px', marginTop: '36px' }}>
                <Button
                  type="link"
                  danger
                  size="large"
                  style={{ float: 'right' }}>
                  Disconnect Hubspot
                </Button>
              </div>
            </Popconfirm>
          </Card>

					{this.lastRunAt()}

{/* 					<div style={{ marginBottom: '48px', marginTop: '36px' }}> */}
{/* 						<Button */}
{/* 							style={{ float: 'right' }} */}
{/* 							onClick={() => this.nextStep()} */}
{/* 							size="large" */}
{/* 							type="primary" */}
{/* 							htmlType="submit"> */}
{/* 							{this.state.onboardingComplete ? 'Save' : 'Continue'} */}
{/* 						</Button> */}
{/* 					</div> */}

				</>
			);
		}

		return r;
	}

	disconnect() {
		let self = this;
		let url =
			'/api/v1/accounts/' + this.props.commonData.currentAccountUniqueToken + '/hubspot/connect';

		axios.delete(url, ApiHelper.headers()).then(function (response) {
			self.setState({ hubspotConnection: null });
		});
	}


	render() {
		return (
			<>
				<DashboardLayout
					className="fixed-width-extra-wide"
					title="Authenticate Hubspot for your organization"
					commonData={this.props.commonData}>
					<Row>
						<Col span={24}>
							<span className={`${this.state.isLoading ? 'hidden' : ''}`}>
								{this.adminView()}
							</span>
						</Col>
					</Row>

          {this.state.hubspotConnection != null && this.state.hubspotConnection.encrypted_access_token != null && (
            <>
              <Notifications commonData={this.props.commonData} crm='hubspot' />
              <Mappings commonData={this.props.commonData} />
              <Advanced commonData={this.props.commonData} />
            </>
          )}

				</DashboardLayout>
			</>
		);
	}
}

export default Hubspot;
