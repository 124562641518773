import React from 'react';
import Queues from './Queues';
import { I18n } from 'i18n-js';
import translations from "/app/frontend/translations.json"
const locale = new I18n(translations)

class Queue extends React.Component {
  constructor(props) {
    locale.locale = props.commonData.locale
    super(props)
  }
  render() {
    return (
      <>
        <Queues
          commonData={this.props.commonData}
          title={this.props.pageName}
          subtitle="Write and schedule your posts"
          postData={this.props.postData || []}
        ></Queues>
      </>
    )
  }
}

export default Queue;
