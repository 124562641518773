import React, { useState, useEffect, useContext } from "react";
import { Table, Checkbox, Button, TimePicker, Popconfirm } from "antd";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EditorThemeContext } from "../../Posts/EditorContext";

const TimeTableComponent = (props) => {
    const [newTime, setNewTime] = useState(null);
    const [timePickerValue, setTimePickerValue] = useState(null);

    const {
        dayjs,
        userTimeZone,
        showToastNotification,
        timeSlotsData, setTimeSlotsData,
        fetchTimeSlotsHandler,
        queueSlotsData, setQueueSlotsData,
        selectedQueueSlotTime, setSelectedQueueSlotTime,
    } = useContext(EditorThemeContext);

    useEffect(() => {
        fetchTimeSlotsHandler();
    }, []);

    const handleCheckboxChange = async (time, day) => {
        setTimeSlotsData((prevTimeData) =>
            prevTimeData.map((row) => {
                if (row.time === time) {
                    const updatedRow = { ...row, [day]: !row[day] };
                    const checkedDays = Object.keys(updatedRow)
                        .filter((key) => key !== "time" && updatedRow[key])
                        .map((key) => {
                            switch (key) {
                                case "Mon":
                                    return 1;
                                case "Tue":
                                    return 2;
                                case "Wed":
                                    return 3;
                                case "Thur":
                                    return 4;
                                case "Fri":
                                    return 5;
                                case "Sat":
                                    return 6;
                                case "Sun":
                                    return 7;
                                default:
                                    return null;
                            }
                        })
                        .sort()
                        .join(",");
                    if (row.id) {
                        saveUpdatedSlotData(row.id, checkedDays);
                    } else {
                        saveTimeData(checkedDays.split(","));
                    }
                    setQueueSlotsData([...queueSlotsData, true]);
                    return updatedRow;
                }
                return row;
            })
        );
    };

    const saveUpdatedSlotData = async (id, updatedCheckedDays) => {
        try {
            const updateSlotPayload = {
                id: id,
                days: updatedCheckedDays
            };
            const response = await axios.post("/api/v1/scheduled_post_slot/update", updateSlotPayload);
            if (response) {
                console.log("Saved!");
            } else {
                console.log("Not Saved!");
            }
        } catch (error) {
            console.error("Error saving updated slot data:", error);
        }
    };

    const handleAddTimeSlotRow = () => {
        if (!newTime) return;
        if (timeSlotsData.some((row) => row.time === newTime)) {
            showToastNotification("Time slot already exists", "error", 3);
            return;
        }
        const newRow = {
            time: newTime,
            Mon: false,
            Tue: false,
            Wed: false,
            Thur: false,
            Fri: false,
            Sat: false,
            Sun: false,
        };

        props?.handleTimeChange({ newTime });

        setTimeSlotsData((prevTimeData) => [...prevTimeData, newRow]);
        setNewTime(null);
        setTimePickerValue(null);
    };

    const saveTimeData = async (selectedDays = [1, 2, 3, 4, 5, 6, 7]) => {
        const newTime = selectedQueueSlotTime
        try {
            const payload = {
                time: dayjs.tz(`1970-01-02 ${newTime}`, "YYYY-MM-DD hh:mm A", userTimeZone).unix(),
                days: selectedDays.join(","),
            };
            console.log({ payload, newTime });
            await axios.post("/api/v1/scheduled_post_slot/create", payload);
            await fetchTimeSlotsHandler();
            showToastNotification("Slot added!", "success", 3);
        } catch (error) {
            console.log({ error })
            showToastNotification("Uh-oh, could not add slot, please try again!", "error", 3);
        }
    };

    const handleDeleteRow = async (id) => {
        try {
            await axios.post(`/api/v1/scheduled_post_slot/delete`, { id });
            const updatedTimeData = timeSlotsData.filter((row) => row.id !== id);
            setTimeSlotsData(updatedTimeData);
            console.log("Row deleted successfully!");
            showToastNotification("Slot Deleted!", "success");
            setQueueSlotsData([...queueSlotsData, true]);
        } catch (error) {
            console.error("Error deleting row:");
            console.error("Failed to delete row. Please try again later.");
        }
    };

    const columns = [
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            width: 150,
            align: "center",
            render: (text, record) => (
                <span className="flex text-sm items-center space-x-4 w-full">
                    <span style={{ paddingRight: "5px" }}>{text}</span>
                    <Popconfirm
                        title="Are you sure you want to delete this row?"
                        onConfirm={() => handleDeleteRow(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <XMarkIcon width="15px" color="black" className="cursor-pointer" />
                    </Popconfirm>
                </span>
            )
        },
        {
            title: "Mon",
            dataIndex: "Mon",
            key: "Mon",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    className="justify-self-center"
                    onChange={() => handleCheckboxChange(record.time, "Mon")}
                />
            ),
            align: "center"
        },
        {
            title: "Tue",
            dataIndex: "Tue",
            key: "Tue",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Tue")}
                />
            ),
            align: "center"
        },
        {
            title: "Wed",
            dataIndex: "Wed",
            key: "Wed",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Wed")}
                />
            ),
            align: "center"
        },
        {
            title: "Thur",
            dataIndex: "Thur",
            key: "Thur",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Thur")}
                />
            ),
            align: "center"
        },
        {
            title: "Fri",
            dataIndex: "Fri",
            key: "Fri",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Fri")}
                />
            ),
            align: "center"
        },
        {
            title: "Sat",
            dataIndex: "Sat",
            key: "Sat",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Sat")}
                />
            ),
            align: "center"
        },
        {
            title: "Sun",
            dataIndex: "Sun",
            key: "Sun",
            render: (text, record) => (
                <Checkbox
                    checked={text}
                    onChange={() => handleCheckboxChange(record.time, "Sun")}
                />
            ),
            align: "center"
        },
    ];

    return (
        <div>
            <div className="flex justify-between">
                <div className="flex gap-2">
                    <TimePicker
                        value={timePickerValue}
                        onChange={(time, timeString) => {
                            if (time && timeString) {
                                setNewTime(timeString);
                                setSelectedQueueSlotTime(timeString)
                                setTimePickerValue(dayjs(dayjs(timeString, "hh:mm A").toDate()));
                            }
                        }}
                        format="hh:mm A"
                        style={{ marginBottom: "1rem" }}
                        minuteStep={5}
                    />
                    <Button onClick={handleAddTimeSlotRow} style={{ marginBottom: "1rem" }}>
                        Add Slot
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                rowClassName={"center"}
                dataSource={timeSlotsData}
                pagination={false}
                size="middle"
                scroll={{ x: "max-content" }}
            />
            <div className="flex justify-center items-center mt-4">
                <p className="text-center underline font-medium text-sm">All times are in: <strong>{userTimeZone}</strong></p>
            </div>
        </div>
    );
};

export default TimeTableComponent;
