import React, { useState } from 'react';
import axios from 'axios';

export default function AiPrompts(props) {
  const [prompts, setPrompts] = useState(props.prompts || []);
  const [newPrompt, setNewPrompt] = useState({ model: '', name: '', text: '', temperature: '0.5' });
  const [error, setError] = useState('');
  const [axiosError, setAxiosError] = useState('');
  const [editIndex, setEditIndex] = useState(null); // Track which prompt is being edited

  const handleAddPrompt = () => {
    if (!newPrompt.model || !newPrompt.name || !newPrompt.text) {
      setError('All fields are required.');
      return;
    }

    setError('');
    axios.post('/api/v1/admin/prompts', newPrompt)
      .then(response => {
        setPrompts([...prompts, response.data]);
        setNewPrompt({ model: '', name: '', text: '', temperature: '0.5' });
        setAxiosError('');  // Clear any previous error
      })
      .catch(error => setAxiosError(error.response?.data?.message || 'There was an error adding the prompt!'));
  };

  const handleDeletePrompt = (id) => {
    axios.delete(`/api/v1/admin/prompts/${id}`)
      .then(() => {
        const updatedPrompts = prompts.filter(prompt => prompt.id !== id);
        setPrompts(updatedPrompts);
        setAxiosError('');  // Clear any previous error
      })
      .catch(error => setAxiosError(error.response?.data?.message || 'There was an error deleting the prompt!'));
  };

  const handleEditPrompt = (index, field, value) => {
    setPrompts(prevPrompts => {
      const newPrompts = [...prevPrompts];
      newPrompts[index] = { ...newPrompts[index], [field]: value };
      return newPrompts;
    });
    setEditIndex(index); // Track which prompt is being edited
  };

  const handleSavePrompt = (index) => {
    const promptToSave = prompts[index];

    axios.put(`/api/v1/admin/prompts/${promptToSave.id}`, promptToSave)
      .then(response => {
        setPrompts(prevPrompts => prevPrompts.map((prompt, i) =>
          i === index ? response.data : prompt
        ));
        setEditIndex(null); // Clear the edit index after saving
        setAxiosError('');  // Clear any previous error
      })
      .catch(error => setAxiosError(error.response?.data?.message || 'There was an error updating the prompt!'));
  };

  const isGptModel = model => ['gpt-3.5-turbo', 'gpt-4o-mini'].includes(model);

  const renderTemperatureOptions = (isGptModelSelected) => {
    const maxTemperature = isGptModelSelected ? 20 : 10;
    return (
      <>
        {[...Array(maxTemperature)].map((_, i) => (
          <option key={i + 1} value={((i + 1) / 10).toFixed(1)}>
            {((i + 1) / 10).toFixed(1)}
          </option>
        ))}
      </>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">AI Prompts</h1>

      {error && <p className="text-red-500">{error}</p>}
      {axiosError && <p className="text-red-500">{axiosError}</p>}

      <div className="mb-4">
        <select
          className="block w-full p-2 border border-gray-300 rounded mb-2"
          value={newPrompt.model}
          onChange={(e) => setNewPrompt({ ...newPrompt, model: e.target.value })}
        >
          <option value="">Select Model</option>
          <option value="claude-3-haiku-20240307">3 Haiku</option>
          <option value="claude-3-5-sonnet-20240620">3.5 Sonnet</option>
          <option value="gpt-3.5-turbo">GPT 3.5 Turbo</option>
          <option value="gpt-4o-mini">GPT 4.0 Mini</option>
        </select>

        <input
          className="block w-full p-2 border border-gray-300 rounded mb-2"
          placeholder="Prompt Name"
          value={newPrompt.name}
          onChange={(e) => setNewPrompt({ ...newPrompt, name: e.target.value })}
        />

        <textarea
          className="block w-full p-2 border border-gray-300 rounded mb-2"
          placeholder="AI Prompt"
          value={newPrompt.text}
          onChange={(e) => setNewPrompt({ ...newPrompt, text: e.target.value })}
        />

        <select
          className="block w-full p-2 border border-gray-300 rounded mb-2"
          value={newPrompt.temperature}
          onChange={(e) => setNewPrompt({ ...newPrompt, temperature: e.target.value })}
        >
          {renderTemperatureOptions(isGptModel(newPrompt.model))}
        </select>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleAddPrompt}
        >
          Add Prompt
        </button>
      </div>

      <ul className="list-none p-0">
        {prompts.map((prompt, index) => (
          <li key={index} className="mb-4 p-4 border border-gray-300 rounded">
            <div className="flex justify-between">
              <div className="flex-grow">
                <h2 className="text-xl font-bold">{prompt.name}</h2>

                <select
                  className="block w-full p-2 border border-gray-300 rounded mb-2"
                  value={prompt.model}
                  onChange={(e) => handleEditPrompt(index, 'model', e.target.value)}
                >
                  <option value="claude-3-haiku-20240307">3 Haiku</option>
                  <option value="claude-3-5-sonnet-20240620">3.5 Sonnet</option>
                  <option value="gpt-3.5-turbo">GPT 3.5 Turbo</option>
                  <option value="gpt-4o-mini">GPT 4.0 Mini</option>
                </select>

                <select
                  className="block w-full p-2 border border-gray-300 rounded mb-2"
                  value={prompt.temperature}
                  onChange={(e) => handleEditPrompt(index, 'temperature', e.target.value)}
                >
                  {renderTemperatureOptions(isGptModel(prompt.model))}
                </select>

                <textarea
                  className="block w-full p-2 border border-gray-300 rounded mt-2"
                  rows="10"
                  value={prompt.text}
                  onChange={(e) => handleEditPrompt(index, 'text', e.target.value)}
                  style={{ minWidth: '400px' }} // Wider textarea
                />
              </div>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded ml-4 self-start" // Delete button doesn't stretch full height
                onClick={() => handleDeletePrompt(prompt.id)}
              >
                Delete
              </button>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 rounded"
                onClick={() => handleSavePrompt(index)}
              >
                Save
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

