import React from 'react';
import * as Helper from './Helpers/Helpers';
import { Progress, Typography } from 'antd';
const { Text, Title } = Typography;
import _ from 'underscore';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.startPolling();
    this.state = {
      fetchingData: false,
      data: {}
    };
  }

  startPolling() {
    let self = this;
    Helper.pollStoredJobs(self.props.commonData, self.callback);
  }

  callback(data, status) {
    if (this.state.fetchingData && status == 'done') {
      window.location.reload();
      return;
    }
    if (!_.isEmpty(data)) {
      this.setState({ fetchingData: true, data: data });
    }
    console.log('DataImport callback', data);
  }

  progress() {
    return (
      <>
        <Title level={3} className="">
          Setting up your account. This page will refresh when we're done.
        </Title>

        {Object.keys(this.state.data).map((e, i) => {
          return (
            <>
              <Text>{e}</Text>
              <Progress percent={this.state.data[e]} />
            </>
          );
        })}
      </>
    );
  }

  render() {
    return <>{!_.isEmpty(this.state.data) && this.progress()}</>;
  }
}

export default Dashboard;
